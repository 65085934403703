import { Icon } from "@blueprintjs/core";
import React from "react";

interface Props {
  addChoice: () => void;
  options: Array<string>[];
  updateChoice: (
    value: string,
    optionIndex: number,
    choiceIndex: number
  ) => void;
  color: string;
  deleteOption: (index: number) => void;
}
const FillInTheBlank = ({
  addChoice,
  options,
  updateChoice,
  color,
  deleteOption,
}: Props) => {
  return (
    <div className="multiple-choice-container fill-in-blank-container">
      <div className="choices-container">
        {options.map((item: string[], index) => {
          return (
            <div
              key={index}
              className={`single-choice-container`}
              style={{
                color,
                borderColor: color,
                backgroundColor: color + "0C",
              }}
            >
              {item
                ? item.map((choice: string, choiceIndex: number) => (
                    <input
                      key={index}
                      value={choice}
                      onChange={(e) =>
                        updateChoice(e.target.value, index, choiceIndex)
                      }
                      style={{ color, borderColor: `${color} !important` }}
                      className="choice-name apply-font"
                    />
                  ))
                : null}
              <Icon
                onClick={() => deleteOption(index)}
                icon="cross"
                className="delete-option-icon"
                color={color}
              />
            </div>
          );
        })}
      </div>
      <div className="add-choice apply-font" onClick={addChoice}>
        Add Another choice
      </div>
    </div>
  );
};
export default FillInTheBlank;
