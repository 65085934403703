import _ from "lodash";
import moment from "moment-timezone";
import { createSelector } from "reselect";

import { RootState } from "model/store";

type TimeOffMonthYear = {
  month: string;
  year: number;
  firstDayReference: Date;
  timeOffNumber: number;
};

export const businessSelector = (state: RootState): TangoBusiness =>
  state.business;

export const draftScheduleSelector = (state: RootState): TangoSchedule[] =>
  state.draftSchedules;
export const duplicatedSchedulesSelector = (
  state: RootState
): TangoSchedule[] => state.fixedSchedules;
export const staffingAppDataSelector = (state: RootState): StaffingRedux =>
  state.staffing;
export const fellowStaffMembersSelector = (state: RootState): StaffMember[] =>
  state.fellowStaffMembers;
export const adminsSelector = createSelector(
  fellowStaffMembersSelector,
  (fellowStaffMembers: StaffMember[]) => {
    return fellowStaffMembers.filter((sm) => sm.isAdmin);
  }
);

type DailyActual = {
  day: string;
  amount: number;
};

type CostScheduleData = {
  scheduleId: string;
  targetScheduleCost: number;
  actualScheduleCost: number;
  amountOverBudget: string;
  dailyActuals: DailyActual[];
  isOverBudget: boolean;
  optionalPrecomposedDuplicateSchedule?: TangoSchedule | null;
  projectedSales: number;
  bohScheduleId: string | null;
  fohScheduleId: string | null;
  selectedDepartment: "boh" | "foh" | undefined;
};

export const selectCostScheduleData = createSelector(
  businessSelector,
  draftScheduleSelector,
  duplicatedSchedulesSelector,
  staffingAppDataSelector,
  fellowStaffMembersSelector,
  (
    business: TangoBusiness,
    draftSchedules: TangoSchedule[],
    duplicatedSchedules: TangoSchedule[],
    staffingData: StaffingRedux,
    fellowStaffMembers: StaffMember[]
  ): CostScheduleData | null => {
    const currentSchedule = staffingData?.currentSchedule;
    if (!currentSchedule?.scheduleId) {
      return null;
    }
    const scheduleType = currentSchedule?.scheduleType;
    const getScheduleForCalculations = (): TangoSchedule | null | undefined => {
      if (scheduleType === "draft") {
        if (currentSchedule?.departmentId) {
          const bohSchedule = draftSchedules?.find(
            (schedule) => schedule?.id === currentSchedule?.bohScheduleId
          );
          const fohSchedule = draftSchedules?.find(
            (schedule) => schedule?.id === currentSchedule?.fohScheduleId
          );
          if (!bohSchedule || !fohSchedule) {
            return null;
          }
          if (currentSchedule.departmentId === "boh") {
            return {
              ...bohSchedule,
              projectedSales: fohSchedule.projectedSales,
            };
          } else if (currentSchedule.departmentId === "foh") {
            return fohSchedule;
          }
          return null;
        } else {
          const bohSchedule = draftSchedules?.find(
            (schedule) => schedule?.id === currentSchedule?.bohScheduleId
          );
          const fohSchedule = draftSchedules?.find(
            (schedule) => schedule?.id === currentSchedule?.fohScheduleId
          );
          if (bohSchedule && fohSchedule) {
            const mergedSchedule: TangoSchedule = {
              ...fohSchedule,
              departmentId: undefined,
              targetScheduleCost:
                (bohSchedule?.targetScheduleCost ?? 0) +
                (fohSchedule?.targetScheduleCost ?? 0),
              shifts: [...bohSchedule.shifts, ...fohSchedule.shifts],
              mergedSchedules: [fohSchedule.id, bohSchedule.id],
            };
            return mergedSchedule;
          }
          return null;
        }
      }
      return null;
    };
    let scheduleForCalculations = getScheduleForCalculations();

    console.log("scheduleForCalculations", scheduleForCalculations);
    if (!scheduleForCalculations) {
      scheduleForCalculations =
        currentSchedule?.optionalPrecomposedDuplicateSchedule;
    }
    if (!scheduleForCalculations) return null;
    const assignedShifts = scheduleForCalculations?.shifts?.filter((shift) =>
      Boolean(shift?.staffId)
    );
    let targetScheduleCost = 100;
    let projectedSales = 100;
    if (
      !_.isNil(scheduleForCalculations?.projectedSales) ||
      scheduleForCalculations?.projectedSales === 0
    ) {
      projectedSales = scheduleForCalculations.projectedSales;
    }
    if (
      !_.isNil(business?.defaultWeeklyStaffBudget) ||
      business?.defaultWeeklyStaffBudget === 0
    ) {
      targetScheduleCost = business.defaultWeeklyStaffBudget;
    }
    if (
      !_.isNil(scheduleForCalculations?.targetScheduleCost) ||
      scheduleForCalculations?.targetScheduleCost === 0
    ) {
      targetScheduleCost = scheduleForCalculations.targetScheduleCost;
    }
    const getPayrateByPostionAndStaffId = (
      positionId: string,
      staffId: string
    ) => {
      const staffMemberPayrates =
        fellowStaffMembers.find((s) => s?.id === staffId)?.payRates || [];
      const rolePayrate = staffMemberPayrates.find(
        (payRate) => payRate?.roleId === positionId
      );
      if (rolePayrate?.amount) {
        return rolePayrate?.amount;
      }
      return 0;
    };
    const actualCostReducer = (accumulator: number, item: TangoShift) => {
      if (item?.staffId) {
        const hoursDuration = Math.abs(
          moment
            .duration(
              moment(item?.startDate.toMillis()).diff(
                moment(item?.endDate.toMillis())
              )
            )
            .asHours()
        );
        return (
          accumulator +
          getPayrateByPostionAndStaffId(item.position, item.staffId) *
            hoursDuration
        );
      }
      return accumulator;
    };
    const groupedDailyActuals = _.groupBy(assignedShifts, (shift) => {
      const shiftStartDateWithTimezone = moment(shift?.startDate?.toDate());
      shiftStartDateWithTimezone.tz(business.timezone, true);
      return shiftStartDateWithTimezone.day();
    });
    const actualScheduleCost = assignedShifts.reduce(actualCostReducer, 0);
    const weekDays = moment.weekdays();
    const dailyActuals: DailyActual[] = weekDays.map((weekday, index) => {
      const shiftsForTheWeekDay = groupedDailyActuals?.[index];
      if (shiftsForTheWeekDay?.length) {
        const weekdayAmount = shiftsForTheWeekDay.reduce(actualCostReducer, 0);
        return { day: weekday, amount: weekdayAmount };
      }
      return { day: weekday, amount: 0 };
    });
    const amountOverBudget =
      targetScheduleCost < actualScheduleCost
        ? ((actualScheduleCost - targetScheduleCost) / 100).toLocaleString(
            "en-US",
            { style: "currency", currency: "USD" }
          )
        : (0).toLocaleString("en-US", { style: "currency", currency: "USD" });
    const fohScheduleId = currentSchedule?.fohScheduleId;
    const bohScheduleId = currentSchedule?.bohScheduleId;

    return {
      scheduleId: scheduleForCalculations?.id,
      targetScheduleCost,
      actualScheduleCost: actualScheduleCost,
      amountOverBudget,
      isOverBudget: targetScheduleCost < actualScheduleCost,
      dailyActuals,
      optionalPrecomposedDuplicateSchedule:
        currentSchedule?.optionalPrecomposedDuplicateSchedule,
      projectedSales,
      fohScheduleId,
      bohScheduleId,
      selectedDepartment: currentSchedule.departmentId,
    };
  }
);
