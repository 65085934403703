import { Intent, Position, Toaster } from "@blueprintjs/core";
import axios from "axios";
import firebase from "config/firebase";
import _ from "lodash";

import { receiveChannels } from "model/actions/channelsAc";

import { receiveMessages } from "../model/actions/messagesAC";
import store from "../model/store";
import { baseUrl } from "./core";
import { generateBearerToken } from "./init";
import { addListener } from "./listeners";

const MESSAGES_PER_REQUEST = 20;

const tangoCommsApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/comms`,
});

tangoCommsApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

const CommsApiRoutesMap = {
  createChannel: "/channel",
  initiateHuddle: "/initiateHuddle",
  joinHuddle: "/joinHuddle",
  leaveHuddle: "/leaveHuddle",
};

const toaster = Toaster.create({
  position: Position.BOTTOM_RIGHT,
});

export const setUpNewChannel = async (
  businessId: string,
  name: string,
  members: string[],
  creatorId: string,
  isPrivate = true,
  workspaceId: string,
  description?: string
) => {
  try {
    await tangoCommsApiClient.post(CommsApiRoutesMap.createChannel, {
      businessId,
      public: !isPrivate,
      name,
      members,
      creatorId,
      description,
      workspaceId,
    });
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const initiateHuddle = async (
  businessId: string,
  channelId: string,
  members: string[],
  creatorId: string
) => {
  try {
    await tangoCommsApiClient.post(CommsApiRoutesMap.initiateHuddle, {
      businessId,

      members,
      creatorId,
      channelId,
    });
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const joinHuddle = async (
  businessId: string,
  channelId: string,
  userId: string
) => {
  try {
    await tangoCommsApiClient.post(CommsApiRoutesMap.joinHuddle, {
      businessId,
      userId,
      channelId,
    });
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const leaveHuddle = async (
  businessId: string,
  channelId: string,
  userId: string
) => {
  try {
    await tangoCommsApiClient.post(CommsApiRoutesMap.leaveHuddle, {
      businessId,
      userId,
      channelId,
    });
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

const channelsCollection = firebase.firestore().collection("Channels");

export const fetchChannels = async (staffMember: StaffMember) => {
  const unsubscribe = firebase
    .firestore()
    .collection("Channels")
    .where("participants", "array-contains", staffMember.id)
    .where("businessId", "==", staffMember.businessId)
    .onSnapshot(
      async (listenerSn) => {
        const channels: TangoChannel[] = [];
        listenerSn.forEach((doc) => channels.push(doc.data() as TangoChannel));
        store.dispatch(receiveChannels(channels));
      },
      (err) => {
        console.warn("fetch channels error", err);
      }
    );
  addListener("channels", unsubscribe);
};

export function fetchMessages(channelKey: string) {
  return async function (dispatch: any, getState: any) {
    try {
      const state = getState();
      const currentMessages = _.get(state, ["messages", channelKey], []);
      const channel = _.get(state, ["channels", channelKey], {});
      const currentAmount = currentMessages.length;
      const amount = MESSAGES_PER_REQUEST + currentAmount;
      const unsubscribe = firebase
        .firestore()
        .collection("Channels")
        .doc(channelKey)
        .collection("Messages")
        .orderBy("timestamp", "desc")
        .limit(amount)
        .onSnapshot(
          (sn) => {
            const res: TangoMessage[] = [];
            sn.forEach((doc) => {
              const message = doc.data() as TangoMessage;
              if (_.isNil(message.timestamp)) message.timestamp = _.now();
              if (_.isObject(message.timestamp)) {
                message.timestamp = (message.timestamp as any).toMillis();
              }
              res.push(message);
            });
            dispatch(receiveMessages(res, channelKey));
          },
          (err) => console.log("receive channel messages error", err)
        );
      const unsubscribeKey =
        _.get(channel, "accounts", []).length === 1 ? "chat_private" : "chat";
      addListener(unsubscribeKey, unsubscribe);
    } catch (e) {
      //@ts-ignore
      console.log("fetch messages error:", e.message);
    }
  };
}

export const createChannel = async (
  businessId: string,
  participants: string[]
): Promise<Result> => {
  try {
    const { id } = channelsCollection.doc();
    const channel = {
      id,
      participants,
      businessId,
    };
    console.log("new channel", channel);
    await channelsCollection.doc(channel.id).set(channel);
    return { data: id };
  } catch (e: any) {
    console.log("e", e);
    return { error: e.message };
  }
};

export const sendMessage = async (channelId: string, message: TangoMessage) => {
  console.log("message", message);
  const { id } = channelsCollection.doc(channelId).collection("Messages").doc();
  await channelsCollection
    .doc(channelId)
    .collection("Messages")
    .doc(id)
    .set({
      ...message,
      id,
    });
  await channelsCollection.doc(channelId).update({
    lastMessage: { ...message, id },
  });
  return id;
};
