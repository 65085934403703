//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import _ from "lodash";
import React from "react";
import styled from "styled-components";

import Box from "components/Box";

import "../style.css";

export type SidebarMode = "channels" | "direct_messages";

type SidebarModeProps = {
  selected: boolean;
};

export const NoAvatarColors = [
  "#EAF9F3",
  "#FAF7E0",
  "#F0E8FA",
  "#FAECE8",
  "#EAF9F3",
  "#FAF7E0",
  "#F0E8FA",
  "#FAECE8",
  "#EAF9F3",
  "#FAF7E0",
  "#F0E8FA",
  "#FAECE8",
  "#EAF9F3",
  "#FAF7E0",
  "#F0E8FA",
  "#FAECE8",
  "#EAF9F3",
  "#FAF7E0",
  "#F0E8FA",
  "#FAECE8",
  "#EAF9F3",
  "#FAF7E0",
  "#F0E8FA",
  "#FAECE8",
  "#EAF9F3",
  "#FAF7E0",
  "#F0E8FA",
  "#FAECE8",
  "#EAF9F3",
  "#FAF7E0",
  "#F0E8FA",
  "#FAECE8",
];

//@ts-ignore
export const count = (str: string, ch: string) => _.sumBy(str, (x) => x === ch);

const AddChannelButton = styled(motion.div)`
  color: #7c7f93;
  background-color: #f5f7fb;
  border-radius: 100px;
  padding: 1vh 1vw;
  margin: 1vh 1vw;
  font-family: Lato-Bold;
  font-weight: bold;
  font-size: 1.4vh;
  cursor: pointer;
  max-width: 8vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SidebarMode = styled(motion.div)`
  color: ${(props: SidebarModeProps) => (props.selected ? "white" : "#7C7F93")};
  background-color: ${(props: SidebarModeProps) =>
    props.selected ? "#424452" : "#F5F7FB"};
  border-radius: 100px;
  padding: 1vh 1vw;
  font-family: Lato;
  font-weight: bold;
  font-size: 1.5vh;
  cursor: pointer;
`;

type ChannelProps = {
  selected: boolean;
};
const Channel = styled(motion.div)`
  font-family: Lato;
  font-weight: bold;
  padding: 1vh 2vw;
  font-size: 1.4vh;
  background-color: ${(props: ChannelProps) =>
    props.selected ? "#EFF2F8" : "transparent"};
  color: ${(props: ChannelProps) => (props.selected ? "#424452" : "#7C7F93")};
  &:hover {
    background-color: #eff2f8;
    color: #424452;
  }
  cursor: pointer;
`;

type StaffDirectMessageProps = {
  selected: boolean;
};

const StaffDirectMessage = styled(motion.div)`
  font-family: Lato;
  font-weight: bold;
  padding: 1vh 1.6vw;
  font-size: 1.4vh;
  background-color: ${(props: StaffDirectMessageProps) =>
    props.selected ? "#EFF2F8" : "transparent"};
  color: ${(props: StaffDirectMessageProps) =>
    props.selected ? "#424452" : "#7C7F93"};
  &:hover {
    background-color: #eff2f8;
    color: #424452;
  }
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const StaffDirectMessageAvatar = styled.img`
  height: 4vh;
  width: 4vh;
  border-radius: 50%;
  margin-right: 1vw;
`;

type StaffDirectMessageNoImageProps = {
  bgColor: string;
};

export const StaffDirectMessageNoImage = styled.div`
  height: 4vh;
  width: 4vh;
  background-color: ${(props: StaffDirectMessageNoImageProps) => props.bgColor};
  border-radius: 50%;
  color: #424452;
  font-family: Lato-Bold;
  font-weight: bold;
  font-size: 1.3vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1vw;
`;

const ChannelsWrapper = styled(Box)``;

const SidebarModesWrapper = styled(Box)`
  padding: 1vh 2vw;
`;

interface SidebarProps {
  sidebarMode: SidebarMode;
  changeSidebarMode: (m: SidebarMode) => void;
  fellowStaffMembers: StaffMember[];
  selectChannel: (ch: string | null) => void;
  selectStaffMember: (sm: string | null) => void;
  selectedChannelId: string | null;
  selectedStaffMemberId: string | null;
  initiateChannelCreation: () => void;

  channels: TangoChannel[];
  chats: TangoChannel[];
  user: StaffMember;
}

export const Sidebar = (props: SidebarProps) => {
  const {
    sidebarMode,
    changeSidebarMode,
    fellowStaffMembers,
    channels,
    selectedChannelId,
    selectChannel,
    selectStaffMember,
    selectedStaffMemberId,
    chats,
    user,
  } = props;

  return (
    <Box
      style={{
        flex: 1,
        borderRight: "1px solid #D8D8D8",
        borderTop: "1px solid #D8D8D8",
        width: "9vw",
      }}
      display="flex"
      flexDirection="column"
    >
      <SidebarModesWrapper
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <SidebarMode
          onClick={() =>
            sidebarMode !== "channels" ? changeSidebarMode("channels") : null
          }
          whileTap={{ scale: 0.9, opacity: 0.9 }}
          selected={sidebarMode === "channels"}
        >
          Channels
        </SidebarMode>
        <SidebarMode
          onClick={() =>
            sidebarMode !== "direct_messages"
              ? changeSidebarMode("direct_messages")
              : null
          }
          whileTap={{ scale: 0.9, opacity: 0.9 }}
          selected={sidebarMode === "direct_messages"}
        >
          Direct Messages
        </SidebarMode>
      </SidebarModesWrapper>
      <Box style={{ height: "80vh", overflowY: "scroll" }}>
        {sidebarMode === "channels" ? (
          <ChannelsWrapper>
            {channels.map((ch) => (
              <Channel
                onClick={() => selectChannel(ch.id)}
                selected={ch.id === selectedChannelId}
                whileTap={{ opacity: 0.5 }}
              >
                # {ch.name}
              </Channel>
            ))}
          </ChannelsWrapper>
        ) : (
          <ChannelsWrapper>
            {chats.map((chat: TangoChannel) => {
              const staffMember = fellowStaffMembers.find(
                (sm) =>
                  sm.id !== user.id &&
                  chat.participants.find((pid) => sm.id === pid)
              );
              if (!staffMember) return null;
              const staffMemberFullName = `${staffMember.contact.firstName} ${staffMember.contact.lastName}`;
              const abbrName = `${staffMember.contact.firstName?.[0]}${staffMember.contact.lastName?.[0]}`;
              const imageUrl = staffMember.imageUrl ?? null;
              const firstLetterCount = count(
                staffMember.uid,
                staffMember.uid?.[0]
              );
              const colorIndex =
                firstLetterCount > NoAvatarColors.length
                  ? NoAvatarColors.length
                  : firstLetterCount;
              const bgColor = NoAvatarColors[colorIndex] ?? "#FAECE8";
              return (
                <StaffDirectMessage
                  selected={chat.id === selectedChannelId}
                  whileTap={{ opacity: 0.5 }}
                  onClick={() => selectChannel(chat.id)}
                >
                  {imageUrl ? (
                    <StaffDirectMessageAvatar src={imageUrl} />
                  ) : (
                    <StaffDirectMessageNoImage bgColor={bgColor}>
                      {abbrName}
                    </StaffDirectMessageNoImage>
                  )}
                  {staffMemberFullName}
                </StaffDirectMessage>
              );
            })}
          </ChannelsWrapper>
        )}
        {sidebarMode === "channels" ? (
          <AddChannelButton
            onClick={props.initiateChannelCreation}
            whileTap={{ scale: 0.9, opacity: 0.9 }}
          >
            + Add Channel
          </AddChannelButton>
        ) : (
          <AddChannelButton whileTap={{ scale: 0.9, opacity: 0.9 }}>
            + New Message
          </AddChannelButton>
        )}
      </Box>
    </Box>
  );
};
