import firebase from "../config/firebase";
import { saveFile } from "../utils/storage";
import { EndingSectionProps, ThemeProp } from "../views/SurveyBuilder/types";

const TYPEFORM_COLLECTION = "Typeforms";
const THEME_COLLECTION = "TypeformThemes";
const TYPEFORM_RESPONSES = "TypeformResponses";
export const createTypeform = async (
  questions: any,
  theme: ThemeProp,
  surveyInfo: { typeformDisplayName: string; typeformDescription: string },
  formData: { name: string; type: string },
  businessId: string,
  endingSections: EndingSectionProps[],
  selectedEndingSection: EndingSectionProps | null
) => {
  const docRef = firebase.firestore().collection(TYPEFORM_COLLECTION).doc();

  const data = {
    questions,
    ...surveyInfo,
    selectedTheme: theme,
    ...formData,
    createdAt: new Date(),
    updatedAt: new Date(),
    businessId: businessId,
    enabled: true,
    deleted: false,
    endingSections,
    selectedEndingSection,
  };
  try {
    const newData = { ...data, id: docRef.id };
    await docRef.set(newData);
    return { data: newData };
  } catch (error) {
    // @ts-ignore
    console.log(error.message);
    return { error: true };
  }
};

export const fetchTypeform = async (id: string) => {
  try {
    const docSnapshot = await firebase
      .firestore()
      .collection(TYPEFORM_COLLECTION)
      .doc(id)
      .get();
    return { data: docSnapshot.data() };
  } catch (error) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};
export const fetchThemes = async (businessId: string) => {
  try {
    const querySnapshot = await firebase
      .firestore()
      .collection(THEME_COLLECTION)
      .where("businessId", "==", businessId)
      .get();
    const defaultThemeQuerySnapshot = await firebase
      .firestore()
      .collection(THEME_COLLECTION)
      .where("businessId", "==", "")
      .get();
    const defaultThemesData = defaultThemeQuerySnapshot.docs.map((doc) =>
      doc.data()
    );
    const themesData = querySnapshot.docs.map((doc) => doc.data());

    console.log(businessId);
    console.log(themesData);
    return { data: [...themesData, ...defaultThemesData] as ThemeProp[] };
  } catch (error) {
    console.log(error);
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};
export const addTheme = async (
  theme: ThemeProp,
  backgroundImage: string | null
) => {
  try {
    let themeData = { ...theme };
    let fileUrl = "";

    const docRef = firebase.firestore().collection(THEME_COLLECTION).doc();
    const fileUrlData = await saveBackgroundImage(
      backgroundImage,
      docRef.id,
      themeData.businessId
    );
    if (fileUrlData.data) {
      fileUrl = fileUrlData.data;
    }
    if (fileUrlData.error) {
      return { error: fileUrlData.error };
    }
    themeData = {
      ...themeData,
      id: docRef.id,
      backgroundImage: fileUrl?.length > 0 ? fileUrl : "",
    };
    await docRef.set(themeData);
    return { data: themeData };
  } catch (error) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};

export const updateTheme = async (
  theme: ThemeProp,
  backgroundImage: string | null
) => {
  try {
    let fileUrl = "";
    let themeData = { ...theme };

    const fileUrlData = await saveBackgroundImage(
      backgroundImage,
      themeData.id,
      themeData.businessId
    );
    if (fileUrlData.data) {
      fileUrl = fileUrlData.data;
    }
    if (fileUrlData.error) {
      return { error: fileUrlData.error };
    }
    themeData = {
      ...themeData,
      backgroundImage: fileUrl?.length > 0 ? fileUrl : "",
    };

    await firebase
      .firestore()
      .collection(THEME_COLLECTION)
      .doc(theme.id)
      .update(themeData);
    return { data: themeData };
  } catch (error) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};
export const saveBackgroundImage = async (
  image: string | null,
  themeId: string,
  businessId: string
) => {
  try {
    let fileUrl = "";
    if (image && !image.startsWith("https://")) {
      fileUrl = await saveFile(
        `businesses/${businessId}/typeform/${themeId}/backgroundImage`,
        image
      );
    }
    return { data: fileUrl };
  } catch (error) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};

export const fetchForm = async (id: string) => {
  try {
    const documentSnapshot = await firebase
      .firestore()
      .collection(TYPEFORM_COLLECTION)
      .doc(id)
      .get();
    return { data: documentSnapshot.data() };
  } catch (error) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};

export const addResponse = async (
  response: any,
  formId: string,
  businessId: string,
  username: string
) => {
  try {
    const docRef = firebase.firestore().collection(TYPEFORM_RESPONSES).doc();
    const data = {
      response,
      formId,
      id: docRef.id,
      createdAt: new Date(),
      updatedAt: new Date(),
      businessId,
      enabled: true,
      deleted: false,
      username,
    };
    await firebase
      .firestore()
      .collection(TYPEFORM_RESPONSES)
      .doc(docRef.id)
      .set(data);
    return { data: true };
  } catch (error) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};

export const fetchTypeForms = async (businessId: string) => {
  try {
    const querySnapShot = await firebase
      .firestore()
      .collection(TYPEFORM_COLLECTION)
      .where("businessId", "==", businessId)
      .get();
    const data = querySnapShot.docs.map((item) => item.data());
    return {
      data,
    };
  } catch (error) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};
