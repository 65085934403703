import { IToasterProps } from "@blueprintjs/core";
import { KeyboardEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import firebase from "../../../config/firebase";
import { RootState } from "../../../model/store";
import { addResponse, fetchForm } from "../../../models/surveyBuilder";
import { saveFile } from "../../../utils/storage";
import { themesData } from "../data";
import {
  ComponentType,
  EndingSectionProps,
  SurveyData,
  ThemeProp,
} from "../types";

const composeSurveyData = (data: any[]): SurveyData[] => {
  return data.map((item) => {
    return {
      id: item.id,
      title: item.title,
      subtitle: item.subtitle,
      required: item.required,
      type: item.type,
      value: null,
      images: item.images,
      choices:
        item.type === ComponentType.multipleChoice ||
        item.type === ComponentType.dropdown ||
        item.type === ComponentType.pictureChoice ||
        item.type === ComponentType.correctOrder
          ? item.values
          : [],
      selectedChoices: [],
      matchingObj: item.type === ComponentType.matching ? item.values : null,
      fillInBlankChoices:
        item.type === ComponentType.fillInBlank ? item.values : null,
    };
  });
};

const useSurvey = (
  id: string,
  businessId: string,
  toastRef: React.RefObject<IToasterProps> | null
) => {
  const [surveryData, setSurveyData] = useState<SurveyData[]>([]);
  const [showUsernameInput, setShowUsernameInput] = useState<boolean>(false);
  const [theme, setTheme] = useState<ThemeProp>(themesData[0]);
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [username, setUsername] = useState<string>("");
  const [showEndingSection, setShowEndingSection] = useState<boolean>(false);
  const [endingSection, setEndingSection] = useState<EndingSectionProps | null>(
    null
  );

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const response = await fetchForm(id);
        if (response.data) {
          if (!response.data.isAnonymous) {
            setShowUsernameInput(true);
          }
          setSurveyData(composeSurveyData(response.data.questions));
          if (response.data.selectedEndingSection) {
            setEndingSection(response.data.selectedEndingSection);
          }
          setIsAnonymous(response.data.isAnonymous);
          setTheme(response.data.selectedTheme);
        }
      }
    };
    fetchData();
  }, []);

  const updateInputValues = (value: string) => {
    const clonedData = [...surveryData];
    clonedData[currentStep]["value"] = value;
    setSurveyData(clonedData);
  };

  const updateMultipleChoice = (choice: string) => {
    const clonedData = [...surveryData];
    const choiceIndex = surveryData[currentStep].selectedChoices.findIndex(
      (item) => item === choice
    );
    if (choiceIndex === -1) {
      clonedData[currentStep].selectedChoices.push(choice);
      setSurveyData(clonedData);
    } else {
      clonedData[currentStep].selectedChoices.splice(choiceIndex, 1);
      setSurveyData(clonedData);
    }
  };

  const handleOnKeyDown = (e: KeyboardEvent) => {
    const { required, type, selectedChoices } = surveryData[currentStep];
    if (e.key === "Enter") {
      if (
        type === ComponentType.correctOrder ||
        type === ComponentType.matching
      ) {
        setCurrentStep((currentStep) => currentStep + 1);
      } else if (
        required &&
        type === ComponentType.multipleChoice &&
        selectedChoices.length > 0
      ) {
        setCurrentStep((currentStep) => currentStep + 1);
      } else if (
        surveryData[currentStep].required &&
        surveryData[currentStep].value
      ) {
        setCurrentStep((currentStep) => currentStep + 1);
      }
    }
  };

  const addLabelToPicture = (value: string) => {
    const clonedData = [...surveryData];
    clonedData[currentStep]["value"] = value;
    setSurveyData(clonedData);
  };
  const updateCorrectOrderChoices = (updatedChoices: any[]) => {
    const clonedData = [...surveryData];
    clonedData[currentStep]["choices"] = updatedChoices;
    setSurveyData(clonedData);
  };
  const handleFileChange = async (image: string, fileName: string) => {
    try {
      let fileUrl = "";
      if (image && !image.startsWith("https://")) {
        fileUrl = await saveFile(
          `businesses/${business.id}/typeform/` + fileName,
          image
        );
      }
      const clonedData = [...surveryData];
      clonedData[currentStep]["value"] = fileUrl;
      setSurveyData(clonedData);
    } catch (error) {
      // @ts-ignore
      const err: firebase.firestore.FirestoreError = error;
      return { error: err.message };
    }
  };
  const updateMatchingChoices = (type: string, choices: string[]) => {
    const clonedData = [...surveryData];
    const clonedMatchingObj = { ...clonedData[currentStep].matchingObj };
    if (clonedMatchingObj) {
      if (type === "leftArray") {
        clonedMatchingObj["leftArray"] = choices;
      }
      if (type === "rightArray") {
        clonedMatchingObj["rightArray"] = choices;
      }
      //@ts-ignore
      clonedData[currentStep].matchingObj = { ...clonedMatchingObj };
      setSurveyData(clonedData);
    }
  };
  const updateFillInTheBlankAnswers = (index: number, value: string) => {
    const clonedData = [...surveryData];
    clonedData[currentStep].selectedChoices[index] = value;
    setSurveyData(clonedData);
  };
  const gotoNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const submitForm = async () => {
    console.log(surveryData);
    const requiredQeustion = surveryData.findIndex((item) => {
      if (
        item.type === ComponentType.multipleChoice &&
        item.required &&
        item.selectedChoices.length > 0
      ) {
        return false;
      } else if (
        item.type === ComponentType.correctOrder ||
        item.type === ComponentType.matching
      ) {
        return false;
      } else if (item.required && !item.value) {
        return true;
      }
    });
    // console.log(requiredQeustion);
    if (requiredQeustion !== -1) {
      //@ts-ignore
      toastRef.current.show({
        message: "Please enter the required details",
        intent: "danger",
      });
      setCurrentStep(requiredQeustion);
    } else {
      if (id) {
        const formResponse = await addResponse(
          surveryData,
          id,
          businessId,
          username
        );
        if (formResponse.data) {
          setShowEndingSection(true);
        } else {
          console.log(formResponse);
        }
        console.log(surveryData);
      }
    }
  };

  return {
    surveryData,
    showUsernameInput,
    theme,
    isAnonymous,
    currentStep,
    username,
    showEndingSection,
    endingSection,
    updateInputValues,
    updateMultipleChoice,
    handleOnKeyDown,
    addLabelToPicture,
    updateCorrectOrderChoices,
    handleFileChange,
    updateMatchingChoices,
    updateFillInTheBlankAnswers,
    gotoNextStep,
    submitForm,
    setCurrentStep,
    setShowUsernameInput,
    setUsername,
  };
};
export default useSurvey;
