import firebase from "config/firebase";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import { fetchMessages, sendMessage } from "controllers/chat";

import { RootState } from "model/store";

import { useTangoRTE } from "./useTangoRTE";

export type SidebarMode = "channels" | "direct_messages";

export interface TransformedMessage {
  id: string | null;
  text: string;
  html?: string;
  createdAt: Date;
  user: {
    id: string;
    name: string;
    avatar: string | null;
  };
}

export const useChat = () => {
  const [sidebarMode, setSidebarMode] =
    useState<SidebarMode>("direct_messages");
  const [selectedChannelId, setSelectedChannelId] = useState<string | null>(
    null
  );
  const [createChannelMode, setCreateChannelMode] = useState(false);
  const [callVisible, setCallVisible] = useState(false);
  const fellowStaffMembers = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const channels: TangoChannel[] = useSelector(
    (state: RootState) => state.channels
  );

  const chats = useMemo(() => {
    return channels?.filter((ch) => !ch.name) ?? [];
  }, [channels]);

  const workspaceChannels = useMemo(() => {
    return channels?.filter((ch) => ch.name) ?? [];
  }, [channels]);

  const publicWorkspaceChannels = useMemo(() => {
    return workspaceChannels?.filter((wch) => wch.public) ?? [];
  }, [workspaceChannels]);

  const privateWorkspaceChannels = useMemo(() => {
    return workspaceChannels?.filter((wch) => !wch.public) ?? [];
  }, [workspaceChannels]);

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const user: StaffMember = useSelector((state: RootState) => state.user);

  const businessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const changeSidebarMode = useCallback((mode: SidebarMode) => {
    setSidebarMode(mode);
  }, []);

  const initiateCreatingChannelFlow = useCallback(() => {
    setSelectedChannelId(null);
    setCreateChannelMode(true);
  }, []);

  const finishCreatingChannelFlow = useCallback(() => {
    setCreateChannelMode(false);
  }, []);

  const selectChannel = useCallback((channelId: string | null) => {
    setSelectedChannelId(channelId);
    if (channelId) {
      fetchChannelMessages(channelId);
    }
    setCreateChannelMode(false);
    setSelectedStaffMemberId(null);
  }, []);

  const selectStaffMember = useCallback((smId: string | null) => {
    setSelectedStaffMemberId(smId);
    setSelectedChannelId(null);
  }, []);

  const [selectedStaffMemberId, setSelectedStaffMemberId] = useState<
    string | null
  >(null);

  const [commsExpanded, setCommsExpanded] = useState<boolean>(false);

  const selectedChannel = useMemo(() => {
    if (!selectedChannelId) {
      return null;
    }
    return channels.find((ch) => ch.id === selectedChannelId) ?? null;
  }, [selectedChannelId, channels]);

  console.log("selectedChannelId", selectedChannelId, selectedChannel);

  const dispatch = useDispatch();

  const fetchChannelMessages = useCallback(
    async (channelId: string) => {
      dispatch(fetchMessages(channelId));
    },
    [selectedChannel]
  );

  const { editorHtml, setEditorHtml, modules, formats } = useTangoRTE();

  const selectedStaffMember = useMemo(() => {
    if (!selectedStaffMemberId) return null;
    return (
      fellowStaffMembers.find((sm) => sm.id === selectedStaffMemberId) ?? null
    );
  }, [fellowStaffMembers, selectedStaffMemberId]);

  const channelSelector = createSelector(
    (state: RootState) => state.channels,
    (channels: TangoChannel[]): TangoChannel | null | undefined => {
      if (selectedChannelId) {
        return channels?.find((channel) => channel.id === selectedChannelId);
      }
      // if (newlyCreatedChannelId) {
      //   console.log("here mfca", newlyCreatedChannelId);
      //   return channels?.find(
      //     (channel) => channel.id === newlyCreatedChannelId
      //   );
      // }
      return null;
    }
  );

  const messagesSelector = createSelector(
    channelSelector,
    (state: RootState) => {
      return state.messages;
    },
    (
      channel: TangoChannel | null | undefined,
      messages: any
    ): TangoMessage[] => {
      if (!messages || !channel) return [];
      if (!messages[channel.id]) return [];
      return messages[channel.id];
    }
  );

  const onSend = useCallback(() => {
    console.log("On Send", editorHtml);
    if (selectedChannel && editorHtml) {
      const message: TangoMessage = {
        id: null,
        type: "TEXT",
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        senderId: user.id,
        text: "",
        html: editorHtml,
        deleted: false,
      };
      sendMessage(selectedChannel.id, message);
      setEditorHtml("");
    }
  }, [selectedChannel, editorHtml]);

  const sendEnabled = useMemo(() => {
    if (selectedChannel && editorHtml?.length) {
      return true;
    }
    return false;
  }, [editorHtml, selectedChannel]);

  const rawMessages = useSelector(messagesSelector);

  const messages: TransformedMessage[] = useMemo(() => {
    return (
      rawMessages
        .filter((m) => m.id)
        .map((message) => {
          const sender = [...fellowStaffMembers, user].find(
            (sm) => sm.id === message.senderId
          );
          if (sender && message?.id) {
            if (message.html) {
              return {
                id: message.id,
                text: message.text,
                html: message.html,
                createdAt: new Date(message.timestamp),
                user: {
                  id: message.senderId,
                  name: `${sender?.contact?.firstName} ${sender?.contact?.lastName}`,
                  avatar: sender?.imageUrl,
                },
              };
            }
            return {
              id: message.id,
              text: message.text,
              createdAt: new Date(message.timestamp),
              user: {
                id: message.senderId,
                name: `${sender?.contact?.firstName} ${sender?.contact?.lastName}`,
                avatar: sender?.imageUrl,
              },
            };
          }
        })
        .filter((x) => !!x) as TransformedMessage[]
    ).sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
  }, [rawMessages, fellowStaffMembers, user]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    //@ts-ignore
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return {
    sidebarMode,
    changeSidebarMode,
    selectedStaffMember,
    selectedChannelId,
    selectChannel,
    selectStaffMember,
    fellowStaffMembers,
    business,
    businessSettings,
    callVisible,
    setCallVisible,
    user,
    initiateCreatingChannelFlow,
    finishCreatingChannelFlow,
    createChannelMode,
    privateWorkspaceChannels,
    publicWorkspaceChannels,
    chats,
    workspaceChannels,
    selectedChannel,
    messages,
    editorHtml,
    setEditorHtml,
    modules,
    formats,
    onSend,
    sendEnabled,
    messagesEndRef,
    setCommsExpanded,
    commsExpanded,
  };
};
