import AgoraRTC from "agora-rtc-sdk-ng";
//@ts-ignore
import { AnimatePresence } from "framer-motion/dist/framer-motion";
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import React, { ReactElement, useCallback } from "react";
import styled from "styled-components";

import Box from "components/Box";

import CommsWidget from "assets/comms-widget.svg";
import BlackTangoIcon from "assets/enterpise/black-tango.svg";
import LaunchIcon from "assets/enterpise/launch-icon.svg";

import { Sidebar } from "./components/Sidebar";
import useAgora, { APP_ID } from "./hooks/useAgora";
import { useChat } from "./hooks/useChat";
import "./style.css";
import { Call } from "./views/Call";
import { Content } from "./views/Content";

const CommsWidgetContainer = styled(motion.div)`
  border-radius: 50%;
  cursor: pointer;
  background-color: white;
  height: 7vh;
  width: 7vh;
  z-index: 1002;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 3vh;
  right: 3vw;
  justify-content: center;
  box-shadow: 0px 1.2px 3.2px rgba(0, 0, 0, 0.1),
    0px 4px 14.4px rgba(0, 0, 0, 0.07);
`;

const CommsWidgetImage = styled.img`
  height: 4vh;
`;

const HeaderContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  height: 7vh;
  border-bottom: 1px solid #dddddd;
`;

const HeaderControlWrapper = styled(motion.div)`
  border-right: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.2vw;
`;

const HeaderIcon = styled(motion.img)``;

const client = AgoraRTC.createClient({ codec: "vp8", mode: "rtc" });
interface ChatProps {
  children: ReactElement | ReactElement[];
}
const Chat = (props: ChatProps) => {
  const {
    sidebarMode,
    changeSidebarMode,
    fellowStaffMembers,
    selectChannel,
    selectedChannelId,
    selectedStaffMember,
    selectStaffMember,
    businessSettings,
    business,
    setCallVisible,
    callVisible,
    initiateCreatingChannelFlow,
    createChannelMode,
    user,
    workspaceChannels,
    selectedChannel,
    messages,
    chats,
    editorHtml,
    setEditorHtml,
    modules,
    formats,
    sendEnabled,
    onSend,
    messagesEndRef,
    finishCreatingChannelFlow,
    commsExpanded,
    setCommsExpanded,
  } = useChat();

  const {
    localAudioTrack,
    localVideoTrack,
    leave,
    join,
    joinState,
    remoteUsers,
    generateToken,
    initiateAudioCall,
    disableLocalVideoTrack,
    enableLocalAudioTrack,
    disableLocalAudioTrack,
    enableLocalVideoTrack,
    enableLocalScreenSharing,
    localScreenSharingTrack,
    disableLocalScreenSharing,
    startHuddle,
  } = useAgora(client);

  const leaveChannel = async () => {
    if (business && selectedChannel) {
      leave(business.id, selectedChannel.id);
      setCallVisible(false);
    }
  };

  const startAudioCall = useCallback(async () => {
    if (joinState) {
      setCallVisible(true);
    } else {
      console.log("selectedChannel", selectedChannel);
      console.log("business", business);
      if (selectedChannel && business) {
        await startHuddle(APP_ID, selectedChannel, business.id);
        setCallVisible(true);
      }
    }
  }, [selectedChannel, business]);

  const startVideoCall = async () => {
    if (joinState) {
      setCallVisible(true);
    } else {
      if (selectedStaffMember) {
        const channelName = `one-on-one-call`;
        const channelToken = await generateToken(channelName);
        if (channelToken) {
          await join(APP_ID, channelName, channelToken);
        }
      }
      setCallVisible(true);
    }
  };

  // return (
  //   <CommsWidgetContainer
  //     onClick={() => setCommsExpanded(!commsExpanded)}
  //     whileHover={{ scale: 1.1 }}
  //     whileTap={{ scale: 0.9 }}
  //   >
  //     <CommsWidgetImage src={CommsWidget} />
  //   </CommsWidgetContainer>
  // );

  if (callVisible && !commsExpanded) {
    return (
      <motion.div style={{ display: "flex", flexDirection: "row" }}>
        <motion.div
          style={{
            height: "100%",
            width: "88vw",
            position: "relative",
            margin: 0,
          }}
        >
          {props.children}
          {commsExpanded ? null : (
            <CommsWidgetContainer
              onClick={() => setCommsExpanded(!commsExpanded)}
              callVisible
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <CommsWidgetImage src={CommsWidget} />
            </CommsWidgetContainer>
          )}
        </motion.div>
        <AnimatePresence>
          <Call
            fullHeight
            fellowStaffMembers={fellowStaffMembers}
            enableLocalScreenSharing={enableLocalScreenSharing}
            localScreenSharingTrack={localScreenSharingTrack}
            disableLocalScreenSharing={disableLocalScreenSharing}
            localVideoTrack={localVideoTrack}
            user={user}
            selectedStaffMember={selectedStaffMember}
            remoteUsers={remoteUsers}
            localAudioTrack={localAudioTrack}
            disableLocalVideoTrack={disableLocalVideoTrack}
            enableLocalAudioTrack={enableLocalAudioTrack}
            disableLocalAudioTrack={disableLocalAudioTrack}
            enableLocalVideoTrack={enableLocalVideoTrack}
            leaveChannel={leaveChannel}
            setCommsExpanded={setCommsExpanded}
          />
        </AnimatePresence>
      </motion.div>
    );
  }

  return (
    <motion.div>
      {props.children}
      {commsExpanded ? null : (
        <CommsWidgetContainer
          onClick={() => setCommsExpanded(!commsExpanded)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <CommsWidgetImage src={CommsWidget} />
        </CommsWidgetContainer>
      )}

      {commsExpanded ? (
        <Box
          className={`comms-overlay ${
            commsExpanded ? "comms-open" : "comms-close"
          }`}
          style={{ flex: 1, height: "100%" }}
          display="flex"
          flexDirection="column"
        >
          <HeaderContainer>
            <HeaderControlWrapper>
              <HeaderIcon
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                src={BlackTangoIcon}
              />
            </HeaderControlWrapper>
            <HeaderControlWrapper>
              <HeaderIcon
                onClick={() => setCommsExpanded(!commsExpanded)}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                src={LaunchIcon}
              />
            </HeaderControlWrapper>
          </HeaderContainer>
          <Box
            display="flex"
            flexDirection="row"
            style={{ flex: 1, height: "100%" }}
          >
            <Sidebar
              user={user}
              chats={chats}
              channels={workspaceChannels}
              selectStaffMember={selectStaffMember}
              selectedStaffMemberId={selectedStaffMember?.id ?? null}
              sidebarMode={sidebarMode}
              changeSidebarMode={changeSidebarMode}
              fellowStaffMembers={fellowStaffMembers}
              selectedChannelId={selectedChannelId}
              selectChannel={selectChannel}
              initiateChannelCreation={initiateCreatingChannelFlow}
            />
            <Content
              messagesEndRef={messagesEndRef}
              messages={messages}
              selectedChannel={selectedChannel}
              fellowStaffMembers={fellowStaffMembers}
              remoteUsers={remoteUsers}
              localVideoTrack={localVideoTrack}
              localAudioTrack={localAudioTrack}
              initiateAudioCall={startAudioCall}
              initiateVideoCall={startVideoCall}
              businessSettings={businessSettings}
              business={business}
              selectedChannelId={selectedChannelId}
              selectedStaffMember={selectedStaffMember}
              createChannelMode={createChannelMode}
              editorHtml={editorHtml}
              setEditorHtml={setEditorHtml}
              modules={modules}
              formats={formats}
              sendEnabled={sendEnabled}
              onSend={onSend}
              finishCreatingChannelFlow={finishCreatingChannelFlow}
            />
            <AnimatePresence>
              {callVisible && commsExpanded ? (
                <Call
                  fellowStaffMembers={fellowStaffMembers}
                  enableLocalScreenSharing={enableLocalScreenSharing}
                  localScreenSharingTrack={localScreenSharingTrack}
                  disableLocalScreenSharing={disableLocalScreenSharing}
                  localVideoTrack={localVideoTrack}
                  user={user}
                  selectedStaffMember={selectedStaffMember}
                  remoteUsers={remoteUsers}
                  localAudioTrack={localAudioTrack}
                  disableLocalVideoTrack={disableLocalVideoTrack}
                  enableLocalAudioTrack={enableLocalAudioTrack}
                  disableLocalAudioTrack={disableLocalAudioTrack}
                  enableLocalVideoTrack={enableLocalVideoTrack}
                  leaveChannel={leaveChannel}
                  setCommsExpanded={setCommsExpanded}
                />
              ) : null}
            </AnimatePresence>
          </Box>
        </Box>
      ) : null}
    </motion.div>
  );

  return (
    <Box
      className={`comms-overlay ${
        commsExpanded ? "comms-open" : "comms-close"
      }`}
      style={{ flex: 1, height: "100%" }}
      display="flex"
      flexDirection="row"
    >
      <AnimatePresence>
        {callVisible ? (
          <Call
            fellowStaffMembers={fellowStaffMembers}
            enableLocalScreenSharing={enableLocalScreenSharing}
            localScreenSharingTrack={localScreenSharingTrack}
            disableLocalScreenSharing={disableLocalScreenSharing}
            localVideoTrack={localVideoTrack}
            user={user}
            selectedStaffMember={selectedStaffMember}
            remoteUsers={remoteUsers}
            localAudioTrack={localAudioTrack}
            disableLocalVideoTrack={disableLocalVideoTrack}
            enableLocalAudioTrack={enableLocalAudioTrack}
            disableLocalAudioTrack={disableLocalAudioTrack}
            enableLocalVideoTrack={enableLocalVideoTrack}
            leaveChannel={leaveChannel}
            setCommsExpanded={setCommsExpanded}
          />
        ) : null}
      </AnimatePresence>
      <Sidebar
        user={user}
        chats={chats}
        channels={workspaceChannels}
        selectStaffMember={selectStaffMember}
        selectedStaffMemberId={selectedStaffMember?.id ?? null}
        sidebarMode={sidebarMode}
        changeSidebarMode={changeSidebarMode}
        fellowStaffMembers={fellowStaffMembers}
        selectedChannelId={selectedChannelId}
        selectChannel={selectChannel}
        initiateChannelCreation={initiateCreatingChannelFlow}
      />
      <Content
        messagesEndRef={messagesEndRef}
        messages={messages}
        selectedChannel={selectedChannel}
        fellowStaffMembers={fellowStaffMembers}
        remoteUsers={remoteUsers}
        localVideoTrack={localVideoTrack}
        localAudioTrack={localAudioTrack}
        initiateAudioCall={startAudioCall}
        initiateVideoCall={startVideoCall}
        businessSettings={businessSettings}
        business={business}
        selectedChannelId={selectedChannelId}
        selectedStaffMember={selectedStaffMember}
        createChannelMode={createChannelMode}
        editorHtml={editorHtml}
        setEditorHtml={setEditorHtml}
        modules={modules}
        formats={formats}
        sendEnabled={sendEnabled}
        onSend={onSend}
        finishCreatingChannelFlow={finishCreatingChannelFlow}
      />
    </Box>
  );
};

export default Chat;
