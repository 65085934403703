import React, { useEffect } from "react";
import { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../../model/store";
import { saveFile } from "../../../utils/storage";
import { EndingSectionProps, ImageLayoutType } from "../types";

const useEndingSection = (
  endingSectionBgImageRef: React.RefObject<HTMLInputElement>
) => {
  const [endingSections, setEndingSections] = useState<EndingSectionProps[]>(
    []
  );
  const [selectedEndingSection, setSelectedEndingSection] =
    useState<EndingSectionProps | null>(null);
  const [showEndingSection, setShowEndingSection] = useState<boolean>(false);
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const updateEndingSectionStep = (key: string, value: string | number) => {
    const clonedEndingSections = [...endingSections];
    let obj = {};
    if (selectedEndingSection) {
      if (key === "showButton" || key === "showButtonLink") {
        obj = { [key]: !selectedEndingSection[key] };
        setSelectedEndingSection({ ...selectedEndingSection, ...obj });
      } else {
        obj = { [key]: value };
        setSelectedEndingSection({ ...selectedEndingSection, ...obj });
      }
      const updatedEndingSections = clonedEndingSections.map((item, index) => {
        if (index === selectedEndingSection.index) {
          return { ...item, ...obj };
        }
        return item;
      });
      setEndingSections(updatedEndingSections);
    }
  };

  const uploadEndingSectionImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    //@ts-ignore
    reader.onload = async (event) => {
      if (event.target) {
        if (endingSectionBgImageRef.current) {
          endingSectionBgImageRef.current.value = "";
        }
        const text = event.target.result;
        if (e.target && text && selectedEndingSection) {
          //@ts-ignore

          try {
            const fileUrl = await saveFile(
              //@ts-ignore
              `businesses/${business.id}/typeform/` + e.target.fileName,
              //@ts-ignore
              text
            );
            const updatedEndingSection = { ...selectedEndingSection };
            const clonedEndingSections = [...endingSections];
            updatedEndingSection.image = fileUrl;
            clonedEndingSections[updatedEndingSection.index] =
              updatedEndingSection;

            setSelectedEndingSection(updatedEndingSection);
            setEndingSections(clonedEndingSections);
          } catch (e) {
            //@ts-ignore
            alert(e.message);
          }
        }
      }
    };
    if (e.target.files) {
      reader.readAsDataURL(e.target.files[0]);
      //@ts-ignore
      reader.fileName = e.target.files[0].name;
    }
  };
  const addNewSection = () => {
    setEndingSections([
      ...endingSections,
      {
        title: "Thank you so much for taking our survey! See you soon.",
        subtitle: "",
        showButton: true,
        buttonLink: "",
        buttonLabel: "Done",
        image: "",
        showButtonLink: true,
        index: endingSections.length,
        imageBrightness: 0,
        imageLayout: "top",
      },
    ]);
  };
  return {
    endingSections,
    selectedEndingSection,
    setSelectedEndingSection,
    updateEndingSectionStep,
    uploadEndingSectionImage,
    addNewSection,
    showEndingSection,
    setShowEndingSection,
  };
};
export default useEndingSection;
