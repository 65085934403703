import { Icon, InputGroup } from "@blueprintjs/core";
import { width } from "@mui/system";
import firebase from "config/firebase";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "components/Box";

import { RootState } from "model/store";

import Layout from "../../MarketingFeedback/Layout";

interface Props {
  subscribedData: any;
}

const SubscribedAudience = ({ subscribedData }: Props) => {
  const navigate = useNavigate();

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const [audienceList, setAudienceList]: any = useState([]);
  const [searchText, setSearchText] = useState("");

  const [filterAudience, setFilterAudience]: any = useState([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const onMasterCheck = (e: any, key: number) => {
    let list = audienceList.map((item: any) => item);
    list[key]["selected"] = e.target.checked;
    setAudienceList(list);
  };

  useEffect(() => {
    let AllData = audienceList.map((item: any) => item);

    if (searchText.length > 2) {
      var data = AllData.filter((item: any) => item.name.includes(searchText));
      setFilterAudience(data);
      setIsSearching(true);
    } else {
      setFilterAudience([]);
      setIsSearching(false);
    }
  }, [searchText]);

  useEffect(() => {
    console.log("audienceList", audienceList);
  }, [audienceList]);

  useEffect(() => {
    if (subscribedData) {
      setAudienceList(subscribedData);
    }
  }, [subscribedData]);

  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        className="body-head-text"
      >
        <Box
          display="flex"
          flexDirection="column"
          className="body-head-leftside"
        >
          <span>Subscribed</span>
          <span>Showing all {audienceList.length} of your contacts</span>
        </Box>
        <div className="edit-roles-button">Edit Roles</div>
      </Box>
      <Box className="search-input">
        <InputGroup
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          leftIcon="search"
          intent="none"
        />
      </Box>
      <Box className="table-container">
        <table>
          {/*All Data*/}
          {!isSearching &&
            audienceList.length > 0 &&
            audienceList.map((item: any, key: number) => {
              return (
                <tr
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/edit-subscriber/${item.id}`)}
                >
                  <td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: "20px",
                      }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={item.selected}
                        id="mastercheck"
                        onChange={(e) => onMasterCheck(e, key)}
                      />
                      <div className="user-avatar-name">{item.profileName}</div>
                      <span className="email" style={{ marginLeft: "20.5px" }}>
                        {item.name}
                      </span>
                    </div>
                  </td>
                  <td>{item.emailAddress}</td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.date}</td>
                  <td>
                    <div className="subscribe-button">Subscribed</div>
                  </td>
                </tr>
              );
            })}

          {/*filtered Data*/}
          {filterAudience.length > 0 &&
            isSearching &&
            filterAudience.map((item: any, key: number) => {
              return (
                <tr
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/edit-subscriber/${item.id}`)}
                >
                  <td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: "20px",
                      }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={item.selected}
                        id="mastercheck"
                        onChange={(e) => onMasterCheck(e, key)}
                      />
                      <div className="user-avatar-name">{item.profileName}</div>
                      <span className="email" style={{ marginLeft: "20.5px" }}>
                        {item.name}
                      </span>
                    </div>
                  </td>
                  <td>{item.emailAddress}</td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.date}</td>
                  <td>
                    <div className="subscribe-button">Subscribed</div>
                  </td>
                </tr>
              );
            })}
        </table>
      </Box>
    </Layout>
  );
};

export default SubscribedAudience;
