import React, { createContext, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ClickableWraper } from "components/AnimatedComponents";
import Box from "components/Box";

import "./style.css";

interface Route {
  name: string;
  displayName: string;
  uri: any;
}

export const Overlay = () => {
  const launchpad = useLaunchpad();

  const routes: Route[] = [
    {
      name: "products",
      displayName: "Products",
      uri: require("../../assets/launchpad/products.svg").default,
    },
    {
      name: "menus",
      displayName: "Menus",
      uri: require("../../assets/launchpad/menus.svg").default,
    },
    {
      name: "modifiers",
      displayName: "Modifiers",
      uri: require("../../assets/launchpad/modifiers.svg").default,
    },
    {
      name: "discounts",
      displayName: "Discounts",
      uri: require("../../assets/launchpad/discounts.svg").default,
    },
    {
      name: "core-reports",
      displayName: "Reporting",
      uri: require("../../assets/launchpad/reporting.svg").default,
    },
    {
      name: "business-info",
      displayName: "Business Info",
      uri: require("../../assets/launchpad/business-info.svg").default,
    },
    {
      name: "settings",
      displayName: "Settings",
      uri: require("../../assets/launchpad/settings.svg").default,
    },
    {
      name: "manager/scheduling",
      displayName: "Scheduling",
      uri: require("../../assets/launchpad/scheduling.svg").default,
    },
    {
      name: "manager/daily-logs",
      displayName: "Daily Logs",
      uri: require("../../assets/launchpad/daily-logs-icon.svg").default,
    },
    {
      name: "manager/team",
      displayName: "Team",
      uri: require("../../assets/launchpad/team.svg").default,
    },
    {
      name: "manager/memos",
      displayName: "Memos",
      uri: require("../../assets/launchpad/memos.svg").default,
    },
    {
      name: "manager/docs",
      displayName: "Storage",
      uri: require("../../assets/launchpad/storage.svg").default,
    },
    {
      name: "manager/training",
      displayName: "Training",
      uri: require("../../assets/launchpad/training.svg").default,
    },
    // {
    //   name:'employees',
    //   displayName:'Employees',
    //   uri:require('../../assets/launchpad/team.svg').default,
    // },
    {
      name: "gift-cards",
      displayName: "Gift Cards",
      uri: require("../../assets/launchpad/giftCards.svg").default,
    },
    {
      name: "campaigns",
      displayName: "Email Marketing",
      uri: require("../../assets/launchpad/emailMarketing.svg").default,
    },
    {
      name: "table-management",
      displayName: "Tables",
      uri: require("../../assets/launchpad/table-management.svg").default,
    },
    {
      name: "table-management",
      displayName: "Services Areas",
      uri: require("../../assets/launchpad/serviceAreas.svg").default,
    },
    {
      name: "device-setup",
      displayName: "Device Setup",
      uri: require("../../assets/launchpad/deviceSetup.svg").default,
    },
    {
      name: "tipouts",
      displayName: "Tipouts",
      uri: require("../../assets/launchpad/tipout.svg").default,
    },
    {
      name: "help/tutorials",
      displayName: "How To",
      uri: require("../../assets/launchpad/help-support.svg").default,
    },
    // {
    //   name:'help',
    //   displayName:'Help & Support',
    //   uri:require('../../assets/launchpad/helpAndSupport.svg').default,
    // }
  ];
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredRoutes, setFilteredRoutes] = useState<Route[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let interval: any;
    if (searchQuery.length > 0) {
      if (interval) clearInterval(interval);
      interval = setInterval(() => {
        const filteredItems: Route[] = routes.filter((item) =>
          item.name.includes(searchQuery.toLowerCase())
        );
        setFilteredRoutes(filteredItems);
      }, 500);
    } else {
      setFilteredRoutes([]);
    }
    return () => clearInterval(interval);
  }, [searchQuery]);

  return (
    <div
      onClick={() => {
        launchpad.setVisibility(false);
      }}
      style={{ pointerEvents: launchpad.visible ? "auto" : "none" }}
      className={`launchpad-overlay ${
        launchpad.visible ? "overlay-open" : "overlay-close"
      }`}
    >
      <Box className="launchpad">
        <Box className="container">
          <Box
            className="header"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <div className="go-anywhere">Go anywhere...</div>
            <div className="header-right">
              <div className="search-container">
                <input
                  onClick={(e) => e.stopPropagation()}
                  placeholder="search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <ClickableWraper
                className="back-to-home-button"
                onClick={(e) => {
                  e.stopPropagation();
                  launchpad.setVisibility(false);
                  navigate("/dashboard");
                }}
              >
                Back to home
              </ClickableWraper>
              <ClickableWraper onClick={() => launchpad.setVisibility(false)}>
                <img
                  src={require("assets/launchpad/launch-pad-white.svg").default}
                  alt="cube"
                  className="cube"
                />
              </ClickableWraper>
            </div>
          </Box>
          <Box className="body">
            {filteredRoutes.length > 0
              ? filteredRoutes.map((item, i) => (
                  <ClickableWraper className="app-icon">
                    <Link to={`/${item.name}`}>
                      <img src={item.uri} alt="app icon" />
                      <div className="subapp-name">{item.displayName}</div>
                    </Link>
                  </ClickableWraper>
                ))
              : routes.map((item, i) => (
                  <ClickableWraper className="app-icon">
                    <Link to={`/${item.name}`}>
                      <img src={item.uri} alt="app icon" />
                      <div className="subapp-name">{item.displayName}</div>
                    </Link>
                  </ClickableWraper>
                ))}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export const LaunchpadContext = createContext({
  visible: false,
  setVisibility: (val: boolean) => {},
});

export const useLaunchpad = () => useContext(LaunchpadContext);
