import "@blueprintjs/core/lib/css/blueprint.css";
// required for blueprint js to work
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
// required for blueprint icons js to work
import "@blueprintjs/select/lib/css/blueprint-select.css";
// required for blueprint selects to be styled
import "@blueprintjs/table/lib/css/table.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
// required for blueprint table to be styled
import "normalize.css";
// required for blueprint js to work with css
import React from "react";
import { hydrate, render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import store from "model/store";

import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: "https://de7db556849847e8927506d140d9908c@o509640.ingest.sentry.io/6112004",
  integrations: [new BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  enabled: !(!process.env.NODE_ENV || process.env.NODE_ENV === "development"),
});

const rootElement = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
