import { Icon } from "@blueprintjs/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { composeUsableTangoBusiness } from "controllers/composableTypes";
import { subscribeToCollectionDocument } from "controllers/core";
import { initializeData } from "controllers/init";

import { receiveBusiness } from "model/actions/businessAC";
import { changeEmulatorMode } from "model/actions/emulatorModeAC";
import store, { RootState } from "model/store";

import BlackTangoIcon from "assets/enterpise/black-tango.svg";
import LaunchIcon from "assets/enterpise/launch-icon.svg";

import "./index.css";

const StoreEmulator = () => {
  const [searchText, setSearchText] = useState("");

  const renderLocations = (searchText: string) => {
    const locations: TangoBusiness[] = useSelector(
      (state: RootState) => state.businesses
    );
    const user: StaffMember = useSelector((state: RootState) => state.user);

    const navigate = useNavigate();

    return (
      <ul className="locations-list">
        {(searchText
          ? (locations || []).filter((location) =>
              location.businessName.includes(searchText)
            )
          : locations || []
        ).map((business) => (
          <li
            key={business.id}
            onClick={async (e: any) => {
              const currentUser = { ...user };
              currentUser.businessId = business.id;
              subscribeToCollectionDocument<TangoBusiness>(
                "Businesses",
                business.id,
                receiveBusiness,
                "business",
                composeUsableTangoBusiness,
                undefined,
                () => {}
              );
              await initializeData(currentUser);
              store.dispatch(changeEmulatorMode(true));
              navigate("/dashboard");
            }}
          >
            <div className="left-part">
              <div className="location-name">{business.businessName}</div>
              <div className="location-address">
                {business.location?.address || ""}
              </div>
            </div>
            <Icon icon="chevron-right" color="#000" />
          </li>
        ))}
      </ul>
    );
  };
  return (
    <div id="store-emulator">
      <div className="header">
        <ul>
          <li>
            <img src={BlackTangoIcon} />
          </li>
          <li>
            <Link to="/enterprise">
              <img src={LaunchIcon} />
            </Link>
          </li>
        </ul>
      </div>
      <div className="store-emulator-body">
        <div className="page-title">Store Emulator</div>
        <div className="page-subtitle">
          Select a location to view its admin portal
        </div>
        <div className="search-input-container">
          <Icon icon="search" color="#7C7F93" />
          <input
            placeholder="Search for a location"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        {renderLocations(searchText)}
      </div>
    </div>
  );
};
export default StoreEmulator;
