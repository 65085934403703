import { View } from "@react-pdf/renderer";
import * as React from "react";

import { TableBody } from "./TableBody";
import { TableFooter } from "./TableFooter";
import { TableHeader } from "./TableHeader";

interface TableProps {
  data?: any[];
  style?: any;
}

export class Table extends React.PureComponent<TableProps> {
  render() {
    let tableHeader: JSX.Element | null = null;
    let tableBody: JSX.Element | null = null;
    let tableFooter: JSX.Element | null = null;

    React.Children.forEach(this.props.children, (c: any) => {
      if (c.type === TableHeader) {
        tableHeader = c;
      } else if (c.type === TableFooter) {
        tableFooter = c;
      } else if (c.type === TableBody) {
        tableBody = React.cloneElement(c, {
          data: c.props.data ?? this.props.data ?? [],
        });
      }
    });

    const additionalStyle = this.props.style || {};

    return (
      <View
        style={{
          width: "100%",
          ...additionalStyle,
        }}
      >
        {tableHeader}
        {tableBody}
        {tableFooter}
      </View>
    );
  }
}
