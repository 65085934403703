import { Icon } from "@blueprintjs/core";
import React, { KeyboardEvent, useEffect } from "react";

interface Props {
  options: string[];
  onSelect: (value: string) => void;
  selectedChoices: string[];
  color: string;
}
const MultipleChoice = ({
  options,
  onSelect,
  selectedChoices,
  color,
}: Props) => {
  const handleUserKeyPress = (event: KeyboardEvent) => {
    const alphabets = "abcdefghijklmnopqrstuvwxyz";
    const characterIndex = alphabets.indexOf(event.key);
    if (characterIndex >= 0 && characterIndex < options.length) {
      onSelect(options[characterIndex]);
    }
  };
  useEffect(() => {
    // @ts-ignore
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      // @ts-ignore
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, []);

  return (
    <div className="multiple-choice-container">
      {options.map((item, index) => {
        const keyCode = String.fromCharCode(index + 65);
        return (
          <div
            key={index}
            className={`single-choice-container active`}
            style={{ borderColor: color, color }}
            onClick={() => {
              onSelect(item);
            }}
          >
            <div
              className="key-bind apply-font"
              style={{ borderColor: color, color }}
            >
              {keyCode}
            </div>
            <div className="apply-font">{item}</div>
            {selectedChoices.includes(item) ? (
              <Icon icon="tick" color={color} />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
export default MultipleChoice;
