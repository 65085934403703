import { Spinner } from "@blueprintjs/core";
import { pdf } from "@react-pdf/renderer";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  WeeklyEmployeeSalaryReport,
  generateWeeklyEmployeesSalaryReports,
} from "controllers/reporting";

import Box from "components/Box";

import { RootState } from "model/store";

import Layout from "../../Layout";
import LaborReportModal from "../LaborReportModal";
import WeeklySalaryReportPdfDocument from "../PdfReports/WeeklySalaryReportPdfDocument";
import "../style.css";

const WeeklyEmployeeSalarySummary = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [weeklyReports, setWeeklyReports] = useState<
    WeeklyEmployeeSalaryReport[]
  >([]);
  const [earliestFetchedDate, setEarliestFetchedDate] = useState<Date | null>(
    null
  );
  const [latestResponseLength, setLatestResponseLength] = useState<
    null | number
  >(null);
  const [reportIdLoading, setReportIdLoading] = useState<string | null>(null);

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const fetchMoreData = useCallback(async () => {
    if (business && earliestFetchedDate) {
      const oldEarliestDate = earliestFetchedDate;
      const newEarliestDate = moment(oldEarliestDate)
        .subtract("days", 30)
        .toDate();
      const newWeeklyReports = await generateWeeklyEmployeesSalaryReports(
        business.id,
        newEarliestDate,
        oldEarliestDate
      );
      setEarliestFetchedDate(newEarliestDate);
      setLatestResponseLength(newWeeklyReports.length);
      setWeeklyReports(
        [...weeklyReports, ...newWeeklyReports].sort(
          (a, b) => moment(b.startDate).unix() - moment(a.startDate).unix()
        )
      );
    }
  }, [weeklyReports, earliestFetchedDate, business?.id]);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (business) {
        const earliestDate = moment().subtract("days", 60).toDate();
        const weeklyEmploeeSalaryReports =
          await generateWeeklyEmployeesSalaryReports(
            business.id,
            earliestDate,
            moment().toDate()
          );
        console.log("weeklyEmploeeSalaryReports", weeklyEmploeeSalaryReports);
        setEarliestFetchedDate(earliestDate);
        setLatestResponseLength(weeklyEmploeeSalaryReports.length);
        setWeeklyReports(
          weeklyEmploeeSalaryReports.sort(
            (a, b) => moment(b.startDate).unix() - moment(a.startDate).unix()
          )
        );
      }
    };
    fetchInitialData();
  }, [business?.id]);

  const createBlob = async (
    report: WeeklyEmployeeSalaryReport,
    snapshot = false
  ) => {
    try {
      const blob = await pdf(
        <WeeklySalaryReportPdfDocument
          report={report}
          businessName={business.businessName}
          snapshot={snapshot}
        />
      ).toBlob();
      const url = URL.createObjectURL(blob);
      setReportIdLoading(null);
      window.open(url, "_blank");
    } catch (e) {
      setReportIdLoading(null);
      alert("Opps, something went wrong generating your report");
      console.log("error generating report", e);
    }
  };

  const hasMoreDocumentsToLoad = _.isNull(latestResponseLength)
    ? true
    : Boolean(latestResponseLength);
  return (
    <Layout>
      <div id="cash-summary" className="daily-labor-report">
        <div className="navigation">
          <div className="navigation-title">Report</div>
          <div className="navigation-subtitle">Select to view below</div>
          {/* <Link to="/reporting">
              <div className="button">Sales Summary</div>
            </Link>
            <Link to="/product-analytics">
              <div  className="button">Product Analytics</div>
            </Link> */}
          <Link to="/core-reports">
            <div className="button active">Core Reports</div>
          </Link>
          <Link to="/bank-deposit">
            <div className="button">Bank Deposit Report</div>
          </Link>
        </div>
        <div className="right-section">
          <div className="body">
            <div className="page-header">
              <div className="page-title">
                Weekly Employee Salary Summary Reports
              </div>
            </div>
            <Box display="flex" flexDirection="column">
              <InfiniteScroll
                dataLength={weeklyReports.length}
                next={fetchMoreData}
                hasMore={hasMoreDocumentsToLoad}
                loader={
                  <div style={{ marginBottom: 50, padding: 20 }}>
                    {weeklyReports.length === 0 ? (
                      <div>
                        <Spinner size={40} />
                        <div
                          className="loading-text"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: 20,
                          }}
                        >
                          This may take a couple minutes to load
                        </div>
                      </div>
                    ) : (
                      <Spinner size={40} />
                    )}
                  </div>
                }
              >
                {weeklyReports.map((report) => {
                  const isLoadingBlob = reportIdLoading === report.id;
                  const renderData = () => {
                    if (moment(report.startDate).isSame(moment(), "week")) {
                      return (
                        <div
                          className="report-table-text"
                          onClick={() => {
                            setReportIdLoading(report.id);

                            createBlob(report, true);
                            // fetchAndOpenReport()
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          View Snapshot
                        </div>
                      );
                    }
                    if (isLoadingBlob) {
                      return <Spinner size={20} />;
                    }
                    return (
                      <div
                        className="report-table-text"
                        onClick={() => {
                          setReportIdLoading(report.id);

                          createBlob(report);
                          // fetchAndOpenReport()
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        View Report
                      </div>
                    );
                  };
                  return (
                    <Box
                      key={report.id}
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      style={{ padding: 5, marginTop: 10 }}
                    >
                      <div className="report-table-text">
                        {moment(report.startDate).format("MMMM D, YYYY")} -{" "}
                        {moment(report.endDate).format("MMMM D, YYYY")}
                      </div>
                      {renderData()}
                    </Box>
                  );
                })}
              </InfiniteScroll>
            </Box>
          </div>
        </div>
      </div>
      <LaborReportModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
      />
    </Layout>
  );
};
export default WeeklyEmployeeSalarySummary;
