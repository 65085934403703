export enum ComponentType {
  shortText = "short-text",
  longText = "long-text",
  email = "email",
  phone = "phone",
  url = "url",
  multipleChoice = "multiple-choice",
  pictureChoice = "picture-choice",
  yesNo = "yes-no",
  dropdown = "dropdown",
  ratings = "ratings",
  uploadFile = "upload-file",
  legal = "legal",
  correctOrder = "correct-order",
  digitalSignature = "digital-signature",
  labelPicture = "label-picture",
  fillInBlank = "fill-in-blank",
  matching = "matching",
}
export interface ThemeProp {
  questionColor: string;
  answerColor: string;
  buttonColor: string;
  buttonTextColor: string;
  backgroundColor: string;
  systemFont: string;
  themeName: string;
  backgroundImage: string | ArrayBuffer | null;
  backgroundImageBrightness: number;
  type: "system" | "custom";
  status: "saved" | "draft";
  id: string;
  businessId: string;
  createdAt: Date;
  updatedAt: Date;
  deleted: boolean;
  enabled: boolean;
}
export type ImageLayoutType =
  | "top"
  | "left"
  | "right"
  | "right-full"
  | "left-full"
  | "background";
export interface EndingSectionProps {
  title: string;
  subtitle: string;
  showButton: boolean;
  buttonLink: string;
  buttonLabel: string;
  image: string;
  showButtonLink: boolean;
  index: number;
  imageBrightness: number;
  imageLayout: ImageLayoutType;
}

export interface SurveyData {
  id: number;
  title: string;
  subtitle: string;
  type: ComponentType;
  required: boolean;
  value: string | null;
  choices: string[];
  fillInBlankChoices: Array<string>[] | null;
  selectedChoices: any[];
  label?: string;
  matchingObj?: { leftArray: string[]; rightArray: string[] } | null;
  images: string[];
}
