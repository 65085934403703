import {
  IAgoraRTCRemoteUser,
  ILocalAudioTrack,
  ILocalVideoTrack,
  UID,
} from "agora-rtc-sdk-ng";
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import MicLight from "assets/audio-white.svg";
import MicDark from "assets/audio.svg";
import CallCameraIcon from "assets/call-camera.svg";
import CallMicrophoneIcon from "assets/call-micro.svg";
import AddMembersToCallIcon from "assets/call_add_members.svg";
import MicOffDark from "assets/mic-off-dark.svg";
import MicOffLight from "assets/mic-off.svg";
import ShareScreenIcon from "assets/share_screen.svg";

import MediaPlayer from "../components/MediaPlayer";
import { useOutsideAlerter } from "../hooks/useOutsideAlerter";
import "../style.css";

const CallExpandedContainer = styled(motion.div)`
  width: 100vh;
  height: 60vh;
  border-radius: 15px;
  background-color: #424452;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 1001;
  border: 1px solid red;
`;

interface MinifiedCallConatinerProps {
  absolute?: boolean;
}

const MinifiedCallConatiner = styled(motion.div)`
  width: 12vw;
  background-color: #424452;
  ${(props: MinifiedCallConatinerProps) =>
    props.absolute
      ? `
    position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  `
      : "height: 93vh;"}
  z-index: 1001;
  border-left: 1px solid #777982;

  display: flex;
  flex-direction: column;
`;

const CallSideContainer = styled(motion.div)`
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const CallParticipantsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 7;
`;

const MinifiedCallParticipantsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 7;
`;
interface CallParticipantContainerProps {
  hasRightBorder?: boolean;
}
const CallParticipantContainer = styled(motion.div)`
  height: 20vh;
  width: 100%;
  ${(props: CallParticipantContainerProps) =>
    props.hasRightBorder ? "border-right: 1px solid #777982" : ""};
  border-bottom: 1px solid #777982;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const CallAvatar = styled.img`
  height: 25vh;
  width: 25vh;
  border-radius: 50%;
`;

const MinifiedCallAvatar = styled.img`
  height: 10vh;
  width: 10vh;
  border-radius: 50%;
`;

type CallNoImageProps = {
  bgColor: string;
  minified?: boolean;
};

const CallNoImage = styled.div`
  height: ${(props: CallNoImageProps) => (props.minified ? "10vh" : "25vh")};
  width: ${(props: CallNoImageProps) => (props.minified ? "10vh" : "25vh")};
  background-color: ${(props: CallNoImageProps) => props.bgColor};
  border-radius: 50%;
  color: #424452;
  font-family: Lato-Bold;
  font-weight: bold;
  font-size: ${(props: CallNoImageProps) => (props.minified ? "2em" : "7em")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ParticipantName = styled.div`
  color: #ffffff;
  font-family: Lato-Bold;
  font-weight: bold;
  font-size: 2em;
  margin-top: 3vh;
`;

const MinifiedParticipantName = styled.div`
  color: #ffffff;
  font-family: Lato-Semibold;
  font-weight: bold;
  font-size: 1.5vh;
`;

interface MinifiedUserVideoControlsProps {
  hasVideo?: boolean;
}

const MinifiedUserVideoControls = styled.div`
  position: absolute;
  padding: 0.5vh;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props: MinifiedUserVideoControlsProps) =>
    props.hasVideo ? "rgba(55, 57, 72, 0.5)" : "transparent"};
`;

const CallFooter = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MinifiedCallFooter = styled.div`
  height: 15vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const CallControlLeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`;

const CallControlRightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

const CallControlIcon = styled(motion.img)`
  height: 4vh;
  margin-left: 2vw;
  margin-top: 2vh;
  margin-bottom: 2vh;
`;
const MinifiedFooterControllContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;
const MinifiedCallControlIcon = styled(motion.img)`
  height: 2vh;
  margin-top: 2vh;
  margin-bottom: 2vh;
`;

const LeaveCallButton = styled(motion.div)`
  color: #424452;
  background-color: #ffffff;
  border-radius: 100px;
  padding: 1vh 2vw;
  font-family: Lato-Bold;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MinifiedLeaveCallButton = styled(motion.div)`
  color: #424452;
  background-color: #ffffff;
  border-radius: 100px;
  padding: 1vh 2vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-family: Lato-Bold;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GlobalCallAbsoluteContainer = styled(motion.div)`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 1001;
`;

const ExpandedScreenSharingWrapper = styled(motion.div)`
  background-color: #424452;
  height: 100vh;
  width: 88vw;
  display: flex;
  align-items: flex-start;
  padding-top: 5vh;
  justify-content: center;
`;

interface CallProps {
  localAudioTrack: ILocalAudioTrack | undefined;
  localVideoTrack: ILocalVideoTrack | undefined;
  localScreenSharingTrack: ILocalVideoTrack | undefined;

  user: StaffMember;
  selectedStaffMember: StaffMember | null;
  remoteUsers: IAgoraRTCRemoteUser[];
  disableLocalVideoTrack: () => void;
  enableLocalAudioTrack: () => void;
  disableLocalAudioTrack: () => void;
  enableLocalVideoTrack: () => void;
  enableLocalScreenSharing: () => void;
  leaveChannel: () => Promise<void>;
  disableLocalScreenSharing: () => void;
  fellowStaffMembers: StaffMember[];
  fullHeight?: boolean;
  setCommsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Call = (props: CallProps) => {
  const [remoteUserTrackExpandedId, setRemoveUserTrackExpandedId] =
    useState<null | UID>(null);
  const {
    localVideoTrack,
    user,
    selectedStaffMember,
    remoteUsers,
    localAudioTrack,
    disableLocalVideoTrack,
    enableLocalAudioTrack,
    disableLocalAudioTrack,
    enableLocalVideoTrack,
    leaveChannel,
    enableLocalScreenSharing,
    localScreenSharingTrack,
    fellowStaffMembers,
    disableLocalScreenSharing,
    setCommsExpanded,
  } = props;
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => null);
  const userAbbrName = user
    ? `${user.contact.firstName[0]}${user.contact.lastName[0]}`
    : null;
  const userFullName = user
    ? `${user.contact.firstName} ${user.contact.lastName}`
    : null;

  const remoteUserExpandedVideo = remoteUserTrackExpandedId
    ? remoteUsers.find((ru) => ru.uid === remoteUserTrackExpandedId)
    : null;

  useEffect(() => {
    if (remoteUserExpandedVideo && !remoteUserExpandedVideo.hasVideo) {
      setRemoveUserTrackExpandedId(null);
    }
  }, [remoteUserExpandedVideo]);

  const renderMinifiedContainer = () => (
    <MinifiedCallConatiner
      absolute={Boolean(remoteUserTrackExpandedId)}
      style={{
        height: remoteUserTrackExpandedId
          ? "100vh"
          : props.fullHeight
          ? "100%"
          : "93vh",
      }}
    >
      <CallSideContainer
        ref={wrapperRef}
        initial={{ opacity: 0, scale: 0.75 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0 }}
      >
        <MinifiedCallParticipantsContainer>
          <CallParticipantContainer>
            {localVideoTrack ? (
              <MediaPlayer
                height="20vh"
                width="17vw"
                videoTrack={localVideoTrack}
                audioTrack={undefined}
              />
            ) : (
              <>
                {user.imageUrl ? (
                  <MinifiedCallAvatar src={user.imageUrl} />
                ) : (
                  <CallNoImage minified bgColor="#FAECE8">
                    {userAbbrName}
                  </CallNoImage>
                )}
              </>
            )}

            <MinifiedUserVideoControls hasVideo={Boolean(localVideoTrack)}>
              <div />
              <MinifiedParticipantName>Me</MinifiedParticipantName>
            </MinifiedUserVideoControls>
          </CallParticipantContainer>
          {remoteUsers.map((remoteUser) => {
            const uid = remoteUser.uid;
            const staffMemberForRemoteUser = fellowStaffMembers.find(
              (sm) => sm.id === uid
            );
            if (!staffMemberForRemoteUser) return null;

            const remoteStaffAbbrName = `${staffMemberForRemoteUser.contact.firstName[0]}${staffMemberForRemoteUser.contact.lastName[0]}`;
            const remoteStaffFullName = `${staffMemberForRemoteUser.contact.firstName} ${staffMemberForRemoteUser.contact.lastName}`;
            return (
              <CallParticipantContainer
                whileTap={{ opacity: 0.8 }}
                onClick={() => {
                  if (remoteUserTrackExpandedId === remoteUser.uid) {
                    setRemoveUserTrackExpandedId(null);
                  } else {
                    setRemoveUserTrackExpandedId(remoteUser.uid);
                  }
                }}
              >
                {remoteUser.hasVideo &&
                remoteUserExpandedVideo?.uid !== remoteUser.uid ? (
                  <motion.div
                    className="remote-player-wrapper"
                    key={remoteUser.uid}
                  >
                    <MediaPlayer
                      height="20vh"
                      width="17vw"
                      videoTrack={remoteUser.videoTrack}
                      audioTrack={remoteUser.audioTrack}
                    />
                  </motion.div>
                ) : (
                  <>
                    <MediaPlayer
                      height="0vh"
                      width="0vw"
                      videoTrack={undefined}
                      audioTrack={remoteUser.audioTrack}
                    />
                    {staffMemberForRemoteUser?.imageUrl ? (
                      <MinifiedCallAvatar
                        src={staffMemberForRemoteUser.imageUrl}
                      />
                    ) : (
                      <CallNoImage minified bgColor="#FAECE8">
                        {remoteStaffAbbrName}
                      </CallNoImage>
                    )}
                  </>
                )}

                <MinifiedUserVideoControls
                  hasVideo={Boolean(remoteUser.videoTrack)}
                >
                  <div />
                  <MinifiedParticipantName>
                    {remoteStaffFullName}
                  </MinifiedParticipantName>
                </MinifiedUserVideoControls>
              </CallParticipantContainer>
            );
          })}
        </MinifiedCallParticipantsContainer>
      </CallSideContainer>
      <MinifiedCallFooter>
        <MinifiedFooterControllContainer>
          <MinifiedCallControlIcon
            onClick={() => {
              if (localAudioTrack) {
                disableLocalAudioTrack();
              } else {
                enableLocalAudioTrack();
              }
            }}
            whileTap={{ scale: 0.9, opacity: 0.9 }}
            src={CallMicrophoneIcon}
          />
          <MinifiedCallControlIcon
            onClick={() => {
              if (localVideoTrack) {
                disableLocalVideoTrack();
              } else {
                enableLocalVideoTrack();
              }
            }}
            whileTap={{ scale: 0.9, opacity: 0.9 }}
            src={CallCameraIcon}
          />
          <MinifiedCallControlIcon
            onClick={() => {
              if (localScreenSharingTrack) {
                disableLocalScreenSharing();
              } else {
                enableLocalScreenSharing();
              }
            }}
            whileTap={{ scale: 0.9, opacity: 0.9 }}
            src={ShareScreenIcon}
          />
        </MinifiedFooterControllContainer>
        <MinifiedLeaveCallButton
          onClick={leaveChannel}
          whileTap={{ scale: 0.9, opacity: 0.9 }}
        >
          Leave Call
        </MinifiedLeaveCallButton>
      </MinifiedCallFooter>
    </MinifiedCallConatiner>
  );

  if (
    remoteUserTrackExpandedId &&
    remoteUserExpandedVideo &&
    remoteUserExpandedVideo.hasVideo
  ) {
    return (
      <GlobalCallAbsoluteContainer>
        <ExpandedScreenSharingWrapper>
          <MediaPlayer
            height="80vh"
            width="80vw"
            borderRadius="15px"
            videoTrack={remoteUserExpandedVideo.videoTrack}
            audioTrack={remoteUserExpandedVideo.audioTrack}
          />
        </ExpandedScreenSharingWrapper>
        {renderMinifiedContainer()}
      </GlobalCallAbsoluteContainer>
    );
  }

  return renderMinifiedContainer();
  // return (
  //   <CallExpandedContainer
  //     ref={wrapperRef}
  //     initial={{ opacity: 0, scale: 0.75 }}
  //     animate={{ opacity: 1, scale: 1 }}
  //     exit={{ opacity: 0, scale: 0 }}
  //   >
  //     <CallParticipantsContainer>
  //       <CallParticipantContainer hasRightBorder>
  //         {localVideoTrack ? (
  //           <MediaPlayer
  //             videoTrack={localScreenSharingTrack || localVideoTrack}
  //             audioTrack={undefined}
  //           />
  //         ) : (
  //           <>
  //             {user.imageUrl ? (
  //               <CallAvatar src={user.imageUrl} />
  //             ) : (
  //               <CallNoImage bgColor="#FAECE8">{userAbbrName}</CallNoImage>
  //             )}
  //           </>
  //         )}

  //         <ParticipantName>{userFullName}</ParticipantName>
  //       </CallParticipantContainer>
  //       <CallParticipantContainer>
  //         {remoteUsers.length ? (
  //           <div className="remote-player-wrapper" key={remoteUsers[0].uid}>
  //             <p className="remote-player-text">{`remoteVideo(${remoteUsers[0].uid})`}</p>
  //             <MediaPlayer
  //               videoTrack={remoteUsers[0].videoTrack}
  //               audioTrack={remoteUsers[0].audioTrack}
  //             />
  //           </div>
  //         ) : (
  //           <>
  //             {selectedStaffMember?.imageUrl ? (
  //               <CallAvatar src={selectedStaffMember.imageUrl} />
  //             ) : (
  //               <CallNoImage bgColor="#FAECE8">
  //                 {otherParticipantAbbsName}
  //               </CallNoImage>
  //             )}
  //           </>
  //         )}

  //         <ParticipantName>{otherParticipantFullName}</ParticipantName>
  //       </CallParticipantContainer>
  //     </CallParticipantsContainer>
  //     <CallFooter>
  //       <CallControlLeftSide>
  //         <CallControlIcon
  //           onClick={() => {
  //             if (localAudioTrack) {
  //               disableLocalAudioTrack();
  //             } else {
  //               enableLocalAudioTrack();
  //             }
  //           }}
  //           whileTap={{ scale: 0.9, opacity: 0.9 }}
  //           src={CallMicrophoneIcon}
  //         />
  //         <CallControlIcon
  //           onClick={() => {
  //             if (localVideoTrack) {
  //               disableLocalVideoTrack();
  //             } else {
  //               enableLocalVideoTrack();
  //             }
  //           }}
  //           whileTap={{ scale: 0.9, opacity: 0.9 }}
  //           src={CallCameraIcon}
  //         />
  //         <CallControlIcon
  //           onClick={() => {
  //             if (localScreenSharingTrack) {
  //               disableLocalScreenSharing();
  //             } else {
  //               enableLocalScreenSharing();
  //             }
  //           }}
  //           whileTap={{ scale: 0.9, opacity: 0.9 }}
  //           src={ShareScreenIcon}
  //         />
  //       </CallControlLeftSide>
  //       <CallControlRightSide>
  //         <CallControlIcon
  //           whileTap={{ scale: 0.9, opacity: 0.9 }}
  //           style={{ marginLeft: 0, marginRight: "2vw" }}
  //           src={AddMembersToCallIcon}
  //         />
  //         <LeaveCallButton
  //           onClick={leaveChannel}
  //           whileTap={{ scale: 0.9, opacity: 0.9 }}
  //           style={{ marginRight: "2vw" }}
  //         >
  //           Leave Call
  //         </LeaveCallButton>
  //       </CallControlRightSide>
  //     </CallFooter>
  //   </CallExpandedContainer>
  // );
};
