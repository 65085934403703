import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../model/store";
import { fetchTypeForms } from "../../models/surveyBuilder";

type ListLayout = "grid" | "list";
type FilterType = "created" | "updated" | "alphabetical";

const useForms = () => {
  const [layoutType, setLayoutType] = useState<ListLayout>("grid");
  const [selectedFilter, setSelectedFilter] = useState<FilterType>("created");
  const [typeForms, setTypeForms] = useState<any[]>([]);
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchTypeForms(business.id);
      if (response.data) {
        setTypeForms(response.data);
      } else {
        console.log(response.error);
      }
    };
    if (business) {
      fetchData();
    }
  }, [business]);
  return {
    layoutType,
    setLayoutType,
    typeForms,
    selectedFilter,
  };
};
export default useForms;
