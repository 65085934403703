import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLaunchpad } from "views/LaunchPad/LaunchpadProvider";

import TangoImage from "../../assets/manager/tango.png";
import Box from "../../components/Box";
import { RootState } from "../../model/store";
import "./style.css";

interface Props {
  children: any;
}

const Layout = ({ children }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const user: StaffMember = useSelector((state: RootState) => state.user);
  const launchpad = useLaunchpad();
  return (
    <Box className="marketing-feedback">
      <Box
        className="layout_header admin-more"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Link to="/dashboard">
          <img src={TangoImage} alt="tango" />
        </Link>
        <Box display="flex" flexDirection="row" alignItems="center">
          {/* <Link to="" className="menu-item">
            <Box
              className={`layout_menu-item ${
                location.pathname === "/reporting" || location.pathname === '/product-analytics' ? "active" : ""
              }`}
              style={{ marginLeft: "35px" }}
            >
              Advertising
            </Box>
          </Link>
          <Link to="" className="menu-item">
            <Box
              className={`layout_menu-item ${
                location.pathname === "/business-info" ? "active" : ""
              }`}
              style={{ marginLeft: "35px" }}
            >
              Marketing
            </Box>
          </Link>
          <Link to="" className="menu-item">
            <Box
              className={`layout_menu-item ${
                location.pathname === "/settings" ? "active" : ""
              }`}
              style={{ marginLeft: "35px" }}
            >
              Feedback
            </Box>
          </Link> */}
          <Link to="/campaigns" className="menu-item">
            <Box
              className={`layout_menu-item ${
                location.pathname === "/campaigns" ? "active" : ""
              }`}
              style={{ marginLeft: "35px" }}
            >
              Campaigns
            </Box>
          </Link>
          <Link to="/audiences" className="menu-item">
            <Box
              className={`layout_menu-item ${
                location.pathname === "/audiences" ? "active" : ""
              }`}
              style={{ marginLeft: "35px" }}
            >
              Audiences
            </Box>
          </Link>
          <Link to="/gift-cards" className="menu-item">
            <Box
              className={`layout_menu-item ${
                location.pathname === "/gift-cards" ? "active" : ""
              }`}
              style={{ marginLeft: "35px" }}
            >
              Gift Cards
            </Box>
          </Link>
          <img
            src={
              require("../../assets/headers/marketing-more-launch.svg").default
            }
            className="launch-icon"
            onClick={() => launchpad.setVisibility(true)}
          />
          <img
            src={
              require("../../assets/headers/marketing-logo-mask.svg").default
            }
            className="launch-icon-mask"
          />
          <img
            src={require("../../assets/tango-icon.svg").default}
            className="tango-logo"
          />
        </Box>
        {/* <Box>{user?.contact?.firstName} {user?.contact?.lastName}</Box> */}
      </Box>
      {children}
    </Box>
  );
};
export default Layout;
