import { ManualPayoutsAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: ManualPayouts[] = [];

export default function payouts(
  state = initialState,
  action: ManualPayoutsAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_PAYOUTS:
      return action.payouts;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
