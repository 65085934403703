//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import Creatable from "react-select/creatable";
import styled from "styled-components";

import Spinner from "components/Spinner";

import ChannelArrowIcon from "assets/channel-search-arrow.svg";
import ChannelTagIcon from "assets/channel-tag.svg";

import {
  NoAvatarColors,
  StaffDirectMessageAvatar,
  StaffDirectMessageNoImage,
  count,
} from "../components/Sidebar";
import CreateChannelPrivacySwitch from "../components/Switch";
import { useCreateChannel } from "../hooks/useCreateChannel";
import "../style.css";
import { ContentContainer } from "./ui.shared";

const ChannelInfoRowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ChannelInfoInputLabelWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1vh;
`;

const CreateChannelInputWrapper = styled.div`
  background-color: #f5f7fb;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5vh 1.5vw;
`;

const ChannelInfoInputLabel = styled.div`
  font-family: Lato-Semibold;
  color: #424452;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 1.7vh;
  align-items: center;
  padding: 1vh 2vw 2vh 0;
`;

const OptionalLabel = styled.div`
  font-family: Lato;
  color: #aaadb6;
  font-weight: bold;
  font-size: 1.3vh;
  margin-left: 0.7vw;
`;

const ChannelInfoInput = styled.input`
  background-color: transparent;
  border: 0;
  font-family: Lato;
  color: #7c7f93;
  font-size: 1.5vh;
  min-width: 20vw;
  margin-left: 0.4vw;
`;

const PrivacySwitchLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1vh;
`;

const SeachDropdownWrapper = styled.div`
  position: absolute;
  top: 2vh;
  max-height: 40vh;
  background-color: white;
  box-shadow: 6px 6px 40px rgba(0, 0, 0, 0.1);
  min-height: 20vh;
  border-radius: 10px;
  width: 20vw;
  overflow-y: scroll;
`;

const SearchDropdownRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 1vh;
  &:hover {
    background-color: #f5f7fb;
  }
  cursor: pointer;
`;

const SearchRowAvatarContainer = styled.div`
  flex: 1;
`;

const SearchRowDescriptionContainer = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SearchRowDescriptionName = styled.div`
  font-family: Lato-Semibold;
  color: #424452;
  width: 100%;
  font-size: 1.5vh;
`;

const SearchRowDescriptionRole = styled.div`
  font-family: Lato;
  color: #7c7f93;
  font-size: 1.1vh;
  width: 100%;
`;

const SearchRowArrowContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CreateButton = styled(motion.div)`
  background-color: ${({ disabled }) => (disabled ? "#f5f7fb" : "#DBE2EF")};
  border-radius: 100px;
  display: flex;
  width: 7vw;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1vh 1.5vw;
  font-family: Lato;
  color: ${({ disabled }) => (disabled ? "#AAADB6" : "#424452")};
  font-size: 2vh;
  font-weight: 700;
  margin-top: 5vh;
  cursor: pointer;
`;

interface SearchDropdownProps {
  staffMembers: StaffMember[];
}

const SearchDropdown = ({ staffMembers }: SearchDropdownProps) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
      }}
    >
      <SeachDropdownWrapper>
        {staffMembers.map((sm) => {
          const staffMemberFullName = `${sm.contact.firstName} ${sm.contact.lastName}`;
          const abbrName = `${sm.contact.firstName[0]}${sm.contact.lastName[0]}`;
          const imageUrl = sm.imageUrl ?? null;
          const firstLetterCount = count(sm.uid, sm.uid[0]);
          const colorIndex =
            firstLetterCount > NoAvatarColors.length
              ? NoAvatarColors.length
              : firstLetterCount;
          const bgColor = NoAvatarColors[colorIndex] ?? "#FAECE8";
          return (
            <SearchDropdownRow>
              <SearchRowAvatarContainer>
                {imageUrl ? (
                  <StaffDirectMessageAvatar src={imageUrl} />
                ) : (
                  <StaffDirectMessageNoImage bgColor={bgColor}>
                    {abbrName}
                  </StaffDirectMessageNoImage>
                )}
              </SearchRowAvatarContainer>
              <SearchRowDescriptionContainer>
                <SearchRowDescriptionName>
                  {staffMemberFullName}
                </SearchRowDescriptionName>
                <SearchRowDescriptionRole>
                  Server | location_name
                </SearchRowDescriptionRole>
              </SearchRowDescriptionContainer>
              <SearchRowArrowContainer>
                <img src={ChannelArrowIcon} />
              </SearchRowArrowContainer>
            </SearchDropdownRow>
          );
        })}
      </SeachDropdownWrapper>
    </div>
  );
};

interface CreateChannelProps {
  finishCreatingChannelFlow: () => void;
}
const CreateChannel = (props: CreateChannelProps) => {
  const {
    multiSelectStyles,
    handleChannelMembersMultiselectChange,
    selectedChannelMembers,
    readyToCreateChannel,
    isPrivate,
    toggleChannelPrivacy,
    channelName,
    handleChannelNameChange,
    handleChannelDescriptionChange,
    channelDescription,
    loading,
    availableStaffOptions,
    createNewChannel,
  } = useCreateChannel({
    onFinish: props.finishCreatingChannelFlow,
  });

  return (
    <ContentContainer>
      {loading ? <Spinner /> : null}
      <ChannelInfoRowWrapper>
        <ChannelInfoInputLabelWrapper>
          <ChannelInfoInputLabel>Channel name</ChannelInfoInputLabel>
          <CreateChannelInputWrapper>
            <img src={ChannelTagIcon} />
            <ChannelInfoInput
              value={channelName}
              onChange={handleChannelNameChange}
              placeholder="ops_team"
            />
          </CreateChannelInputWrapper>
        </ChannelInfoInputLabelWrapper>
        <ChannelInfoInputLabelWrapper>
          <ChannelInfoInputLabel>
            Description <OptionalLabel>(Optional)</OptionalLabel>
          </ChannelInfoInputLabel>

          <CreateChannelInputWrapper>
            <ChannelInfoInput
              value={channelDescription}
              onChange={handleChannelDescriptionChange}
              placeholder="What is this channel about?"
            />
          </CreateChannelInputWrapper>
        </ChannelInfoInputLabelWrapper>
        <PrivacySwitchLabelWrapper>
          <ChannelInfoInputLabel>Private?</ChannelInfoInputLabel>
          <CreateChannelPrivacySwitch
            isOn={isPrivate}
            toggleSwitch={toggleChannelPrivacy}
          />
        </PrivacySwitchLabelWrapper>
      </ChannelInfoRowWrapper>
      <ChannelInfoRowWrapper>
        <ChannelInfoInputLabelWrapper>
          <ChannelInfoInputLabel>
            Channel members{" "}
            <OptionalLabel>
              (optional: you can invite members later on)
            </OptionalLabel>
          </ChannelInfoInputLabel>
          <CreateChannelInputWrapper style={{ padding: "1vh 1vw" }}>
            <Creatable
              placeholder="Add channel participants..."
              isClearable={false}
              isMulti
              styles={multiSelectStyles}
              menuPlacement="auto"
              menuPosition="fixed"
              options={availableStaffOptions}
              value={selectedChannelMembers}
              noOptionsMessage={() =>
                "We weren't able to find any staff members..."
              }
              onChange={handleChannelMembersMultiselectChange}
              isValidNewOption={() => false}
            />
          </CreateChannelInputWrapper>
        </ChannelInfoInputLabelWrapper>
        <PrivacySwitchLabelWrapper>
          <CreateButton
            onClick={createNewChannel}
            disabled={!readyToCreateChannel}
            whileTap={
              readyToCreateChannel ? { scale: 0.9, opacity: 0.9 } : undefined
            }
          >
            Create
          </CreateButton>
        </PrivacySwitchLabelWrapper>
      </ChannelInfoRowWrapper>
    </ContentContainer>
  );
};

export default CreateChannel;
