import { Button, Icon, Menu, MenuItem } from "@blueprintjs/core";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import React from "react";
import { Link } from "react-router-dom";

import Box from "../../components/Box";
import "./styles.css";
import useForms from "./useForms";

const ViewForms = () => {
  const { layoutType, setLayoutType, typeForms, selectedFilter } = useForms();

  const renderGridView = () => {
    return typeForms.map((item) => (
      <div className="form" key={item.id}>
        <Link to={`/form/${item.id}/${item.businessId}`}>
          <div className="form-body">{item.name}</div>
        </Link>
        <div className="form-footer">
          <div>No responses</div>
          <div>
            <Popover2
              placement="bottom"
              content={
                <Menu>
                  <MenuItem text="Child one" />
                  <MenuItem text="Child two" />
                  <MenuItem text="Child three" />
                </Menu>
              }
            >
              <Icon icon="more" color={"#231f20"} />
            </Popover2>
          </div>
        </div>
      </div>
    ));
  };
  const renderListView = () => {
    return typeForms.map((item) => (
      <div className="form" key={item.id}>
        <div className="form-body">
          <Link to={`/form/${item.id}/${item.businessId}`}>
            <div className="left-part">
              <div className="form-title">{item.name}</div>
              <div className="date">Created: 17 Feb 2021</div>
            </div>
          </Link>
          <div className="right-part">
            <Popover2
              placement="bottom"
              content={
                <Menu>
                  <MenuItem text="Child one" />
                  <MenuItem text="Child two" />
                  <MenuItem text="Child three" />
                </Menu>
              }
            >
              <Icon icon="more" color={"#231f20"} />
            </Popover2>
          </div>
        </div>
      </div>
    ));
  };
  return (
    <div className="view-forms-container">
      <div className="view-forms">
        <div className="header">
          <Button
            text="create typeform"
            icon={<Icon icon="plus" color="#fff" />}
            className="create-form-button"
          />
          <Box display="flex" alignItems="center">
            <Popover2
              placement="bottom"
              content={
                <Menu>
                  <MenuItem text="Date Created" />
                  <MenuItem text="Date Updated" />
                  <MenuItem text="Alphabetical" />
                </Menu>
              }
            >
              <Box display="flex" alignItems="center">
                <Button
                  intent="primary"
                  text="Date Created"
                  className="date-dropdown"
                  rightIcon={<Icon icon="chevron-down" />}
                />
              </Box>
            </Popover2>
            <div>
              <Button
                onClick={() => setLayoutType("grid")}
                icon={
                  <Icon
                    icon="grid"
                    color={layoutType === "grid" ? "#fff" : "#231f20"}
                  />
                }
                text="Grid"
                className={`list-layout-button ${
                  layoutType === "grid" ? "active" : ""
                }`}
              />
              <Button
                onClick={() => setLayoutType("list")}
                icon={
                  <Icon
                    icon="list"
                    color={layoutType === "list" ? "#fff" : "#231f20"}
                  />
                }
                text="List"
                className={`list-layout-button ${
                  layoutType === "list" ? "active" : ""
                }`}
              />
            </div>
          </Box>
        </div>
        <div className={`form-list ${layoutType}`}>
          {layoutType === "grid" ? renderGridView() : renderListView()}
        </div>
      </div>
    </div>
  );
};
export default ViewForms;
