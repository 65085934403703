import _ from "lodash";
import mime from "mime";

import { BASE_TANGO_STORAGE_FOLDER } from "controllers/tangoStorage";

import firebase from "../config/firebase";
import { storage } from "../config/firebase";

const db = firebase.firestore();
const docsCollection = db.collection("Docs");
const enterpriseDocsCollection = db.collection("EnterpriseDocs");
const businessSettingsCollection = db.collection("BusinessSettings");

const getDoc = async (
  businessId: string,
  accountId: string = "",
  parentId: string = ""
) => {
  return parentId
    ? await enterpriseDocsCollection.doc(parentId).get()
    : accountId
    ? await enterpriseDocsCollection.doc(accountId).get()
    : await docsCollection.doc(businessId).get();
};

const createFirstParentDoc = (
  businessId: string,
  accountId: string = "",
  parentId: string = ""
) => {
  return parentId
    ? enterpriseDocsCollection.doc(parentId)
    : accountId
    ? enterpriseDocsCollection.doc(accountId)
    : docsCollection.doc(businessId);
};

const createDoc = (
  businessId: string,
  accountId: string = "",
  parentId: string = ""
) => {
  return parentId
    ? enterpriseDocsCollection.doc(parentId).collection("FilesAndFolders").doc()
    : accountId
    ? enterpriseDocsCollection
        .doc(accountId)
        .collection("FilesAndFolders")
        .doc()
    : docsCollection.doc(businessId).collection("FilesAndFolders").doc();
};

const getDocReference = (
  docId: string,
  businessId: string,
  accountId: string = "",
  parentId: string = ""
) => {
  return parentId
    ? enterpriseDocsCollection
        .doc(parentId)
        .collection("FilesAndFolders")
        .doc(docId)
    : accountId
    ? enterpriseDocsCollection
        .doc(accountId)
        .collection("FilesAndFolders")
        .doc(docId)
    : docsCollection.doc(businessId).collection("FilesAndFolders").doc(docId);
};

export const generateFirstDocsDocument = async (
  businessId: string,
  accountId: string = "",
  parentId: string = ""
) => {
  try {
    // All the docs will be saved in the parent ID
    const doc = await getDoc(businessId, accountId, parentId);
    // If no document exists then create a document with 1 subcollection
    // which is the root directory for Docs
    if (!doc.exists) {
      const mainDocRef = createFirstParentDoc(businessId, accountId, parentId);
      const mainDoc = {
        id: businessId,
        businessId: businessId,
        createdAt: new Date(),
        updatedAt: new Date(),
        deleted: false,
      };
      await mainDocRef.set(mainDoc);

      const rootDocRef = createDoc(businessId, accountId, parentId);
      const rootDoc: FilesAndFolders = {
        id: "",
        parentAccountId: parentId,
        accountId: accountId,
        businessId: businessId,
        createdAt: new Date(),
        updatedAt: new Date(),
        deleted: false,
        parentId: "",
        childrenIds: [],
        absolutePath: "",
        downloadUrl: null,
        isFolder: true,
        deletedAt: null,
        deletedTemporarily: false,
        fileSize: 0,
        fileExtension: null,
        name: "root",
        starred: false,
        description: "",
        authorId: { staffId: "", accessType: "editor" as DocAccessType },
        access: [],
        tags: [],
        businessAccess: accountId ? (businessId ? [businessId] : []) : [],
      };
      rootDoc.id = rootDocRef.id;
      rootDoc.parentId = rootDocRef.id;
      await rootDocRef.set(rootDoc);
    }
  } catch (err) {
    console.log("ERR: " + err);
  }
};

export const getAllFilesAndFoldersForFolder = (
  folder: FilesAndFolders,
  allFiles: FilesAndFolders[],
  current: FilesAndFolders[]
): FilesAndFolders[] => {
  for (const id of folder.childrenIds) {
    const index = allFiles.findIndex((f) => f.id === id);
    if (index !== -1) {
      const fileFound = allFiles[index];
      current.push(fileFound);

      if (fileFound.childrenIds.length > 0) {
        return getAllFilesAndFoldersForFolder(fileFound, allFiles, current);
      }
    }
  }
  return current;
};

export const revokeAccessForFileOrFolder = async (
  fileOrFolder: FilesAndFolders,
  usersToRemove: StaffMember[]
) => {
  const staffIds = usersToRemove.map((i) => i.staffMemberCopyId);
  await getDocReference(
    fileOrFolder.id,
    fileOrFolder.businessId,
    fileOrFolder.accountId || "",
    fileOrFolder.parentAccountId || ""
  ).update({
    access: _.uniq([
      ...fileOrFolder.access.filter(
        (staff) => !staffIds.includes(staff.staffId)
      ),
    ]).filter((i) => !!i),
  });
};

export const updateFileInfo = async (
  fileOrFolder: FilesAndFolders,
  fileInfo: { name: string; description: string; tags: string[] }
) => {
  const docRef = getDocReference(
    fileOrFolder.id,
    fileOrFolder.businessId,
    fileOrFolder.accountId || "",
    fileOrFolder.parentAccountId || ""
  );
  await docRef.update(fileInfo);
};

export const revokeFileOrFolderFromStaff = async (
  fileOrFolder: FilesAndFolders,
  selectedUser: DocUser
) => {
  await getDocReference(
    fileOrFolder.id,
    fileOrFolder.businessId,
    fileOrFolder.accountId || "",
    fileOrFolder.parentAccountId || ""
  ).update({
    access: fileOrFolder.access.filter(
      (staff) => staff.staffId !== selectedUser.staffId
    ),
  });
};

export const revokeFileOrFolderFromBusiness = async (
  fileOrFolder: FilesAndFolders,
  selectedBusiness: TangoBusiness
) => {
  const docRef = getDocReference(
    fileOrFolder.id,
    fileOrFolder.businessId,
    fileOrFolder.accountId || "",
    fileOrFolder.parentAccountId || ""
  );
  await docRef.update({
    businessAccess: (fileOrFolder.businessAccess || []).filter(
      (businessId) => businessId !== selectedBusiness.id
    ),
  });
};

export const shareFileOrFolder = async (
  fileOrFolder: FilesAndFolders,
  selectedUsers: StaffMember[],
  staffPriveleges: DocUser[],
  businessAccess: TangoBusiness[]
) => {
  const newUsers = selectedUsers.map((user) => ({
    staffId: user.staffMemberCopyId || "",
    accessType: "editor",
  }));
  const updatedAccess = fileOrFolder.access.map((staff) => {
    const index = staffPriveleges.findIndex(
      (updatedStaff) => updatedStaff.staffId === staff.staffId
    );
    if (index !== -1) {
      return staffPriveleges[index];
    }
    return staff;
  });

  await getDocReference(
    fileOrFolder.id,
    fileOrFolder.businessId,
    fileOrFolder.accountId || "",
    fileOrFolder.parentAccountId || ""
  ).update({
    // Updated access comes after new users because
    access: _.uniqBy([...updatedAccess, ...newUsers], "staffId").filter(
      (i) => !!i.staffId
    ),
    businessAccess: _.uniq(businessAccess.map((business) => business.id)),
  });
};

export const getStaffMemberName = (
  staffId: string,
  allStaffMembers: StaffMember[]
) => {
  const staff = allStaffMembers.filter(
    (staff) => staff.staffMemberCopyId === staffId
  );
  if (staff.length) {
    return staff[0].contact.firstName + " " + staff[0].contact.lastName;
  }
  return "";
};

export const updateDocTags = async (businessId: string, tags: DocTag[]) => {
  if (businessId) {
    await businessSettingsCollection.doc(businessId).update({
      docsTags: tags,
    });
  }
};

export const updateFolderTags = async (businessId: string, tags: DocTag[]) => {
  try {
    if (businessId) {
      await businessSettingsCollection.doc(businessId).update({
        folderTags: tags,
      });
    }
  } catch (err) {
    console.log("ERR: ", err);
  }
};

export const updateDocIdsInFolderTags = async (
  businessId: string,
  folderTags: DocTag[],
  currentTags: string[],
  deletedTags: string[],
  fileId: string
) => {
  // businessId could be null if we're in the enterprise level
  if (businessId) {
    // Remove the tags from deleted if they've been re-added - and it was just an accident
    const nonAccidentalDeletes = _.uniq(
      deletedTags.filter((tag) => !currentTags.includes(tag))
    );
    const updatedTags = folderTags.reduce((acc, val, i) => {
      if (nonAccidentalDeletes.includes(val.name)) {
        // Remove the document ID from the array
        const newIds = acc[i].ids.filter((id) => fileId !== id);
        acc[i].ids = newIds;

        // Add a new document id
      } else if (currentTags.includes(val.name)) {
        const newIds = [...acc[i].ids, fileId];
        acc[i].ids = newIds;
      }

      return acc;
    }, folderTags);

    // Add any new tags to the business settings
    const allTags = updatedTags.map((tag) => tag.name);
    const newTags = currentTags
      .filter((tag) => !allTags.includes(tag))
      .map((tag) => ({ name: tag, count: 1, deleted: false, ids: [fileId] }));

    const finalTags = [...updatedTags, ...newTags];
    await updateFolderTags(businessId, finalTags);
  }
};

export const getFinalTags = (
  businessSettingTags: DocTag[],
  currentTags: string[],
  deletedTags: string[],
  file?: FilesAndFolders
) => {
  // Remove the tags from deleted if they've been re-added - and it was just an accident
  const nonAccidentalDeletes = _.uniq(
    deletedTags.filter((tag) => !currentTags.includes(tag))
  );

  const updatedTags = businessSettingTags.reduce((acc, val, i) => {
    // Delete an unused tag from that file
    if (nonAccidentalDeletes.includes(val.name)) {
      if (businessSettingTags[i].count === 1) {
        acc[i].deleted = true;
        acc[i].count = 0;
      } else {
        acc[i].count -= 1;
      }

      if (file) {
        // Remove the document ID from the array
        const newIds = acc[i].ids.filter((id) => id !== file.id);
        acc[i].ids = newIds;
      }

      // Increment the tag count for an existing tag
    } else if (currentTags.includes(val.name)) {
      acc[i].count += 1;

      if (file) {
        // Add the document ID from the array
        const newIds = [...acc[i].ids, file.id];
        acc[i].ids = newIds;
      }
    }

    return acc;
  }, businessSettingTags);

  // Add any new tags to the business settings
  const allTags = updatedTags.map((tag) => tag.name);
  const newTags = currentTags
    .filter((tag) => !allTags.includes(tag))
    .map((tag) => {
      const ids = file ? [file.id] : [];
      return { name: tag, count: 1, deleted: false, ids };
    });

  return [...updatedTags, ...newTags];
};

export const addFolder = async (
  businessId: string,
  name: string,
  parentFolder: FilesAndFolders,
  staffId: string,
  selectedUsers: StaffMember[],
  folderTags: DocTag[],
  currentTags: string[],
  description: string = "",
  accountId: string = "",
  parentId: string = "",
  businessAccess: TangoBusiness[] = []
) => {
  await updateFolderTags(businessId, folderTags);
  // Create a new doc
  const docRef = createDoc(businessId, accountId, parentId);
  const doc: FilesAndFolders = {
    id: "",
    parentAccountId: parentId,
    accountId: accountId,
    businessId: businessId,
    createdAt: new Date(),
    updatedAt: new Date(),
    deleted: false,
    parentId: parentFolder.id,
    childrenIds: [],
    absolutePath: parentFolder.absolutePath + "/" + parentFolder.name,
    downloadUrl: null,
    isFolder: true,
    deletedAt: null,
    deletedTemporarily: false,
    fileSize: 0,
    fileExtension: null,
    name: name.replace("/", "-"),
    starred: false,
    description: description,
    authorId: { staffId: staffId, accessType: "editor" as DocAccessType },
    access: _.uniqBy(
      [
        { staffId: staffId, accessType: "editor" as DocAccessType },
        ...selectedUsers.map((user) => ({
          staffId: user.staffMemberCopyId || "",
          accessType: "editor" as DocAccessType,
        })),
      ],
      "staffId"
    ).filter((i) => !!i.staffId),
    tags: currentTags,
    businessAccess:
      businessAccess && businessAccess.length
        ? _.uniq(businessAccess.map((i) => i.id))
        : accountId
        ? businessId
          ? [businessId]
          : []
        : [],
  };
  doc.id = docRef.id;
  await docRef.set(doc);

  // Add the children IDs reference to the parent doc
  const parentDocRef = getDocReference(
    parentFolder.id,
    businessId,
    accountId,
    parentId
  );
  await parentDocRef.update({
    childrenIds: [...parentFolder.childrenIds, doc.id],
  });

  return doc;
};

export const createDirectoryForBusinessIfNew = async (businessId: string) => {
  try {
    const rootRef = storage.ref(`${BASE_TANGO_STORAGE_FOLDER}/${businessId}`);
    const listResult = await rootRef.list({
      maxResults: 2,
    });
    const files = listResult.items.map((i) => i.name);
    // This means the folder doesn't exist so we should create a folder
    if (files.length === 0) {
      const ghostfile = new File([], ".ghostfile");
      await rootRef.child(".ghostfile").put(ghostfile);
    }
  } catch (err) {
    console.log("ERR: ", err);
    return null;
  }
};

export const addFileInStorage = async (
  id: string,
  docName: string,
  data: File
) => {
  try {
    const rootRef = storage.ref(`${BASE_TANGO_STORAGE_FOLDER}/${id}`);
    const result = await rootRef.child(docName).put(data);
    const url = await result.ref.getDownloadURL();

    if (!url) {
      // Firebase Storage returns any for getDownloadURL so it's possible
      // if the subscriber from their end fails that we might not have
      // had a successful operation
      return null;
    }

    const fileSizeInKB = result.metadata.size / 1000;
    const fileType = result.metadata.contentType || "";
    const metadata = {
      url,
      fileSizeInKB,
      fileType,
    };
    return metadata;
  } catch (err) {
    console.log("ERR: ", err);
    return null;
  }
};

export const addFile = async (
  businessId: string,
  name: string,
  parentFolder: FilesAndFolders,
  staffId: string,
  files: File[],
  description: string = "",
  accountId: string = "",
  parentId: string = ""
) => {
  const folderId = parentId || accountId || businessId;

  await createDirectoryForBusinessIfNew(folderId);

  const childrenIds: string[] = [];
  for await (const file of files) {
    // If multiple files are selected - we have to default
    // to the name used in the file as opposed to a custom
    // name passed from the modal

    // TODO: Add tags
    const docRef = createDoc(businessId, accountId, parentId);
    const fileName = name && files.length === 1 ? name : file.name;
    const doc: FilesAndFolders = {
      id: "",
      parentAccountId: parentId,
      accountId: accountId,
      businessId: businessId,
      createdAt: new Date(),
      updatedAt: new Date(),
      deleted: false,
      parentId: parentFolder.id,
      childrenIds: [],
      absolutePath: parentFolder.absolutePath + "/" + parentFolder.name,
      downloadUrl: null,
      isFolder: false,
      deletedAt: null,
      deletedTemporarily: false,
      fileSize: 0,
      fileExtension: null,
      // Remove all instances of / in the name to avoid issues in the absolute path
      name: fileName.replace("/", "-"),
      starred: false,
      description: description,
      authorId: { staffId: staffId, accessType: "editor" as DocAccessType },
      access: [{ staffId: staffId, accessType: "editor" as DocAccessType }],
      tags: [],
      businessAccess: accountId ? (businessId ? [businessId] : []) : [],
    };
    doc.id = docRef.id;

    // Use the document ID as the final name because 2 files with the same
    // name can be used
    const metadata = await addFileInStorage(folderId, doc.id, file);

    // Metadata can be null if the operation failed
    if (metadata) {
      doc.downloadUrl = metadata.url;
      doc.fileSize = metadata.fileSizeInKB;
      doc.fileExtension = metadata.fileType;

      await docRef.set(doc);
      childrenIds.push(doc.id);
    }
  }

  // Add the children IDs reference to the parent doc
  const parentDocRef = getDocReference(
    parentFolder.id,
    businessId,
    accountId,
    parentId
  );
  await parentDocRef.update({
    childrenIds: [...parentFolder.childrenIds, ...childrenIds],
  });
};

export const changeStarredStatus = async (
  businessId: string,
  item: FilesAndFolders,
  status: boolean
) => {
  try {
    const docRef = getDocReference(
      item.id,
      businessId,
      item.accountId || "",
      item.parentAccountId || ""
    );
    docRef.update({
      starred: status,
    });
  } catch (err) {
    console.log("ERR: ", err);
  }
};

export const updateTheAbsolutePath = async (
  businessId: string,
  item: FilesAndFolders,
  newAbsolutePath: string
) => {
  try {
    // Set the new absolute path for file and folder
    const docRef = getDocReference(
      item.id,
      businessId,
      item.accountId || "",
      item.parentAccountId || ""
    );
    await docRef.update({
      absolutePath: newAbsolutePath,
    });
  } catch (err) {
    console.log("ERR: ", err);
  }
};

export const renameFileOrFolder = async (
  businessId: string,
  item: FilesAndFolders,
  newName: string
) => {
  try {
    // Set the new name of the file or folder
    const docRef = getDocReference(
      item.id,
      businessId,
      item.accountId || "",
      item.parentAccountId || ""
    );
    await docRef.update({
      name: newName.replace("/", "-"),
    });
  } catch (err) {
    console.log("ERR: ", err);
  }
};

export const deleteFileOrFolder = async (
  businessId: string,
  item: FilesAndFolders,
  parentDoc: FilesAndFolders,
  childrenFiles: FilesAndFolders[]
) => {
  try {
    // Set the deleted flag to true
    const docRef = getDocReference(
      item.id,
      businessId,
      item.accountId || "",
      item.parentAccountId || ""
    );
    await docRef.update({
      deleted: true,
    });

    // Remove the children IDs from the parent IDs
    const parentDocRef = getDocReference(
      item.parentId,
      businessId,
      item.accountId || "",
      item.parentAccountId || ""
    );
    const newChildrenIds: string[] = parentDoc.childrenIds.filter(
      (id) => id !== item.id
    );
    await parentDocRef.update({
      childrenIds: newChildrenIds,
    });

    // If it's a folder then we should delete the children IDs as well
    if (childrenFiles && childrenFiles.length > 0) {
      for await (const file of childrenFiles) {
        const docRef = getDocReference(
          file.id,
          businessId,
          item.accountId || "",
          item.parentAccountId || ""
        );
        await docRef.update({
          deleted: true,
        });
      }
    }
  } catch (err) {
    console.log("ERR: ", err);
  }
};

export const getFileType = (type: string) => {
  return mime.getExtension(type)?.toUpperCase();
  // const splitType = type.split('/');
  // if (splitType.length > 1) {
  //   switch (splitType[0].toLowerCase()) {
  //     case 'text':
  //       return 'Text';
  //     default:
  //       return splitType[1].split(' ').length === 1 ? splitType[1].toUpperCase() : _.startCase(_.toLower(splitType[1]));
  //   }
  // }
  // return splitType[0];
};

export const getFileSize = (size: number) => {
  const sizeInString = String(size);
  const sizeLength = sizeInString.split(".")[0].length;
  if (sizeLength <= 3) {
    return size.toFixed(2) + " KB";
  } else if (sizeLength <= 6) {
    return (size / 1000).toFixed(2) + " MB";
  } else if (sizeLength <= 9) {
    return (size / 1000000).toFixed(2) + " GB";
  } else if (sizeLength <= 12) {
    return (size / 1000000000).toFixed(2) + " TB";
  }
  return size.toFixed(2) + " KB";
};
