import {
  ILocalAudioTrack,
  ILocalVideoTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
} from "agora-rtc-sdk-ng";
import React, { useEffect, useRef } from "react";

export interface VideoPlayerProps {
  videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined;
  audioTrack: ILocalAudioTrack | IRemoteAudioTrack | undefined;
  height?: string | number;
  width?: string | number;
  borderRadius?: string | number;
}

const MediaPlayer = (props: VideoPlayerProps) => {
  const container = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!container.current) return;
    props.videoTrack?.play(container.current);
    return () => {
      props.videoTrack?.stop();
    };
  }, [container, props.videoTrack]);
  useEffect(() => {
    if (props.audioTrack) {
      props.audioTrack?.play();
    }
    return () => {
      props.audioTrack?.stop();
    };
  }, [props.audioTrack]);

  return (
    <div
      ref={container}
      className="video-player"
      style={
        props.videoTrack
          ? {
              width: props.width ?? "35vh",
              height: props.height ?? "25vh",
              borderRadius: props.borderRadius ?? "0px",
              overflow: "hidden",
            }
          : { visibility: "hidden" }
      }
    ></div>
  );
};

export default MediaPlayer;
