// @ts-ignore
// @ts-ignore
import {
  Button,
  IToasterProps,
  Icon,
  Toast,
  ToastProps,
  Toaster,
} from "@blueprintjs/core";
import FontPicker from "font-picker-react";
import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Box from "../../components/Box";
import Email from "./components/admin/Email";
import Legal from "./components/admin/Legal";
import PhoneInput from "./components/admin/Phone";
import UrlInput from "./components/admin/Url";
import YesNo from "./components/admin/YesNo";
import CorrectOrder from "./components/client/CorrectOrder";
import DigitalSignature from "./components/client/DigitalSignature";
import Dropdown from "./components/client/Dropdown";
import FillIntheBlank from "./components/client/FillInTheBlank";
import LabelPicture from "./components/client/LabelPicture";
import Matching from "./components/client/Matching";
import MultipleChoice from "./components/client/MultipleChoice";
import PictureChoice from "./components/client/PictureChoice";
import Rate from "./components/client/Ratings";
import ShortText from "./components/client/ShortText";
import UploadFile from "./components/client/UploadFile";
import "./form.css";
import useSurvey from "./hooks/useSurvey";
import { ComponentType } from "./types";

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
const urlRegex =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

type ScrollDirectionType = "down" | "up";

const RenderForm = () => {
  const { id = "", businessId = "" } =
    useParams<{ id: string; businessId: string }>();
  const toastRef = useRef<IToasterProps>(null);

  const {
    surveryData,
    showUsernameInput,
    endingSection,
    theme,
    isAnonymous,
    currentStep,
    username,
    setUsername,
    updateInputValues,
    updateMultipleChoice,
    handleOnKeyDown,
    addLabelToPicture,
    updateCorrectOrderChoices,
    handleFileChange,
    updateMatchingChoices,
    updateFillInTheBlankAnswers,
    gotoNextStep,
    submitForm,
    setCurrentStep,
    setShowUsernameInput,
    showEndingSection,
  } = useSurvey(id, businessId, toastRef);
  const [scrollDirection, setScrollDirection] =
    useState<ScrollDirectionType>("up");

  const handleUserKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      handleOnKeyDown(event);
    }
  };

  useEffect(() => {
    // @ts-ignore
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      // @ts-ignore
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [surveryData, currentStep]);

  const renderComponent = (type: ComponentType) => {
    switch (type) {
      case ComponentType.shortText:
      case ComponentType.longText:
        return (
          <ShortText
            color={theme.answerColor}
            onChange={(e) => updateInputValues(e.target.value)}
            value={surveryData[currentStep]?.value || ""}
          />
        );
      case ComponentType.phone:
        return (
          <PhoneInput
            color={theme.answerColor}
            phoneNumber={surveryData[currentStep]?.value || ""}
            onChange={(phoneNumber) => updateInputValues(phoneNumber)}
          />
        );
      case ComponentType.email:
        return (
          <Email
            onChange={(e) => updateInputValues(e.target.value)}
            color={theme.answerColor}
          />
        );
      case ComponentType.url:
        return (
          <UrlInput
            onChange={(e) => updateInputValues(e.target.value)}
            color={theme.answerColor}
          />
        );
      case ComponentType.multipleChoice:
        return (
          <MultipleChoice
            selectedChoices={surveryData[currentStep].selectedChoices}
            options={surveryData[currentStep]?.choices || []}
            onSelect={updateMultipleChoice}
            color={theme?.answerColor || "#000"}
          />
        );
      case ComponentType.pictureChoice:
        return (
          <PictureChoice
            color={theme?.answerColor || "#000"}
            selectedChoices={surveryData[currentStep].value}
            options={surveryData[currentStep]?.choices || []}
            onSelect={updateInputValues}
            images={surveryData[currentStep]?.images || []}
          />
        );
      case ComponentType.correctOrder:
        return (
          <CorrectOrder
            options={surveryData[currentStep]?.choices || []}
            updateChoice={updateCorrectOrderChoices}
          />
        );
      case ComponentType.yesNo:
        return (
          <YesNo
            color={theme?.answerColor || "#000"}
            updateChoice={(value) => updateInputValues(value)}
            value={surveryData[currentStep].value}
          />
        );
      case ComponentType.dropdown:
        return (
          <Dropdown
            color={theme.answerColor}
            options={surveryData[currentStep].choices}
            selectedVale={surveryData[currentStep].value}
            onChange={(option: string) => updateInputValues(option)}
          />
        );
      case ComponentType.ratings:
        return (
          <Rate
            color={theme.answerColor}
            rating={parseInt(surveryData[currentStep]?.value || "")}
            onRating={(rating) => updateInputValues(rating.toString())}
          />
        );
      case ComponentType.uploadFile:
        return (
          <UploadFile
            color={theme.answerColor}
            handleFileChange={handleFileChange}
            selectedValue={surveryData[currentStep]?.value || ""}
          />
        );
      case ComponentType.legal:
        return (
          <Legal
            updateChoice={(value) => updateInputValues(value)}
            value={surveryData[currentStep].value}
            color={theme.answerColor}
          />
        );
      case ComponentType.digitalSignature:
        return (
          <DigitalSignature
            color={theme.answerColor}
            onChange={(option: string) => updateInputValues(option)}
          />
        );
      case ComponentType.labelPicture:
        return (
          <LabelPicture
            updateChoice={addLabelToPicture}
            value={surveryData[currentStep].value || ""}
            color={theme.answerColor}
            images={surveryData[currentStep].images}
          />
        );
      case ComponentType.fillInBlank:
        return (
          <FillIntheBlank
            color={theme.answerColor}
            value={surveryData[currentStep].fillInBlankChoices}
            selectedAnswers={surveryData[currentStep].selectedChoices}
            updateAnswer={updateFillInTheBlankAnswers}
          />
        );
      case ComponentType.matching:
        return (
          <Matching
            color={theme.answerColor}
            value={surveryData[currentStep].matchingObj}
            updateChoice={updateMatchingChoices}
          />
        );
    }
  };

  const getButtonDisableState = () => {
    switch (surveryData[currentStep].type) {
      case ComponentType.email:
        return !emailRegex.test(surveryData[currentStep]?.value || "");
      case ComponentType.url:
        return !urlRegex.test(surveryData[currentStep]?.value || "");
      case ComponentType.multipleChoice:
        return (
          surveryData[currentStep].required &&
          surveryData[currentStep]?.selectedChoices.length === 0
        );
      case ComponentType.yesNo:
        return (
          surveryData[currentStep].required && !surveryData[currentStep]?.value
        );
      case ComponentType.dropdown:
        return (
          surveryData[currentStep].required && !surveryData[currentStep].value
        );
      case ComponentType.fillInBlank:
        return (
          surveryData[currentStep].required &&
          !surveryData[currentStep].selectedChoices.length
        );
      case ComponentType.correctOrder:
        return false;
      default:
        return (
          surveryData[currentStep].required && !surveryData[currentStep].value
        );
    }
  };
  const renderEndingSection = () => {
    return (
      <div className="survey-form-body question-container">
        {endingSection &&
        endingSection.image &&
        endingSection.imageLayout === "background" ? (
          <>
            <div
              className="form-bg-image"
              style={{
                backgroundImage: `url(${endingSection.image})`,
              }}
            />
            <div
              className="form-bg-image bg-color"
              style={{
                backgroundColor:
                  endingSection.imageBrightness > 0 ? "#fff" : "#000",
                opacity:
                  endingSection.imageBrightness > 0
                    ? endingSection.imageBrightness / 100
                    : -endingSection.imageBrightness / 100,
              }}
            />
          </>
        ) : null}
        <div className="center-component ending-section-component">
          <div className={`question-container ${endingSection?.imageLayout}`}>
            {endingSection &&
            endingSection.image &&
            endingSection.imageLayout !== "background" ? (
              <div className="thank-you-image">
                <img src={endingSection.image} />
              </div>
            ) : null}
            <Box display="flex" flexDirection="column" flex={1}>
              <div
                className="question-title apply-font"
                style={{ color: theme?.questionColor }}
              >
                {endingSection?.title}
              </div>
              {endingSection?.subtitle ? (
                <input
                  style={{ color: theme?.questionColor }}
                  placeholder="Description on this is optional"
                  className="question-subtitle apply-font"
                  value={endingSection?.subtitle}
                />
              ) : null}
            </Box>
          </div>
          {endingSection && endingSection.showButton ? (
            <Box display="flex" justifyContent="center">
              <Button
                onClick={() => {
                  if (endingSection?.buttonLink.length > 0) {
                    window.location.href = endingSection?.buttonLink;
                  }
                }}
                text={endingSection.buttonLabel}
                className="okay-button apply-font"
                style={{
                  backgroundColor: theme?.buttonColor,
                  color: theme?.buttonTextColor,
                }}
              />
            </Box>
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <div className="survey-form-container">
      {showEndingSection ? (
        renderEndingSection()
      ) : surveryData.length > 0 ? (
        <div
          className="survey-form-body question-container"
          style={{ backgroundColor: theme?.backgroundColor }}
        >
          {theme.backgroundImage ? (
            <>
              {" "}
              <div
                className="form-bg-image"
                style={{ backgroundImage: `url(${theme.backgroundImage})` }}
              />
              <div
                className="form-bg-image bg-color"
                style={{
                  backgroundColor:
                    theme.backgroundImageBrightness > 0 ? "#fff" : "#000",
                  opacity:
                    theme.backgroundImageBrightness > 0
                      ? theme.backgroundImageBrightness / 100
                      : -theme.backgroundImageBrightness / 100,
                }}
              />
            </>
          ) : null}

          <TransitionGroup style={{ zIndex: 100 }}>
            <CSSTransition
              classNames={`slide-${scrollDirection}`}
              timeout={500}
              key={`${currentStep}`}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  zIndex: 100,
                }}
              >
                {!isAnonymous && showUsernameInput ? (
                  <>
                    <div
                      className="question-title apply-font"
                      style={{ color: theme?.questionColor }}
                    >
                      What is your name?
                    </div>
                    <ShortText
                      color={theme.answerColor}
                      onChange={(e) => setUsername(e.target.value)}
                      value={username}
                    />
                    <Button
                      style={{
                        backgroundColor: theme?.buttonColor,
                        color: theme?.buttonTextColor,
                      }}
                      text="SUBMIT"
                      onClick={() => {
                        if (username.length > 0) {
                          setShowUsernameInput(false);
                        }
                      }}
                      className="okay-button apply-font"
                    />
                  </>
                ) : (
                  <>
                    <div
                      className="question-title apply-font"
                      style={{ color: theme?.questionColor }}
                    >
                      <div className="question-number">
                        {surveryData[currentStep].id}
                      </div>
                      {surveryData[currentStep].title.replace(
                        "{{{name}}}",
                        isAnonymous ? "" : username
                      )}
                    </div>
                    <div
                      className="question-subtitle apply-font"
                      style={{ color: theme?.questionColor }}
                    >
                      {surveryData[currentStep].subtitle}
                    </div>
                    {renderComponent(surveryData[currentStep].type)}
                    {surveryData.length - 1 === currentStep ? (
                      <Button
                        style={{
                          backgroundColor: theme?.buttonColor,
                          color: theme?.buttonTextColor,
                        }}
                        text="SUBMIT"
                        onClick={submitForm}
                        className="okay-button apply-font"
                      />
                    ) : (
                      <Button
                        style={{
                          backgroundColor: theme?.buttonColor,
                          color: theme?.buttonTextColor,
                          opacity: getButtonDisableState() ? 0.8 : 1,
                        }}
                        text="OK"
                        disabled={getButtonDisableState()}
                        rightIcon={
                          <Icon icon="tick" color={theme.buttonTextColor} />
                        }
                        onClick={gotoNextStep}
                        className="okay-button apply-font"
                      />
                    )}
                  </>
                )}
              </div>
            </CSSTransition>
          </TransitionGroup>
          <div className="next-prev-buttons">
            <Button
              style={{
                backgroundColor: theme?.buttonColor,
                color: theme?.buttonTextColor,
              }}
              disabled={currentStep === 0}
              onClick={() => {
                setScrollDirection("down");
                setTimeout(() => {
                  setCurrentStep(currentStep - 1);
                }, 0);
              }}
              className="next-button"
              icon={<Icon icon="chevron-up" iconSize={30} color="#fff" />}
              minimal
            />
            <Button
              style={{
                backgroundColor: theme?.buttonColor,
                color: theme?.buttonTextColor,
              }}
              disabled={currentStep === surveryData.length - 1}
              onClick={() => {
                setScrollDirection("up");
                setTimeout(() => {
                  setCurrentStep(currentStep + 1);
                }, 0);
              }}
              className="prev-button"
              icon={<Icon icon="chevron-down" iconSize={30} color="#fff" />}
              minimal
            />
          </div>
        </div>
      ) : null}
      <div style={{ position: "absolute", opacity: 0 }}>
        <FontPicker
          apiKey="AIzaSyCAzTWQNgRzwkudY7lCPw8TjzfjPvBpSlk"
          activeFontFamily={theme ? theme.systemFont : "Lato"}
        />
        {/*@ts-ignore*/}
        <Toaster ref={toastRef} />
      </div>
    </div>
  );
};
export default RenderForm;
