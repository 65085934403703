import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLaunchpad } from "views/LaunchPad/LaunchpadProvider";

import TangoImage from "../../assets/manager/tango.png";
import Box from "../../components/Box";
import "./style.css";

interface Props {
  children: any;
}

const Layout = ({ children }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const launchpad = useLaunchpad();
  return (
    <Box className="business-admin-module">
      <Box
        className="layout_header admin-more"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Link to="/dashboard">
          <img src={TangoImage} alt="tango" />
        </Link>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Link to="/core-reports" className="menu-item">
            <Box
              className={`layout_menu-item ${
                location.pathname === "/core-reports" ||
                location.pathname === "/daily-labor-report" ||
                location.pathname === "/time-card-summary" ||
                location.pathname === "/weekly-employee-salary-summary" ||
                location.pathname === "/distribution-and-labor" ||
                location.pathname === "/weekly-sales-summary" ||
                location.pathname === "/bank-deposit" ||
                location.pathname === "/activity-report" ||
                location.pathname === "/sales-mix-report" ||
                location.pathname === "/sales-mix-report-csv" ||
                location.pathname === "/daily-sales-report" ||
                location.pathname === "/tip-by-menu"
                  ? "active"
                  : ""
              }`}
              style={{ marginLeft: "35px" }}
            >
              Reporting
            </Box>
          </Link>
          <Link to="/business-info" className="menu-item">
            <Box
              className={`layout_menu-item ${
                location.pathname === "/business-info" ? "active" : ""
              }`}
              style={{ marginLeft: "35px" }}
            >
              Business Info
            </Box>
          </Link>
          <Link to="/settings" className="menu-item">
            <Box
              className={`layout_menu-item ${
                location.pathname === "/settings" ? "active" : ""
              }`}
              style={{ marginLeft: "35px" }}
            >
              Settings
            </Box>
          </Link>
          <img
            src={require("../../assets/headers/admin-more-launch.svg").default}
            className="launch-icon"
            onClick={() => launchpad.setVisibility(true)}
          />
          <img
            src={
              require("../../assets/headers/admin-more-logo-mask.svg").default
            }
            className="launch-icon-mask"
          />
          <img
            src={require("../../assets/tango-icon.svg").default}
            className="tango-logo"
          />
        </Box>
        {/* <Box>{user?.contact?.firstName} {user?.contact?.lastName}</Box> */}
      </Box>
      {children}
    </Box>
  );
};
export default Layout;
