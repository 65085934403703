import _ from "lodash";

export const isEqual = (item: any, anotherItem: any) => {
  if (typeof item === "object") {
    if (Array.isArray(item) && Array.isArray(anotherItem)) {
      return _.isEqual(_.sortBy(item), _.sortBy(anotherItem));
    }
    return _.isEqual(item, anotherItem);
  }
  return item === anotherItem;
};

export function getObjectDiff(obj1: any, obj2: any) {
  const diff = Object.keys(obj1).reduce((result, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj2.hasOwnProperty(key) && obj1[key] !== undefined) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
}

// This will remove all the items as opposed to just the first one
export function removeItemFromArray<T>(arr: Array<T>, value: T): Array<T> {
  let i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}
