//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import "quill-mention";
import React, { useMemo, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useTangoRTE } from "../hooks/useTangoRTE";
import "../style.css";
import {
  AlignCenter,
  AlignLeft,
  AlignRight,
  AtIcon,
  AttachmentIcon,
  BoldIcon,
  EmojiIcon,
  ItalicIcon,
  OrderedListIcon,
  StrikeThruIcon,
  UnderlineIcon,
  UnorderedListIcon,
} from "./RTEIcons";

const CustomHeart = () => <span>♥</span>;

/*
 * Custom toolbar component including the custom heart button and dropdowns
 */
const CustomToolbar = () => (
  <div id="toolbar">
    <motion.button
      whileTap={{ scale: 0.9, opacity: 0.9 }}
      title="Bold"
      type="button"
      className="ql-bold tango-rte-control"
    >
      <BoldIcon />
    </motion.button>
    <motion.button
      whileTap={{ scale: 0.9, opacity: 0.9 }}
      className="ql-italic tango-rte-control"
    >
      <ItalicIcon />
    </motion.button>
    <motion.button
      whileTap={{ scale: 0.9, opacity: 0.9 }}
      className="ql-underline tango-rte-control"
    >
      <UnderlineIcon />
    </motion.button>
    <motion.button
      whileTap={{ scale: 0.9, opacity: 0.9 }}
      className="ql-strike tango-rte-control"
    >
      <StrikeThruIcon />
    </motion.button>
    <motion.button
      whileTap={{ scale: 0.9, opacity: 0.9 }}
      className="ql-list tango-rte-control with-stroke"
      value="bullet"
    >
      <UnorderedListIcon />
    </motion.button>
    <motion.button
      whileTap={{ scale: 0.9, opacity: 0.9 }}
      className="ql-list tango-rte-control with-stroke"
      value="ordered"
    >
      <OrderedListIcon />
    </motion.button>
    <motion.button
      whileTap={{ scale: 0.9, opacity: 0.9 }}
      className="ql-align tango-rte-control with-stroke"
      value=""
    >
      <AlignLeft />
    </motion.button>
    <motion.button
      whileTap={{ scale: 0.9, opacity: 0.9 }}
      className="ql-align tango-rte-control with-stroke"
      value="center"
    >
      <AlignCenter />
    </motion.button>
    <motion.button
      whileTap={{ scale: 0.9, opacity: 0.9 }}
      className="ql-align tango-rte-control with-stroke"
      value="right"
    >
      <AlignRight />
    </motion.button>

    {/* <motion.button
      whileTap={{ scale: 0.9, opacity: 0.9 }}
      className="ql-attachment tango-rte-control"
    >
      <AttachmentIcon />
    </motion.button>

    <motion.button
      whileTap={{ scale: 0.9, opacity: 0.9 }}
      className="ql-emojii tango-rte-control"
    >
      <EmojiIcon />
    </motion.button>

    <motion.button
      whileTap={{ scale: 0.9, opacity: 0.9 }}
      className="ql-at tango-rte-control"
    >
      <AtIcon />
    </motion.button>

    <motion.button
      whileTap={{ scale: 0.9, opacity: 0.9 }}
      className="ql-insertHeart"
    >
      <CustomHeart />
    </motion.button> */}
  </div>
);

const icons = Quill.import("ui/icons");
icons.align = {};
icons.bold = undefined;
icons.italic = undefined;
icons.list = {};
icons.strike = undefined;
icons.underline = undefined;

Quill.register(icons, true);

interface EditorProps {
  placeholder: string;
  editorHtml: string;
  setEditorHtml: React.Dispatch<React.SetStateAction<string>>;
  modules: any | undefined;
  formats: string[] | undefined;
}

interface ReadOnlyRTEProps {
  editorHtml: string;
  modules: any | undefined;
  formats: string[] | undefined;
}

const InternalCommsRTEditor = (props: EditorProps) => {
  const { editorHtml, setEditorHtml, modules, formats, placeholder } = props;

  return (
    <div className="text-editor" style={{ width: "100%" }}>
      <ReactQuill
        value={editorHtml}
        onChange={setEditorHtml}
        //@ts-ignore
        placeholder={placeholder}
        modules={modules}
        formats={formats}
      />
      <CustomToolbar />
    </div>
  );
};

export const ReadOnlyRTE = (props: ReadOnlyRTEProps) => {
  const { editorHtml, modules, formats } = props;
  return (
    <ReactQuill
      className="ql-read-only"
      style={{
        backgroundColor: "transparent",
        padding: 0,
        borderRadius: 0,
        margin: 0,
        width: "100%",
      }}
      value={editorHtml}
      modules={modules}
      formats={formats}
      readOnly
    />
  );
};

export default InternalCommsRTEditor;
