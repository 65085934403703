import AgoraRTC, {
  CameraVideoTrackInitConfig,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  ILocalAudioTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
  MicrophoneAudioTrackInitConfig,
  ScreenVideoTrackInitConfig,
} from "agora-rtc-sdk-ng";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { initiateHuddle, joinHuddle, leaveHuddle } from "controllers/chat";
import { baseUrl } from "controllers/core";

import { RootState } from "model/store";

export const rtcApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/rtc`,
});

export const APP_ID = "7794178f424049a7b0277a8f848d4384";

export default function useAgora(client: IAgoraRTCClient | undefined): {
  localAudioTrack: ILocalAudioTrack | undefined;
  localVideoTrack: ILocalVideoTrack | undefined;
  localScreenSharingTrack: ILocalVideoTrack | undefined;

  joinState: boolean;
  leave: (businessId: string, channelId: string) => Promise<void>;
  join: Function;
  remoteUsers: IAgoraRTCRemoteUser[];
  initiateAudioCall: (
    appid: string,
    channel: string,
    token?: string | undefined
  ) => Promise<void>;
  generateToken: (
    channelName: string,
    isPublisher?: any
  ) => Promise<string | null>;
  disableLocalVideoTrack: () => void;
  enableLocalAudioTrack: () => void;
  disableLocalAudioTrack: () => void;
  enableLocalVideoTrack: () => void;
  enableLocalScreenSharing: () => void;
  disableLocalScreenSharing: () => void;
  startHuddle: (
    appid: string,
    selectedChannel: TangoChannel,
    businessId: string
  ) => Promise<void>;
} {
  const [localVideoTrack, setLocalVideoTrack] = useState<
    ILocalVideoTrack | undefined
  >(undefined);
  const [localScreenSharingTrack, setLocalScreenSharingTrack] = useState<
    ILocalVideoTrack | undefined
  >(undefined);
  const [localAudioTrack, setLocalAudioTrack] = useState<
    ILocalAudioTrack | undefined
  >(undefined);

  const user: StaffMember = useSelector((state: RootState) => state.user);

  const [joinState, setJoinState] = useState(false);

  console.log("joinState", joinState);

  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);

  async function createLocalTracks(
    audioConfig?: MicrophoneAudioTrackInitConfig,
    videoConfig?: CameraVideoTrackInitConfig
  ): Promise<[IMicrophoneAudioTrack, ICameraVideoTrack]> {
    const [microphoneTrack, cameraTrack] =
      await AgoraRTC.createMicrophoneAndCameraTracks(audioConfig, videoConfig);
    setLocalAudioTrack(microphoneTrack);
    setLocalVideoTrack(cameraTrack);
    return [microphoneTrack, cameraTrack];
  }

  async function createLocalAudioTrack(
    audioConfig?: MicrophoneAudioTrackInitConfig
  ): Promise<IMicrophoneAudioTrack> {
    const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack(
      audioConfig
    );
    setLocalAudioTrack(microphoneTrack);
    return microphoneTrack;
  }

  async function createLocalVideoTrack(
    videoConfig?: CameraVideoTrackInitConfig
  ): Promise<ICameraVideoTrack> {
    const videoTrack = await AgoraRTC.createCameraVideoTrack(videoConfig);
    setLocalVideoTrack(videoTrack);
    return videoTrack;
  }

  async function createScreenSharingTrack(
    screenSharingConfig: ScreenVideoTrackInitConfig
  ) {
    const screenTrack = await AgoraRTC.createScreenVideoTrack(
      screenSharingConfig,
      "disable" // also share audio?
    );
    // setLocalScreenSharingTrack(screenTrack);
    return screenTrack;
  }

  const initiateAudioCall = async (
    appid: string,
    channel: string,
    token?: string
  ) => {
    if (!client) return;
    const microphoneTrack = await createLocalAudioTrack();

    await client.join(appid, channel, token || null, user.id);
    await client.publish(microphoneTrack);

    (window as any).client = client;

    setJoinState(true);
  };

  const startHuddle = useCallback(
    async (
      appid: string,
      selectedChannel: TangoChannel,
      businessId: string
    ) => {
      if (user) {
        console.log("Starting huddle");
        if (!client) return;
        if (selectedChannel.currentHuddle) {
          await joinHuddle(businessId, selectedChannel.id, user.id);
          const channelName = selectedChannel.id;

          const channelToken = await generateToken(channelName);

          const microphoneTrack = await createLocalAudioTrack();
          console.log("microphoneTrack", microphoneTrack);

          await client.join(appid, channelName, channelToken || null, user.id);
          await client.publish(microphoneTrack);

          console.log("After having joined huddle");
          (window as any).client = client;

          setJoinState(true);
        } else {
          await initiateHuddle(
            businessId,
            selectedChannel.id,
            [user.id],
            user.id
          );

          const channelName = selectedChannel.id;

          const channelToken = await generateToken(channelName);

          const microphoneTrack = await createLocalAudioTrack();

          await client.join(appid, channelName, channelToken || null, user.id);
          await client.publish(microphoneTrack);

          console.log("After having started huddle");
          (window as any).client = client;

          setJoinState(true);
        }
      }
    },
    [user]
  );

  const disableLocalVideoTrack = useCallback(() => {
    if (client && joinState && localVideoTrack && localVideoTrack?.isPlaying) {
      localVideoTrack?.close();
      client.unpublish(localVideoTrack);
      setLocalVideoTrack(undefined);
    }
  }, [client, user, localVideoTrack, joinState]);

  const enableLocalVideoTrack = useCallback(async () => {
    if (client && joinState && !localVideoTrack) {
      const videoTrack = await createLocalVideoTrack();
      client.publish(videoTrack);
    }
  }, [client, user, localVideoTrack, joinState]);

  const enableLocalScreenSharing = useCallback(async () => {
    if (client && joinState && !localScreenSharingTrack) {
      const screenTrack = await createScreenSharingTrack({
        // Set the encoder configurations. For details, see the API description.
        encoderConfig: "1080p_1",
        // Set the video transmission optimization mode as prioritizing video quality.
        optimizationMode: "detail",
      });
      client.publish(screenTrack);
      setLocalScreenSharingTrack(screenTrack);

      screenTrack.on("track-ended", () => {
        screenTrack.close();
        client.unpublish(screenTrack);
        setLocalScreenSharingTrack(undefined);
      });
    }
  }, [client, user, localScreenSharingTrack, joinState]);

  const disableLocalScreenSharing = useCallback(() => {
    if (client && joinState && localScreenSharingTrack) {
      localScreenSharingTrack?.close();
      client.unpublish(localScreenSharingTrack);
      setLocalScreenSharingTrack(undefined);
    }
  }, [client, user, localScreenSharingTrack, joinState]);

  const disableLocalAudioTrack = useCallback(async () => {
    if (client && joinState && localAudioTrack && localAudioTrack?.isPlaying) {
      localAudioTrack?.close();
      client.unpublish(localAudioTrack);
      setLocalAudioTrack(undefined);
    }
  }, [client, user, localAudioTrack, joinState]);

  const enableLocalAudioTrack = useCallback(async () => {
    if (client && joinState && !localAudioTrack) {
      const audioTrack = await createLocalAudioTrack();
      client.publish(audioTrack);
    }
  }, [client, user, localAudioTrack, joinState]);

  const generateToken = useCallback(
    async (channelName: string, isPublisher = true) => {
      if (!user) return null;

      const res = await rtcApiClient.get<{ rtcToken: string }>(
        `account-based-token/${channelName}/${
          isPublisher ? "publisher" : "audience"
        }/${user.id}`
      );
      return res?.data?.rtcToken ?? null;
    },
    [user]
  );

  async function join(appid: string, channel: string, token?: string) {
    if (!client) return;
    const [microphoneTrack, cameraTrack] = await createLocalTracks();

    await client.join(appid, channel, token || null, user.id);
    await client.publish([microphoneTrack, cameraTrack]);

    (window as any).client = client;
    (window as any).videoTrack = cameraTrack;

    setJoinState(true);
  }

  const leave = useCallback(
    async (businessId: string, channelId: string) => {
      if (localAudioTrack) {
        localAudioTrack.stop();
        localAudioTrack.close();
      }
      if (localVideoTrack) {
        localVideoTrack.stop();
        localVideoTrack.close();
      }
      setRemoteUsers([]);
      setJoinState(false);
      await leaveHuddle(businessId, channelId, user.id);
      await client?.leave();
    },
    [localAudioTrack, localVideoTrack, client, user]
  );

  useEffect(() => {
    if (!client) return;
    setRemoteUsers(client.remoteUsers);

    const handleUserPublished = async (
      user: IAgoraRTCRemoteUser,
      mediaType: "audio" | "video"
    ) => {
      await client.subscribe(user, mediaType);
      // toggle rerender while state of remoteUsers changed.
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };
    const handleUserUnpublished = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };
    const handleUserJoined = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };
    const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };
    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("user-joined", handleUserJoined);
    client.on("user-left", handleUserLeft);

    return () => {
      client.off("user-published", handleUserPublished);
      client.off("user-unpublished", handleUserUnpublished);
      client.off("user-joined", handleUserJoined);
      client.off("user-left", handleUserLeft);
    };
  }, [client]);

  return {
    localAudioTrack,
    localVideoTrack,
    localScreenSharingTrack,
    joinState,
    leave,
    join,
    remoteUsers,
    initiateAudioCall,
    generateToken,
    disableLocalVideoTrack,
    enableLocalAudioTrack,
    disableLocalAudioTrack,
    enableLocalVideoTrack,
    enableLocalScreenSharing,
    disableLocalScreenSharing,
    startHuddle,
  };
}
