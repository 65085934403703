import React from "react";

interface Props {
  value: Array<string>[] | null;
  selectedAnswers: string[];
  updateAnswer: (index: number, value: string) => void;
  color: string;
}
const FillInTheBlank = ({
  value,
  selectedAnswers,
  updateAnswer,
  color,
}: Props) => {
  return (
    <div className="fill-in-blank-component">
      {value
        ? value.map((questions: string[], index) => (
            <div
              className="fill-in-blank-question-container"
              key={index}
              style={{ borderColor: color }}
            >
              {questions[0].length ? (
                <div style={{ color }} className="apply-font">
                  {questions[0]}
                </div>
              ) : (
                <input
                  style={{ color, borderColor: color }}
                  onChange={(e) => updateAnswer(index, e.target.value)}
                  placeholder="answer"
                  className="apply-font"
                  value={selectedAnswers[index]}
                />
              )}
              {questions[1].length ? (
                <div style={{ color }} className="apply-font">
                  {questions[1]}
                </div>
              ) : (
                <input
                  style={{ color, borderColor: color }}
                  className="apply-font"
                  placeholder="answer"
                  onChange={(e) => updateAnswer(index, e.target.value)}
                  value={selectedAnswers[index]}
                />
              )}
              {questions[2].length ? (
                <div style={{ color }} className="apply-font">
                  {questions[2]}
                </div>
              ) : !questions[1].length ? (
                ""
              ) : (
                <input
                  className="apply-font"
                  style={{ color, borderColor: color }}
                  placeholder="answer"
                  onChange={(e) => updateAnswer(index, e.target.value)}
                  value={selectedAnswers[index]}
                />
              )}
            </div>
          ))
        : null}
    </div>
  );
};
export default FillInTheBlank;
