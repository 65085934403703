//@ts-ignore
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import React from "react";
import { useState } from "react";

import PrivateCheckMarkIcon from "assets/private-checkmark.svg";

import "./styles.css";

interface CreateChannelPrivacySwitchProps {
  isOn: boolean;
  toggleSwitch: () => void;
}

const CreateChannelPrivacySwitch = ({
  toggleSwitch,
  isOn,
}: CreateChannelPrivacySwitchProps) => {
  return (
    <div className="switch" data-isOn={isOn} onClick={toggleSwitch}>
      <AnimatePresence>
        <motion.img
          className="privacy-check-mark"
          src={PrivateCheckMarkIcon}
          animate={{ opacity: isOn ? 1 : 0 }}
          transition={spring}
        />
      </AnimatePresence>
      <motion.div
        animate={{ backgroundColor: isOn ? "white" : "#e0e5f0" }}
        whileHover={{ scale: 1.1 }}
        className="handle"
        layout
        transition={spring}
      />
    </div>
  );
};

export default CreateChannelPrivacySwitch;

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};
