import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import firebase from "../../../config/firebase";
import { RootState } from "../../../model/store";
import { saveFile } from "../../../utils/storage";
import { ComponentTypeProp } from "../data";
import { ComponentType } from "../types";

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const useQuestions = () => {
  const [questions, setQuestions] = useState<any[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);
  const [uploadingIndex, setUploadingIndex] = useState<number | null>(null);
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const createNewQuestion = (type: ComponentType) => {
    const clonedQuestions = [...questions];
    const newQuestion = {
      id: questions.length + 1,
      type,
      title: "Your text can be edited here",
      subtitle: "",
      values: [],
      required: false,
      images: [],
    };
    clonedQuestions.push(newQuestion);
    setQuestions(clonedQuestions);
    setSelectedQuestion(newQuestion);
  };
  const deleteOption = (index: number) => {
    const clonedSelectedQuestion = { ...selectedQuestion };
    clonedSelectedQuestion["values"].splice(index, 1);
    setSelectedQuestion(clonedSelectedQuestion);
    const updatedValues = [...questions].map((item) => {
      if (item.id === selectedQuestion.id) {
        return { ...clonedSelectedQuestion };
      } else {
        return item;
      }
    });
    setQuestions(updatedValues);
  };
  const deleteMatchingOption = (index: number) => {
    const clonedSelectedQuestion = { ...selectedQuestion };
    clonedSelectedQuestion["values"]["leftArray"].splice(index, 1);
    clonedSelectedQuestion["values"]["rightArray"].splice(index, 1);
    setSelectedQuestion(clonedSelectedQuestion);
    const updatedValues = [...questions].map((item) => {
      if (item.id === selectedQuestion.id) {
        return { ...clonedSelectedQuestion };
      } else {
        return item;
      }
    });
    setQuestions(updatedValues);
  };

  const handleFileChange = async (
    image: string,
    index: number,
    fileName: string
  ) => {
    try {
      setUploadingIndex(index);
      let fileUrl = "";
      if (image && !image.startsWith("https://")) {
        fileUrl = await saveFile(
          `businesses/${business.id}/typeform/` + fileName,
          image
        );
      }
      const clonedSelectedQuestion = { ...selectedQuestion };
      clonedSelectedQuestion.images[index] = fileUrl;
      setSelectedQuestion(clonedSelectedQuestion);
      const updatedValues = [...questions].map((item) => {
        if (item.id === selectedQuestion.id) {
          return { ...clonedSelectedQuestion };
        } else {
          return item;
        }
      });
      setQuestions(updatedValues);
      setUploadingIndex(null);
    } catch (error) {
      // @ts-ignore
      const err: firebase.firestore.FirestoreError = error;
      return { error: err.message };
    }
  };

  const updateDropdownOptions = (options: string[]) => {
    const clonedSelectedQuestion = { ...selectedQuestion };
    const existingQuestionIndex = questions.findIndex(
      (item) => item.id === selectedQuestion.id
    );
    const clonedQuestions = [...questions];
    setSelectedQuestion({
      ...clonedSelectedQuestion,
      values: options,
    });
    clonedQuestions[existingQuestionIndex]["values"] = options;
    setQuestions(clonedQuestions);
  };

  const updateMatchingChoice = (value: string, type: string, index: number) => {
    const clonedSelectedQuestion = { ...selectedQuestion };
    clonedSelectedQuestion["values"][type][index] = value;
    setSelectedQuestion(clonedSelectedQuestion);
    const updatedValues = [...questions].map((item) => {
      if (item.id === selectedQuestion.id) {
        return { ...clonedSelectedQuestion };
      } else {
        return item;
      }
    });
    setQuestions(updatedValues);
  };

  const addMatchingChoice = () => {
    const clonedSelectedQuestion = { ...selectedQuestion };
    if (
      selectedQuestion.values.leftArray &&
      selectedQuestion.values.rightArray
    ) {
      clonedSelectedQuestion.values.leftArray.push("");
      clonedSelectedQuestion.values.rightArray.push("");
      const updatedValues = [...questions].map((item) => {
        if (item.id === selectedQuestion.id) {
          return { ...clonedSelectedQuestion };
        } else {
          return item;
        }
      });
      setSelectedQuestion(clonedSelectedQuestion);
      setQuestions(updatedValues);
    } else {
      const newChoice = { leftArray: [""], rightArray: [""] };
      const updatedValues = [...questions].map((item) => {
        if (item.id === selectedQuestion.id) {
          return { ...item, values: newChoice };
        } else {
          return item;
        }
      });
      setQuestions(updatedValues);
      setSelectedQuestion({
        ...selectedQuestion,
        values: newChoice,
      });
    }
  };

  const addFillInTheBlankChoice = () => {
    const newChoice = ["", "", ""];
    const updatedValues = [...questions].map((item) => {
      if (item.id === selectedQuestion.id) {
        return { ...item, values: [...item.values, newChoice] };
      } else {
        return item;
      }
    });
    setQuestions(updatedValues);
    setSelectedQuestion({
      ...selectedQuestion,
      values: [...selectedQuestion.values, newChoice],
    });
  };

  const updateFillInTheBlankChoice = (
    value: any,
    optionIndex: number,
    choiceIndex: number
  ) => {
    const clonedSelectedQuestion = { ...selectedQuestion };
    const existingQuestionIndex = questions.findIndex(
      (item) => item.id === selectedQuestion.id
    );
    const clonedQuestions = [...questions];
    const clonedChoicesArray = [
      ...clonedSelectedQuestion["values"][optionIndex],
    ].map((item, index) => {
      if (index === choiceIndex) {
        return value;
      } else {
        return item;
      }
    });
    clonedSelectedQuestion["values"][optionIndex] = clonedChoicesArray;
    clonedQuestions[existingQuestionIndex] = { ...clonedSelectedQuestion };
    setSelectedQuestion(clonedSelectedQuestion);
    setQuestions(clonedQuestions);
  };

  const updateMultipleChoice = (value: string, index: number) => {
    const clonedSelectedQuestion = { ...selectedQuestion };
    const existingQuestionIndex = questions.findIndex(
      (item) => item.id === selectedQuestion.id
    );

    const clonedQuestions = [...questions];
    const updatedChoices = clonedQuestions[existingQuestionIndex]["values"].map(
      (item: any, choiceIndex: number) => {
        if (choiceIndex === index) {
          item = value;
          return item;
        }
        return item;
      }
    );
    clonedQuestions[existingQuestionIndex]["values"] = updatedChoices;
    clonedSelectedQuestion["values"] = updatedChoices;
    setSelectedQuestion(clonedSelectedQuestion);
    setQuestions(clonedQuestions);
  };

  const addMultipleChoiceOption = () => {
    const updatedValues = [...questions].map((item) => {
      if (item.id === selectedQuestion.id) {
        return { ...item, values: [...item.values, ""] };
      } else {
        return item;
      }
    });
    setQuestions(updatedValues);
    setSelectedQuestion({
      ...selectedQuestion,
      values: [...selectedQuestion.values, ""],
    });
  };

  const updateQuestion = (type: string, value: string) => {
    if (type === "title") {
      const updatedQuestions = [...questions].map((question) => {
        if (question.id === selectedQuestion.id) {
          return { ...question, title: value };
        } else {
          return { ...question };
        }
      });
      setQuestions(updatedQuestions);
      setSelectedQuestion({ ...selectedQuestion, title: value });
    }
    if (type === "subtitle") {
      const updatedQuestions = [...questions].map((question) => {
        if (question.id === selectedQuestion.id) {
          return { ...question, subtitle: value };
        } else {
          return { ...question };
        }
      });
      setQuestions(updatedQuestions);
      setSelectedQuestion({ ...selectedQuestion, subtitle: value });
    }
    if (type === "required") {
      const updatedQuestions = [...questions].map((question) => {
        if (question.id === selectedQuestion.id) {
          return { ...question, required: !selectedQuestion.required };
        } else {
          return { ...question };
        }
      });
      setQuestions(updatedQuestions);
      setSelectedQuestion({
        ...selectedQuestion,
        required: !selectedQuestion.required,
      });
    }
  };

  const updateComponentType = (type: ComponentTypeProp) => {
    setSelectedQuestion({
      ...selectedQuestion,
      type: type.slug,
      values: [],
    });
    const updatedQuestions = [...questions].map((question, index) => {
      if (question.id === selectedQuestion.id) {
        return { ...question, type: type.slug, values: [] };
      }
      return { ...question };
    });
    setQuestions(updatedQuestions);
  };

  useEffect(() => {
    if (questions.length === 1) {
      setSelectedQuestion(questions[0]);
    }
  }, [questions]);
  return {
    questions,
    selectedQuestion,
    createNewQuestion,
    deleteOption,
    deleteMatchingOption,
    handleFileChange,
    uploadingIndex,
    updateDropdownOptions,
    updateMatchingChoice,
    addMatchingChoice,
    addFillInTheBlankChoice,
    updateFillInTheBlankChoice,
    updateMultipleChoice,
    addMultipleChoiceOption,
    updateQuestion,
    updateComponentType,
    setQuestions,
    setSelectedQuestion,
  };
};

export default useQuestions;
