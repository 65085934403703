import { combineReducers } from "redux";

import account from "model/reducers/account";
import announcements from "model/reducers/announcements";
import availabilities from "model/reducers/availabilities";
import business from "model/reducers/business";
import businessSettings from "model/reducers/businessSettings";
import businesses from "model/reducers/businesses";
import cashDrawers from "model/reducers/cashDrawers";
import cashEvents from "model/reducers/cashEvents";
import channels from "model/reducers/channels";
import discounts from "model/reducers/discounts";
import docs from "model/reducers/docs";
import draftSchedules from "model/reducers/draftSchedules";
import enterpriseDocs from "model/reducers/enterpriseDocs";
import fellowStaffMembers from "model/reducers/fellowStaffMembers";
import fixedAvailabilities from "model/reducers/fixedAvailabilities";
import fixedSchedules from "model/reducers/fixedSchedules";
import jobFunctions from "model/reducers/jobFunctions";
import locations from "model/reducers/locations";
import payouts from "model/reducers/manualPayouts";
import menuCategories from "model/reducers/menuCategories";
import menus from "model/reducers/menus";
import messages from "model/reducers/messages";
import modifiers from "model/reducers/modifiers";
import modifierOptions from "model/reducers/modifiersOptions";
import orderDisplays from "model/reducers/orderDisplays";
import orders from "model/reducers/orders";
import payoutLedgers from "model/reducers/payoutLedgers";
import printers from "model/reducers/printers";
import productTypes from "model/reducers/productTypes";
import products from "model/reducers/products";
import scheduleEvents from "model/reducers/scheduleEvents";
import schedules from "model/reducers/schedules";
import serviceAreas from "model/reducers/serviceAreas";
import staffMembers from "model/reducers/staffMembers";
import staffing from "model/reducers/staffing";
import tables from "model/reducers/tables";
import tabs from "model/reducers/tabs";
import user from "model/reducers/user";
import workEvents from "model/reducers/workEvents";

import emulatorMode from "./emulatorMode";

export default combineReducers({
  account,
  business,
  businesses,
  staffMembers,
  user,
  workEvents,
  fellowStaffMembers,
  channels,
  messages,
  announcements,
  availabilities,
  jobFunctions,
  businessSettings,
  schedules,
  products,
  productTypes,
  docs,
  enterpriseDocs,
  modifiers,
  modifierOptions,
  menuCategories,
  menus,
  scheduleEvents,
  orderDisplays,
  serviceAreas,
  tables,
  cashDrawers,
  orders,
  tabs,
  discounts,
  fixedAvailabilities,
  fixedSchedules,
  draftSchedules,
  cashEvents,
  staffing,
  payouts,
  payoutLedgers,
  printers,
  locations,
  emulatorMode,
});
