import _ from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "model/store";

function insertHeart() {
  //@ts-ignore
  const cursorPosition = this.quill.getSelection().index;
  //@ts-ignore
  this.quill.insertText(cursorPosition, "♥");
  //@ts-ignore
  this.quill.setSelection(cursorPosition + 1);
}

export const useTangoRTE = () => {
  const fellowStaffMembers = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const [editorHtml, setEditorHtml] = useState("");

  const atValues = useMemo(() => {
    return fellowStaffMembers.map((sm) => {
      return {
        id: sm.id,
        value: `${sm.contact.firstName} ${sm.contact.lastName}`,
      };
    });
  }, [fellowStaffMembers]);
  const hashValues = [
    { id: 3, value: "Fredrik Sundqvist 2" },
    { id: 4, value: "Patrik Sjölin 2" },
  ];

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: "#toolbar",
        handlers: {
          insertHeart: insertHeart,
        },
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionContainerClass: "mention-list",
        listItemClass: "mention-list-item",
        mentionDenotationChars: ["@", "#"],
        renderItem: (item: any, searchTerm: any) => {
          return `<p className="mention-list-item">${item.value}</p>`;
        },
        source: function (searchTerm: any, renderList: any, mentionChar: any) {
          let values;

          if (mentionChar === "@") {
            values = atValues;
          } else {
            values = hashValues;
          }

          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              )
                matches.push(values[i]);
            renderList(matches, searchTerm);
          }
        },
      },
    };
  }, []);

  const formats = useMemo(() => {
    return [
      "align",
      "bold",
      "list",
      "strike",
      "underline",
      "italic",
      "mention",
    ];
  }, []);

  return {
    formats,
    modules,
    hashValues,
    atValues,
    setEditorHtml,
    editorHtml,
  };
};
