import { Icon, InputGroup } from "@blueprintjs/core";
import { width } from "@mui/system";
import firebase from "config/firebase";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "components/Box";

import { RootState } from "model/store";

import Layout from "../MarketingFeedback/Layout";
//tables
import SubscribedAudience from "./Tables/SubscribedAudience";
import UnsubscribedAudience from "./Tables/UnsubscribedAudience";
//icons
import { AddSubsciber } from "./icons/AddSubsciber";
import { All } from "./icons/All";
import { Completed } from "./icons/Completed";
import { Drafts } from "./icons/Drafts";
import { ImportContacts } from "./icons/ImportContacts";
import { Ongoing } from "./icons/Ongoing";
import { Subscribed } from "./icons/Subscribed";
import { Unsubscribed } from "./icons/Unsubscribed";
import "./styles.css";

const Audiences = () => {
  const db = firebase.firestore();
  const navigate = useNavigate();

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const [audienceList, setAudienceList]: any = useState([]);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus]: any = useState("All");
  const [sidebarList, setSidebarList] = useState([
    {
      name: "All",
      icon: All,
      index: 1,
    },
    {
      name: "Subscribed",
      icon: Subscribed,
      index: 2,
    },
    {
      name: "Unsubscribed",
      icon: Unsubscribed,
      index: 3,
    },
  ]);
  const [tableData, setTableData] = useState([
    {
      name: "Brandon Herron",
      emailAddress: "herronbp@gmail.com",
      totalOPens: 1,
      phoneno: "513-617-9942",
      date: new Date(),
      selected: false,
    },
    {
      name: "Brandon Herron",
      emailAddress: "herronbp@gmail.com",
      totalOPens: 1,
      phoneno: "513-617-9942",
      date: new Date(),
      selected: false,
    },
    {
      name: "Brandon Herron",
      emailAddress: "herronbp@gmail.com",
      totalOPens: 1,
      phoneno: "513-617-9942",
      date: new Date(),
      selected: false,
    },
    {
      name: "Brandon Herron",
      emailAddress: "herronbp@gmail.com",
      totalOPens: 1,
      phoneno: "513-617-9942",
      date: new Date(),
      selected: false,
    },

    {
      name: "Brandon Herron",
      emailAddress: "herronbp@gmail.com",
      totalOPens: 1,
      phoneno: "513-617-9942",
      date: new Date(),
      selected: false,
    },
    {
      name: "Brandon Herron",
      emailAddress: "herronbp@gmail.com",
      totalOPens: 1,
      phoneno: "513-617-9942",
      date: new Date(),
      selected: false,
    },
    {
      name: "Brandon Herron",
      emailAddress: "herronbp@gmail.com",
      totalOPens: 1,
      phoneno: "513-617-9942",
      date: new Date(),
      selected: false,
    },

    {
      name: "Brandon Herron",
      emailAddress: "herronbp@gmail.com",
      totalOPens: 1,
      phoneno: "513-617-9942",
      date: new Date(),
      selected: false,
    },
  ]);
  const [filterAudience, setFilterAudience]: any = useState([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const onMasterCheck = (e: any, key: number) => {
    let list = audienceList.map((item: any) => item);
    list[key]["selected"] = e.target.checked;
    setTableData(list);
  };

  const filterSubscribedData = (data: any) => {
    return data.filter((item: any) => item.status == "subscribed");
  };

  const filterUnSubscribedData = (data: any) => {
    return data.filter((item: any) => item.status == "unsubscribed");
  };

  const transformDataForGrid = (data: any) =>
    data.map((item: any) => {
      const [firstName, lastName] = item.name.split(" ");
      return {
        ...item,
        date: item?.createdAt
          ? moment(item.createdAt.toDate()).format("DD/MM/yyyy")
          : "",
        selected: false,
        profileName:
          firstName?.charAt(0).toUpperCase() +
          "" +
          lastName?.charAt(0)?.toUpperCase(),
      };
    });

  const getAudienceList = async (businessId: string) => {
    try {
      const data = await db
        .collection("Audiences")
        .where("businessId", "==", businessId)
        .get();

      let list: any = [];

      data.docs.forEach((item: any) => {
        list = [...list, item.data()];
      });
      console.log("list", list);
      setAudienceList(transformDataForGrid(list));
    } catch (err) {
      console.log(err);
    }
  };

  const renderAllAudience = () => {
    return (
      <>
        <Box display="flex" flexDirection="column" className="body-head-text">
          <span className="body-head-title">All Audience</span>
          <span className="body-head-subtitle">
            Showing all {audienceList.length} of your contacts
          </span>
        </Box>
        <Box className="search-input">
          <InputGroup
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            leftIcon="search"
            intent="none"
          />
        </Box>
        <Box className="table-container">
          <table>
            {/*All Data*/}
            {!isSearching &&
              audienceList.length > 0 &&
              audienceList.map((item: any, key: number) => {
                return (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/edit-subscriber/${item.id}`)}
                  >
                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: "20px",
                        }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={item.selected}
                          id="mastercheck"
                          onChange={(e) => onMasterCheck(e, key)}
                        />
                        <div className="user-avatar-name">
                          {item.profileName}
                        </div>
                        <span
                          className="email"
                          style={{ marginLeft: "20.5px" }}
                        >
                          {item.name}
                        </span>
                      </div>
                    </td>
                    <td>{item.emailAddress}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{item.date}</td>
                    <td>
                      <div className="subscribe-button">Subscribed</div>
                    </td>
                  </tr>
                );
              })}

            {/*filtered Data*/}
            {filterAudience.length > 0 &&
              isSearching &&
              filterAudience.map((item: any, key: number) => {
                return (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/edit-subscriber/${item.id}`)}
                  >
                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: "20px",
                        }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={item.selected}
                          id="mastercheck"
                          onChange={(e) => onMasterCheck(e, key)}
                        />
                        <div className="user-avatar-name">
                          {item.profileName}
                        </div>
                        <span
                          className="email"
                          style={{ marginLeft: "20.5px" }}
                        >
                          {item.name}
                        </span>
                      </div>
                    </td>
                    <td>{item.emailAddress}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{item.date}</td>
                    <td>
                      <div className="subscribe-button">Subscribed</div>
                    </td>
                  </tr>
                );
              })}
          </table>
        </Box>
      </>
    );
  };

  useEffect(() => {
    let AllData = audienceList.map((item: any) => item);

    if (searchText.length > 2) {
      var data = AllData.filter((item: any) => item.name.includes(searchText));
      setFilterAudience(data);
      setIsSearching(true);
    } else {
      setFilterAudience([]);
      setIsSearching(false);
    }
  }, [searchText]);

  useEffect(() => {
    if (business) {
      business.id && getAudienceList(business.id);
    }
  }, [business]);

  useEffect(() => {
    console.log("audienceList", audienceList);
  }, [audienceList]);

  return (
    <Layout>
      <div id="audience-page">
        <Box className="page-head">
          <div>Your Audience</div>
          <div>
            Your audience has{" "}
            <span className="bold">{audienceList.length}</span> contacts.{" "}
            <span className="bold">
              {audienceList.length > 0
                ? audienceList.filter(
                    (audience: any) => audience.status === "subscribed"
                  ).length
                : 0}
            </span>{" "}
            of these contacts are subscribed
          </div>
        </Box>

        <Box display="flex" flexDirection="row">
          <Box className="sidebar">
            <div className="section">
              <div className="sidebar-head">View by Status</div>
              <div className="statuslistdiv">
                <ul className="statuslist">
                  {sidebarList.map((item: any, key: number) => {
                    const Iconcomponent = item.icon;
                    return (
                      <li onClick={() => setStatus(item.name)}>
                        <Iconcomponent
                          height={16}
                          width={16}
                          color={status === item.name ? "#424452" : "#7C7F93"}
                        />
                        <span
                          className={
                            status === item.name
                              ? "status-list-active-text"
                              : "status-list-text"
                          }
                          style={{}}
                        >
                          {item.name}
                        </span>

                        {status === item.name && (
                          <div className="active-element"></div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="section">
              <div className="sidebar-head">Actions</div>
              <div className="sidebar-actions">
                <div
                  className="action-item"
                  onClick={() => navigate("/add-subscriber")}
                >
                  <AddSubsciber height={16} width={16} color={"#7C7F93"} />
                  <span className="action-item-name">Add subscriber</span>
                </div>
                <div className="action-item">
                  <ImportContacts height={16} width={16} color={"#7C7F93"} />
                  <span className="action-item-name">Import Contacts</span>
                </div>
              </div>
            </div>
          </Box>

          <Box className="body" style={{ width: "100%" }}>
            <Box className="body-container">
              {status === "All" && renderAllAudience()}
              {status === "Subscribed" && (
                <SubscribedAudience
                  subscribedData={
                    audienceList && filterSubscribedData(audienceList)
                  }
                />
              )}
              {status === "Unsubscribed" && (
                <UnsubscribedAudience
                  unsubscribedData={
                    audienceList && filterUnSubscribedData(audienceList)
                  }
                />
              )}
            </Box>
          </Box>
        </Box>
      </div>
    </Layout>
  );
};

export default Audiences;
