import * as Sentry from "@sentry/react";
import React, {
  createContext,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Audiences from "views/Audiences";
import AddSubscrber from "views/Audiences/AddSubscriber";
import EditSubscriber from "views/Audiences/EditSubscriber";
import AudienceProfile from "views/Audiences/profile";
import ActivityReport from "views/BusinessAdmin/Reporting/ActivityReport";
import CloseOutReportModal from "views/BusinessAdmin/Reporting/CloseOutReport";
import CoreReports from "views/BusinessAdmin/Reporting/CoreReports";
import DistributionAndLaborReport from "views/BusinessAdmin/Reporting/DistributionAndLaborReport";
import TimeCardSummary from "views/BusinessAdmin/Reporting/TimeCardSummary";
import TipsByMenu from "views/BusinessAdmin/Reporting/TipsByMenu";
import WeeklyEmployeeSalarySummary from "views/BusinessAdmin/Reporting/WeeklyEmployeeSalarySummary";
import WeeklySalesSummary from "views/BusinessAdmin/Reporting/WeeklySalesSummary";
import Campaigns from "views/Campaigns";
import CreativeStudio from "views/Campaigns/CreativeStudio";
import CreateCampaign from "views/Campaigns/createCampaign";
import CampaignDashboard from "views/Campaigns/dashboard";
import EditCampaign from "views/Campaigns/editCampaign";
import Templates from "views/Campaigns/templates";
import Chat from "views/Comms/Chat";
import ErrorPage from "views/ErrorPage";
import MobileFriednlyHowToVideos from "views/HelpSupport/HowToVideos/MobileFriendlyHowToVideos";
import { LaunchpadContext, Overlay } from "views/LaunchPad/LaunchpadProvider";
import SurveyBuilder from "views/SurveyBuilder";
import ViewApplications from "views/manager/Hiring/ViewApplications";
import ViewArchives from "views/manager/Hiring/ViewArchives";
import ApplicationTracker from "views/manager/Team/ApplicationTracker";
import CreateJobPosting from "views/manager/Team/CreateJobPosting";
import EditJobPosting from "views/manager/Team/EditJobPosting";

import {
  composeUsableTangoAccount,
  composeUsableTangoBusiness,
} from "controllers/composableTypes";
import { getCloseOutReportForADateRange } from "controllers/reporting";

import Box from "components/Box";
import PrivateRouteWrapper from "components/Navigation/PrivateRoute";
import PublicRouteWrapper from "components/Navigation/PublicRoute";
import Spinner from "components/Spinner";

import { receiveAccount } from "model/actions/accountsAC";
import { changeEmulatorMode } from "model/actions/emulatorModeAC";
import store, { RootState } from "model/store";

import "./App.css";
import firebase from "./config/firebase";
import { subscribeToCollectionDocument } from "./controllers/core";
import {
  initializeData,
  initializeEnterpriseData,
  setUserSubscription,
  switchCurrentLocation,
} from "./controllers/init";
import { clearListeners, clearUserListener } from "./controllers/listeners";
import { receiveBusiness } from "./model/actions/businessAC";
import { logoutUser } from "./model/actions/userAC";
import EnterPriseDashboard from "./views/Enterprise/Dashboard";
import StoreEmulator from "./views/Enterprise/StoreEmulator";
import ReportBug from "./views/HelpSupport/ReportBug";
import RenderForm from "./views/SurveyBuilder/renderForm";
import ViewForms from "./views/ViewForms";

const Manager = lazy(() => import("./views/manager/Manager"));
const Staffing = lazy(() => import("./views/manager/Staffing"));
const DailyLogs = lazy(() => import("./views/manager/dailyLogs"));
const GiftCards = lazy(() => import("views/MarketingFeedback/GiftCards"));
const HelpSupport = lazy(() => import("views/HelpSupport"));
const PayPeriodLaborReport = lazy(
  () => import("views/BusinessAdmin/Reporting/PayPeriodLaborReport")
);

const Profile = lazy(() => import("views/manager/Team/Profile"));
const DailyLaborReport = lazy(
  () => import("views/BusinessAdmin/Reporting/DailyLaborReport")
);
const DailySalesReport = lazy(
  () => import("views/BusinessAdmin/Reporting/DailySalesReport")
);
const LaborPayroll = lazy(
  () => import("views/BusinessAdmin/Reporting/LaborPayroll")
);
const SalesMixReport = lazy(
  () => import("views/BusinessAdmin/Reporting/SalesMix")
);
const SalesMixReportCSV = lazy(
  () => import("views/BusinessAdmin/Reporting/SalesMixCSV")
);
const Discounts = lazy(() => import("./views/menuBuilder/Discounts"));
const Modifiers = lazy(() => import("./views/menuBuilder/Modifiers"));
const Products = lazy(() => import("./views/menuBuilder/Products"));
const Menus = lazy(() => import("./views/menuBuilder/AllMenus"));
// const ManagerOnBoardingScreen = lazy(() => import("views/HelpSupport"))
const ManagerVerificationCodeScreen = lazy(
  () => import("./views/manager/EnterVerificationCode")
);
const ManagerSignInScreen = lazy(() => import("./views/manager/SignIn"));
const Reporting = lazy(() => import("./views/BusinessAdmin/Reporting"));
const Employees = lazy(() => import("./views/manager/Employees"));
const DashBoard = lazy(() => import("./views/Dashboard"));
const Team = lazy(() => import("./views/manager/Team"));
const NotFound = lazy(() => import("./views/notFound/NotFound"));
const CashSummary = lazy(
  () => import("views/BusinessAdmin/Reporting/CashSummary")
);

const ProductAnalysis = lazy(
  () => import("views/BusinessAdmin/Reporting/ProductAnalysis")
);
const TableManagement = lazy(
  () => import("views/StoresAndOps/TableManagement")
);
const BankDeposit = lazy(
  () => import("views/BusinessAdmin/Reporting/BankDeposit")
);
const DeviceSetup = lazy(() => import("views/StoresAndOps/DeviceSetup"));
const SelectBusinessType = lazy(
  () => import("views/manager/SelectBusinessType")
);
const Settings = lazy(() => import("views/BusinessAdmin/Settings"));
// const LaunchPad = lazy(() => import("views/LaunchPad"))
const GetStarted = lazy(() => import("views/GetStarted"));
const BusinessInfo = lazy(() => import("views/BusinessAdmin/BusinessInfo"));
const SubAppsIntro = lazy(() => import("views/SubAppsIntro"));
const AddEmployee = lazy(() => import("views/manager/Team/AddEmployee"));
const EditEmployee = lazy(() => import("views/manager/Team/EditEmployee"));
const Comms = lazy(() => import("views/Comms"));

export const SwitchLocationContext = createContext({
  switchLocation: (uid: string, locationId: string) => {},
});

export const useSwitchLocation = () => useContext(SwitchLocationContext);

function App() {
  const [loading, setLoading] = useState(true); // default to loading
  const [business, setBusiness] = useState<TangoBusiness | null>(null);
  const [currentUser, setCurrentUser] = useState<StaffMember | null>(null);
  const [launchpadVisible, setLaunchpadVisibility] = useState(false);
  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);
  const currentBusiness: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const navigate = useNavigate();

  const updateLaunchpadVisibility = (val: boolean) => {
    setLaunchpadVisibility(val);
  };

  const switchLocation = useCallback(
    async (uid: string, locationId: string) => {
      await switchCurrentLocation(uid, locationId);
      subscribeToCollectionDocument<TangoBusiness>(
        "Businesses",
        locationId,
        receiveBusiness,
        "business",
        composeUsableTangoBusiness,
        undefined,
        setBusiness
      );
    },
    []
  );

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        clearListeners();
        clearUserListener();
        store.dispatch(logoutUser());
        localStorage.removeItem("locationId");
        setLoading(false);
      } else {
        const tokenResult = await user.getIdTokenResult();
        setLoading(false);
        console.log("User: ", user);
        const result = await setUserSubscription(
          user,
          tokenResult.claims.admin
        );

        if (!result.data) {
          clearListeners();
          clearUserListener();
          localStorage.removeItem("locationId");
          store.dispatch(logoutUser());
        } else if (result?.data?.isAdmin) {
          const user: StaffMember = result.data;

          if (user.accountId) {
            subscribeToCollectionDocument<TangoAccount>(
              "Accounts",
              result.data.accountId,
              receiveAccount,
              "account",
              composeUsableTangoAccount,
              undefined,
              () => {}
            );
          }

          if (user.corporateAccess) {
            navigate("/enterprise");
            initializeEnterpriseData(result.data);
            setCurrentUser(result.data);
          } else {
            subscribeToCollectionDocument<TangoBusiness>(
              "Businesses",
              result.data.businessId,
              receiveBusiness,
              "business",
              composeUsableTangoBusiness,
              undefined,
              setBusiness
            );
            initializeData(result.data);
          }
        } else {
          localStorage.removeItem("locationId");
          clearListeners();
          clearUserListener();
          store.dispatch(logoutUser());
          setLoading(false);
          setBusiness(null);
        }
      }
    });
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <div className={`App ${emulatorMode ? "has-emulator" : ""}`}>
        {loading ? (
          <Spinner />
        ) : (
          <SwitchLocationContext.Provider
            value={{
              switchLocation,
            }}
          >
            <LaunchpadContext.Provider
              value={{
                visible: launchpadVisible,
                setVisibility: updateLaunchpadVisibility,
              }}
            >
              {emulatorMode ? (
                <div className="emulator-top-bar">
                  <div
                    className="exit"
                    onClick={async () => {
                      const user = { ...currentUser };
                      user.businessId = business?.id || "";
                      clearListeners();
                      clearUserListener();
                      store.dispatch(logoutUser());
                      if (user) {
                        // @ts-ignore
                        await initializeEnterpriseData(user);
                      }
                      store.dispatch(changeEmulatorMode(false));
                      navigate("/store-emulator");
                    }}
                  >
                    <img
                      src={require("assets/back-arrow.svg").default}
                      alt="previous icon"
                      className="activity-arrow-back"
                    />
                    Exit
                  </div>
                  <div className="outer-name">
                    <div className="business-name">
                      {currentBusiness?.businessName || "Emulator mode"}
                    </div>
                  </div>
                </div>
              ) : null}
              <Overlay />

              <Chat>
                <Routes>
                  <Route
                    path="manager/*"
                    element={
                      <PrivateRouteWrapper business={business} path="manager/*">
                        <Manager />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="help/*"
                    element={
                      <PrivateRouteWrapper business={business} path="help">
                        <HelpSupport />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="report-a-bug"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="report-a-bug"
                      >
                        <ReportBug />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="table-management"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="table-management"
                      >
                        <TableManagement />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="enterprise"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="enterprise"
                      >
                        <EnterPriseDashboard />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="store-emulator"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="store-emulator"
                      >
                        <StoreEmulator />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="device-setup"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="device-setup"
                      >
                        <DeviceSetup />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="comms/*"
                    element={
                      <PrivateRouteWrapper business={business} path="comms">
                        <Comms />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="dashboard"
                    element={
                      <PrivateRouteWrapper business={business} path="dashboard">
                        <DashBoard />
                      </PrivateRouteWrapper>
                    }
                  />
                  {/* <Route
    path="launchpad"
    element={
      <PrivateRouteWrapper business={business} path="launchpad">
        <LaunchPad />
      </PrivateRouteWrapper>
    }
  />  */}
                  <Route
                    path="menus"
                    element={
                      <PrivateRouteWrapper business={business} path="menus">
                        <Menus />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="products"
                    element={
                      <PrivateRouteWrapper business={business} path="products">
                        <Products />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="modifiers"
                    element={
                      <PrivateRouteWrapper business={business} path="modifiers">
                        <Modifiers />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="discounts"
                    element={
                      <PrivateRouteWrapper business={business} path="discounts">
                        <Discounts />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="services-intro"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="services-intro"
                      >
                        <SubAppsIntro />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="reporting"
                    element={
                      <PrivateRouteWrapper business={business} path="reporting">
                        <Reporting />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="bank-deposit"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="bank-deposit"
                      >
                        <BankDeposit />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="product-analytics"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="product-analytics"
                      >
                        <ProductAnalysis />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="cash-summary"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="cash-summary"
                      >
                        <CashSummary />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="business-info"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="business-info"
                      >
                        <BusinessInfo />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="activity-report"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="activity-report"
                      >
                        <ActivityReport />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="labor-payroll"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="labor-payroll"
                      >
                        <LaborPayroll />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="core-reports"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="core-reports"
                      >
                        <CoreReports />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="summary-report"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="summary-report"
                      >
                        <CloseOutReportModal />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="daily-labor-report"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="daily-labor-report"
                      >
                        <DailyLaborReport />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="time-card-summary"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="time-card-summary"
                      >
                        <TimeCardSummary />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="weekly-employee-salary-summary"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="weekly-employee-salary-summary"
                      >
                        <WeeklyEmployeeSalarySummary />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="distribution-and-labor"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="distribution-and-labor"
                      >
                        <DistributionAndLaborReport />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="weekly-sales-summary"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="weekly-sales-summary"
                      >
                        <WeeklySalesSummary />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="pay-period-labor-report"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="pay-period-labor-report"
                      >
                        <PayPeriodLaborReport />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="sales-mix-report-csv"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="sales-mix-report-csv"
                      >
                        <SalesMixReportCSV />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="tip-by-menu"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="tip-by-menu"
                      >
                        <TipsByMenu />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="daily-sales-report"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="daily-sales-report"
                      >
                        <DailySalesReport />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="sales-mix-report"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="sales-mix-report"
                      >
                        <SalesMixReport />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="settings"
                    element={
                      <PrivateRouteWrapper business={business} path="settings">
                        <Settings />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="select-business"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="select-business"
                      >
                        <SelectBusinessType />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="gift-cards"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="gift-cards"
                      >
                        <GiftCards />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="application-tracker"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="application-tracker"
                      >
                        <ApplicationTracker />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="view-profile/:staffMemberId"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="view-profile"
                      >
                        <Profile />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="view-archives"
                    element={
                      <PrivateRouteWrapper
                        business={business}
                        path="view-archives"
                      >
                        <ViewArchives />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="view-applications"
                    element={
                      <PrivateRouteWrapper
                        path="view-applications"
                        business={business}
                      >
                        <ViewApplications />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="survey-builder"
                    element={
                      <PrivateRouteWrapper
                        path="survey-builder"
                        business={business}
                      >
                        <SurveyBuilder />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="survey-builder"
                    element={
                      <PrivateRouteWrapper
                        path="survey-builder"
                        business={business}
                      >
                        <SurveyBuilder />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="view-forms"
                    element={
                      <PrivateRouteWrapper
                        path="view-forms"
                        business={business}
                      >
                        <ViewForms />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="/form/:id/:businessId"
                    element={
                      <PublicRouteWrapper restricted={false}>
                        <RenderForm />
                      </PublicRouteWrapper>
                    }
                  />

                  <Route
                    path="create-job-post"
                    element={
                      <PrivateRouteWrapper
                        path="create-job-post"
                        business={business}
                      >
                        <CreateJobPosting />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="edit-job-post"
                    element={
                      <PrivateRouteWrapper
                        path="create-job-post"
                        business={business}
                      >
                        <EditJobPosting />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="templates"
                    element={
                      <PrivateRouteWrapper path="templates" business={business}>
                        <Templates />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="signIn/*"
                    element={
                      <PublicRouteWrapper restricted>
                        <ManagerSignInScreen />
                      </PublicRouteWrapper>
                    }
                  />
                  <Route
                    path="mobile-friendly-tutorials"
                    element={
                      <PublicRouteWrapper restricted={false}>
                        <MobileFriednlyHowToVideos />
                      </PublicRouteWrapper>
                    }
                  />
                  <Route
                    path="get-started"
                    element={
                      <PublicRouteWrapper restricted>
                        <GetStarted />
                      </PublicRouteWrapper>
                    }
                  />
                  <Route
                    path="check-verification"
                    element={
                      <PublicRouteWrapper restricted>
                        <ManagerVerificationCodeScreen />
                      </PublicRouteWrapper>
                    }
                  />

                  <Route
                    path="campaigns"
                    element={
                      <PrivateRouteWrapper path="campaigns" business={business}>
                        <Campaigns />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="audiences"
                    element={
                      <PrivateRouteWrapper path="audiences" business={business}>
                        <Audiences />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="campaign-dashboard"
                    element={
                      <PrivateRouteWrapper
                        path="campaign-dashboard"
                        business={business}
                      >
                        <CampaignDashboard />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="audience-profile"
                    element={
                      <PrivateRouteWrapper
                        path="audience-profile"
                        business={business}
                      >
                        <AudienceProfile />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="add-subscriber"
                    element={
                      <PrivateRouteWrapper
                        path="add-subscriber"
                        business={business}
                      >
                        <AddSubscrber />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="creative-studio/:templateId"
                    element={
                      <PrivateRouteWrapper
                        path="creative-studio"
                        business={business}
                      >
                        <CreativeStudio />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="edit-subscriber/:subscriberId"
                    element={
                      <PrivateRouteWrapper
                        path="creative-studio"
                        business={business}
                      >
                        <EditSubscriber />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="create-campaign"
                    element={
                      <PrivateRouteWrapper
                        path="create-campaign"
                        business={business}
                      >
                        <CreateCampaign />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="edit-campaign/:campaignId"
                    element={
                      <PrivateRouteWrapper
                        path="edit-campaign"
                        business={business}
                      >
                        <EditCampaign />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="add-employee"
                    element={
                      <PrivateRouteWrapper
                        path="add-employee"
                        business={business}
                      >
                        <AddEmployee />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="edit-employee/:staffMemberId"
                    element={
                      <PrivateRouteWrapper
                        path="edit-employee"
                        business={business}
                      >
                        <EditEmployee />
                      </PrivateRouteWrapper>
                    }
                  />

                  <Route
                    path="notFound"
                    element={
                      <React.Suspense fallback={<>Loading...</>}>
                        <NotFound />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/staffing"
                    element={<Navigate replace to="/manager/scheduling" />}
                  />
                  <Route
                    path="/scheduling"
                    element={<Navigate replace to="/manager/scheduling" />}
                  />
                  <Route
                    path="/team"
                    element={<Navigate replace to="/manager/team" />}
                  />
                  <Route
                    path="/"
                    element={<Navigate replace to="/dashboard" />}
                  />
                  <Route
                    path="/*"
                    element={<Navigate replace to="/notFound" />}
                  />
                </Routes>
              </Chat>
            </LaunchpadContext.Provider>
          </SwitchLocationContext.Provider>
        )}
      </div>
    </Sentry.ErrorBoundary>
  );
}

export default App;
