import { Icon } from "@blueprintjs/core";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import Box from "../../../../../components/Box";

interface Props {
  value: { leftArray: string[]; rightArray: string[] } | null | undefined;
  updateChoice: (type: string, choices: string[]) => void;
  color: string;
}
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Matching = ({ value, updateChoice, color }: Props) => {
  return (
    <div className="multiple-choice-container matching-component">
      <Box display="flex" className="parts-container">
        <div className="left-part">
          {value ? (
            <DragDropContext
              onDragEnd={(result) => {
                if (!result.destination) {
                  return;
                }
                const items = reorder(
                  value.leftArray,
                  result.source.index,
                  result.destination.index
                );
                updateChoice("leftArray", items);
              }}
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {value.leftArray.map((item, index) => (
                      <Draggable key={item} draggableId={item} index={index}>
                        {(provided, snapshot) => (
                          <div
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              className={`single-choice-container active apply-font`}
                              style={{ color, borderColor: color }}
                            >
                              <div
                                className="key-bind"
                                style={{ color, borderColor: color }}
                              >
                                {index + 1}
                              </div>
                              <input
                                style={{ color }}
                                className="choice-name"
                                value={item}
                                disabled
                                placeholder="Type Choice"
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : null}
        </div>
        <div className="right-part">
          {value ? (
            <DragDropContext
              onDragEnd={(result) => {
                if (!result.destination) {
                  return;
                }
                const items = reorder(
                  value.rightArray,
                  result.source.index,
                  result.destination.index
                );
                updateChoice("rightArray", items);
              }}
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {value.rightArray.map((item, index) => (
                      <Draggable key={item} draggableId={item} index={index}>
                        {(provided, snapshot) => (
                          <div
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              className={`single-choice-container active apply-font`}
                              style={{ color, borderColor: color }}
                            >
                              <div
                                className="key-bind"
                                style={{ color, borderColor: color }}
                              >
                                {index + 1}
                              </div>
                              <input
                                disabled
                                className="choice-name"
                                value={item}
                                placeholder="Type Choice"
                                style={{ color, borderColor: color }}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : null}
        </div>
      </Box>
    </div>
  );
};
export default Matching;
