import { Grid } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import Layout from "../../Layout";
import LaborReportModal from "../LaborReportModal";
import "../style.css";

const CoreReports = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <Layout>
      <div id="cash-summary" className="daily-labor-report">
        <div className="navigation">
          <div className="navigation-title">Report</div>
          <div className="navigation-subtitle">Select to view below</div>
          {/* <Link to="/reporting">
            <div className="button">Sales Summary</div>
          </Link>
          <Link to="/product-analytics">
            <div  className="button">Product Analytics</div>
          </Link> */}
          <Link to="/core-reports">
            <div className="button active">Core Reports</div>
          </Link>
          <Link to="/bank-deposit">
            <div className="button">Bank Deposit Report</div>
          </Link>

          {/*
          <div  className="button">Cash Summary</div>
          <Link to="/labor-payroll">
            <div  className="button active">Labor/Payroll</div>
          </Link>
          <div className="button">Inventory  Reports</div>
          <div className="button" >Customer  Reports</div>
          <div className="button">Transaction  Reports</div>
          */}
        </div>
        <div className="right-section" id="labor-payroll">
          <div className="body">
            <div className="page-header">
              <div className="page-title">Core Reports</div>
            </div>
            <div className="report-boxes">
              <Grid container spacing={5} columns={18}>
                <Grid item xs={6}>
                  <Link to="/summary-report" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">Summary Reports</div>
                      </div>
                      <div className="report-description">
                        Summary of transactions by day.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/daily-labor-report" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">Daily Labor Reports</div>
                      </div>
                      <div className="report-description">
                        Exportable reports breaking down labor costs by day.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/activity-report" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">Activity Report</div>
                      </div>
                      <div className="report-description">
                        Exportable daily activity reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/distribution-and-labor" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">
                          Distribution And Labor Reports
                        </div>
                      </div>
                      <div className="report-description">
                        Exportable detailed daily labor and distribution
                        reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                {/* <Grid item xs={6}  >
              <Link to="/daily-labor-report" style={{ width: '100%'}}>
                <div className="report-box">
                  <div className="report-box-header">
                    <img src={require('../../../../assets/24-hour.svg').default}/>
                    <div className="report-title">
                      Summary Receipt Report
                    </div>
                  </div>
                  <div className="report-description">
                    Exportable reports breaking down labor costs by day.
                  </div>
                </div>
              </Link>
            </Grid> */}
                <Grid item xs={6}>
                  <Link to="/sales-mix-report" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">Sales Mix Report</div>
                      </div>
                      <div className="report-description">
                        Exportable daily sales mix reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/weekly-sales-summary" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">Weekly Sales Report</div>
                      </div>
                      <div className="report-description">
                        Exportable weekly sales reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/time-card-summary" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">
                          Time Card Summary Report
                        </div>
                      </div>
                      <div className="report-description">
                        Exportable time card summary reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link
                    to="/weekly-employee-salary-summary"
                    style={{ width: "100%" }}
                  >
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">
                          Weekly Employee Salary Summary Report
                        </div>
                      </div>
                      <div className="report-description">
                        Exportable weekly salary reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/sales-mix-report-csv" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">Sales Mix Report CSV</div>
                      </div>
                      <div className="report-description">
                        Exportable daily sales mix reports in CSV format.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/daily-sales-report" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">Daily Sales Report</div>
                      </div>
                      <div className="report-description">
                        Exportable daily sales reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/tip-by-menu" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">Tip by menu</div>
                      </div>
                      <div className="report-description">
                        Exportable daily total tips earned by menu.
                      </div>
                    </div>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <LaborReportModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
      />
    </Layout>
  );
};
export default CoreReports;
