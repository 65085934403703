import {
  IAgoraRTCRemoteUser,
  ILocalAudioTrack,
  ILocalVideoTrack,
} from "agora-rtc-sdk-ng";
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import _ from "lodash";
import React, { useMemo } from "react";
import styled from "styled-components";

import { getPositionById } from "model/selectors/businessSettings";

import AudioCallIcon from "assets/audio-call-icon.svg";
import SearchIcon from "assets/chat-search-icon.svg";
import SendMessageIcon from "assets/send-message.svg";
import VideoCallIcon from "assets/video-call-icon.svg";

import InternalCommsRTEditor, {
  ReadOnlyRTE,
} from "../components/InternamCommsRTEditor";
import {
  StaffDirectMessageAvatar,
  StaffDirectMessageNoImage,
} from "../components/Sidebar";
import { TransformedMessage } from "../hooks/useChat";
import { useTangoRTE } from "../hooks/useTangoRTE";
import "../style.css";
import CreateChannel from "./CreateChannel";

interface ContentProps {
  selectedChannelId: string | null;
  selectedStaffMember: StaffMember | null;
  business: TangoBusiness;
  businessSettings: TangoBusinessSettings;
  initiateVideoCall: () => void;
  initiateAudioCall: () => void;
  remoteUsers: IAgoraRTCRemoteUser[];
  localVideoTrack: ILocalVideoTrack | undefined;
  localAudioTrack: ILocalAudioTrack | undefined;
  createChannelMode: boolean;
  fellowStaffMembers: StaffMember[];
  selectedChannel: TangoChannel | null;
  messages: TransformedMessage[];
  editorHtml: string;
  setEditorHtml: React.Dispatch<React.SetStateAction<string>>;
  modules: any | undefined;
  formats: string[] | undefined;
  sendEnabled: boolean;
  onSend: () => void;
  messagesEndRef: React.MutableRefObject<null>;
  finishCreatingChannelFlow: () => void;
}

const ContentHeaderWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 2vw;
`;

const HeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const HeaderTitle = styled.div`
  color: #424452;
  font-family: Lato;
  font-weight: bold;
  font-size: 1.7vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1vw;
`;

const HeaderSubtitle = styled.div`
  color: #7c7f93;
  font-family: Lato;
  font-weight: bold;
  font-size: 1.7vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderSearchWrapper = styled.div`
  background-color: #f5f7fb;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1vh 1vw;
`;

const HeaderSearchInput = styled.input`
  background-color: transparent;
  border: 0;
  font-family: Lato;
  color: #7c7f93;
  font-size: 1.5vh;
  min-width: 20vw;
  margin-left: 0.4vw;
`;

const ContentWrapper = styled.div`
  flex: 7;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ContentFooter = styled.div`
  flex: 1;
  max-height: 50vh;
  background-color: #eff2f8;
  display: flex;
  flex-direction: row;
`;

const CallButtonsWrapper = styled.div`
  flex: 1;
  max-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const MessageControlsWrapper = styled.div`
  flex: 6;
  padding-left: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5vh;
`;

const MessageFooterControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const MessageFooterControlIcon = styled(motion.img)`
  margin-left: 1vw;
  margin-top: 1vh;
`;

const CallButton = styled(motion.div)`
  display: flex;
  border-radius: 1vh;
  margin: 0.4vh;
  font-size: 2vh;
  padding: 1vh 2vw;
  height: 5vh;
  font-family: Lato;
  font-weight: bold;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #424452;
  background-color: #ffffff;
`;

const CallIcon = styled.img`
  margin-left: 0.6vw;
  height: 1.9vh;
`;

const SendMessageButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1vh;
`;

const SendMessageButton = styled(motion.div)`
  height: 5vh;
  width: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #424452;
  margin-bottom: 1vh;
`;
const SendMessageButtonImage = styled.img`
  height: 1.8vh;
`;

const mock_messages = _.range(10).map(() => ({
  name: "Brandon Harron",
  text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lobortis libero. imperdiet rhoncus non at elit. Phasellus finibus tellus et commodo convallis. Morbi in porta est. Etiam imperdiet dui in magna vehicula, ut consequat felis interdum`,
  abbrName: "BH",
  bgColor: _.sample(["#EAF9F3", "#F0E8FA"]) ?? "#F0E8FA",
}));

const MessageContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2vh 2vw;

  &:hover {
    background-color: #f5f7fb;
  }
`;

const MessageAvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MessageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MessageTitle = styled.div`
  width: 100%;
  font-family: Lato-Semibold;
  color: #424452;
  font-weight: bold;
  font-size: 1.7vh;
`;
const MessageText = styled.div`
  width: 100%;
  margin-top: 0.5vh;
  font-size: 1.4vh;
  font-family: Lato;
  font-weight: 400;
  color: #7c7f93;
`;

const ContentContainer = styled.div`
  flex: 4;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #d8d8d8;
`;

interface MessageProps {
  name: string;
  text: string;
  abbrName: string;
  imageUrl?: string;
  bgColor: string;
  html?: string;
  modules?: any;
  formats?: string[];
}

const Message = (props: MessageProps) => {
  if (props.html && props.formats && props.modules) {
    return (
      <MessageContainer>
        <MessageAvatarContainer>
          {props.imageUrl ? (
            <StaffDirectMessageAvatar src={props.imageUrl} />
          ) : (
            <StaffDirectMessageNoImage bgColor={props.bgColor}>
              {props.abbrName}
            </StaffDirectMessageNoImage>
          )}
        </MessageAvatarContainer>
        <MessageContentContainer>
          <MessageTitle>{props.name}</MessageTitle>
          <MessageText>{props.text}</MessageText>
          <ReadOnlyRTE
            editorHtml={props.html}
            formats={props.formats}
            modules={props.modules}
          />
        </MessageContentContainer>
      </MessageContainer>
    );
  }
  return (
    <MessageContainer>
      <MessageAvatarContainer>
        {props.imageUrl ? (
          <StaffDirectMessageAvatar src={props.imageUrl} />
        ) : (
          <StaffDirectMessageNoImage bgColor={props.bgColor}>
            {props.abbrName}
          </StaffDirectMessageNoImage>
        )}
      </MessageAvatarContainer>
      <MessageContentContainer>
        <MessageTitle>{props.name}</MessageTitle>
        <MessageText>{props.text}</MessageText>
      </MessageContentContainer>
    </MessageContainer>
  );
};

type StaffMessageGroupedValue = {
  message: TransformedMessage;
  staffMember: StaffMember;
};

interface MessagesProps {
  staffMessageGroupedValues: StaffMessageGroupedValue[];
  modules: any | undefined;
  formats: string[] | undefined;
  messagesEndRef: React.MutableRefObject<null>;
}

const Messages = ({
  staffMessageGroupedValues,
  modules,
  formats,
  messagesEndRef,
}: MessagesProps) => {
  return (
    <>
      {staffMessageGroupedValues.map((staffMessageGroupedValue) => {
        const fullName = `${staffMessageGroupedValue.staffMember.contact.firstName} ${staffMessageGroupedValue.staffMember.contact.lastName}`;
        const abbrName = `${staffMessageGroupedValue.staffMember.contact.firstName?.[0]}${staffMessageGroupedValue.staffMember.contact.lastName?.[0]}`;
        const imageUrl =
          staffMessageGroupedValue.staffMember.imageUrl ?? undefined;
        if (staffMessageGroupedValue.message.html && modules && formats) {
          return (
            <Message
              imageUrl={imageUrl}
              bgColor={_.sample(["#EAF9F3", "#F0E8FA"]) ?? "#F0E8FA"}
              name={fullName}
              text={staffMessageGroupedValue.message.text}
              html={staffMessageGroupedValue.message.html}
              formats={formats}
              modules={modules}
              abbrName={abbrName}
            />
          );
        }
        return (
          <Message
            imageUrl={imageUrl}
            bgColor={_.sample(["#EAF9F3", "#F0E8FA"]) ?? "#F0E8FA"}
            name={fullName}
            text={staffMessageGroupedValue.message.text}
            abbrName={abbrName}
          />
        );
      })}
      <div ref={messagesEndRef} />
    </>
  );
};

export const Content = ({
  createChannelMode = false,
  selectedChannelId,
  selectedStaffMember,
  business,
  businessSettings,
  initiateVideoCall,
  initiateAudioCall,
  selectedChannel,
  fellowStaffMembers,
  messages,
  editorHtml,
  setEditorHtml,
  modules,
  formats,
  sendEnabled,
  onSend,
  finishCreatingChannelFlow,

  messagesEndRef,
}: ContentProps) => {
  const staffMemberFullName = useMemo(() => {
    if (!selectedStaffMember) return null;
    return `${selectedStaffMember.contact.firstName} ${selectedStaffMember.contact.lastName}`;
  }, [selectedStaffMember]);

  const staffPrimaryRole = useMemo(() => {
    if (!selectedStaffMember) return null;
    if (!selectedStaffMember.primaryRole) return null;
    return (
      getPositionById(businessSettings, selectedStaffMember.primaryRole)
        ?.title ?? null
    );
  }, [businessSettings, selectedStaffMember]);

  const staffMessageGroupedValues = useMemo(() => {
    return messages
      .map((message) => {
        const staffMemberForAMessage = fellowStaffMembers.find(
          (sm) => sm.id === message.user.id
        );
        if (staffMemberForAMessage) {
          return {
            message,
            staffMember: staffMemberForAMessage,
          };
        }
      })
      .filter((x) => !!x) as StaffMessageGroupedValue[];
  }, [messages, fellowStaffMembers]);

  if (createChannelMode) {
    return (
      <CreateChannel finishCreatingChannelFlow={finishCreatingChannelFlow} />
    );
  }

  if (selectedChannel) {
    return (
      <ContentContainer style={{ height: "93vh" }}>
        <>
          <ContentHeaderWrapper>
            <HeaderTitleWrapper>
              <HeaderTitle>{staffMemberFullName}</HeaderTitle>
              <HeaderSubtitle>
                #{selectedChannel.name ?? staffPrimaryRole} |{" "}
                {business?.location?.address}
              </HeaderSubtitle>
            </HeaderTitleWrapper>
            <HeaderSearchWrapper>
              <img style={{ height: "2vh" }} src={SearchIcon} />
              <HeaderSearchInput placeholder="Search this chat" />
            </HeaderSearchWrapper>
          </ContentHeaderWrapper>
          <ContentWrapper>
            <Messages
              messagesEndRef={messagesEndRef}
              modules={modules}
              formats={formats}
              staffMessageGroupedValues={staffMessageGroupedValues}
            />
          </ContentWrapper>
          <ContentFooter>
            <CallButtonsWrapper>
              <CallButton
                onClick={initiateAudioCall}
                whileTap={{ scale: 0.9, opacity: 0.9 }}
              >
                Huddle <CallIcon src={AudioCallIcon} />
              </CallButton>
            </CallButtonsWrapper>
            <MessageControlsWrapper>
              <InternalCommsRTEditor
                editorHtml={editorHtml}
                setEditorHtml={setEditorHtml}
                modules={modules}
                formats={formats}
                placeholder={`Type a message`}
              />
            </MessageControlsWrapper>
            <SendMessageButtonWrapper>
              <SendMessageButton
                disabled={!sendEnabled}
                onClick={onSend}
                whileTap={{ scale: 0.9, opacity: 0.9 }}
              >
                <SendMessageButtonImage src={SendMessageIcon} />
              </SendMessageButton>
            </SendMessageButtonWrapper>
          </ContentFooter>
        </>
      </ContentContainer>
    );
  }

  return <ContentContainer />;
};
