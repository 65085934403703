import _ from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { setUpNewChannel } from "controllers/chat";

import { RootState } from "model/store";

interface UseCreateChannelProps {
  onFinish: () => void;
}
export const useCreateChannel = (props: UseCreateChannelProps) => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const user: StaffMember = useSelector((state: RootState) => state.user);

  const fellowStaffMembers = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const [loading, setLoading] = useState(false);

  const [selectedChannelMembers, setSelectedChannelMembers] = useState<
    { value: string; label: string }[]
  >([]);

  const availableStaffOptions = useMemo(() => {
    return fellowStaffMembers
      .filter(
        (sm) =>
          sm.id !== user.id &&
          !selectedChannelMembers.find(
            (selectedMember) => selectedMember.value === sm.id
          )
      )
      .map((sm) => ({
        value: sm.id,
        label: `${sm.contact.firstName} ${sm.contact.lastName}`,
      }));
  }, [fellowStaffMembers, selectedChannelMembers]);

  const [channelName, setChannelName] = useState("");
  const handleChannelNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChannelName(event.target.value);
    },
    []
  );

  const [channelDescription, setChannelDescription] = useState("");

  const handleChannelDescriptionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChannelDescription(event.target.value);
    },
    []
  );

  const [isPrivate, setPrivate] = useState(false);

  const toggleChannelPrivacy = useCallback(() => {
    setPrivate(!isPrivate);
  }, [isPrivate]);

  const createNewChannel = useCallback(async () => {
    setLoading(true);
    const selectedStaffIds = selectedChannelMembers.map((s) => s.value);
    console.log("selectedStaffIds", selectedStaffIds);
    await setUpNewChannel(
      business.id,
      channelName,
      selectedStaffIds,
      user.id,
      isPrivate,
      "jlVJQEb8s4I4TTrlgBwl",
      channelDescription
    );
    setLoading(false);
  }, [
    business,
    channelName,
    selectedChannelMembers,
    user,
    isPrivate,
    channelDescription,
  ]);

  const handleChannelMembersMultiselectChange = useCallback((inputValue) => {
    setSelectedChannelMembers(inputValue);
  }, []);

  const multiSelectStyles = useMemo(() => {
    return {
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#f5f7fb" : "white",
        padding: 20,
        fontFamily: "Lato",
        color: "#7c7f93",
        fontSize: "1.5vh",
      }),
      container: (provided: any, state: any) => ({
        // none of react-select's styles are passed to <Control />
        ...provided,
        width: "70vw",
      }),
      control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: "transparent",
        border: 0,
        // This line disable the blue border
        boxShadow: "none",
      }),
      indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        visibility: "hidden",
      }),
      multiValueRemove: (provided: any, state: any) => ({
        ...provided,
      }),
      valueContainer: (provided: any, state: any) => ({
        ...provided,
        fontFamily: "Lato",
        color: "#7c7f93",
        fontSize: "1.5vh",
      }),
      noOptionsMessage: (provided: any, state: any) => ({
        ...provided,
        fontFamily: "Lato",
        color: "#7c7f93",
        fontSize: "1.5vh",
      }),
      placeholder: (provided: any, state: any) => ({
        ...provided,
        fontFamily: "Lato",
        color: "#7c7f93",
        fontSize: "1.5vh",
      }),
      dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        visibility: "hidden",
      }),
      multiValue: (provided: any, state: any) => ({
        ...provided,

        backgroundColor: "white",
        padding: "0.5vh 0.7vw 0.5vh 0.7vw",
        borderRadius: 20,
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        border: "0px",
        backgroundColor: "white",
        boxShadow: "6px 6px 40px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        marginTop: "2vh",
      }),
      singleValue: (provided: any, state: any) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      },
    };
  }, []);

  const readyToCreateChannel = useMemo(() => {
    if (selectedChannelMembers.length && channelName) {
      return true;
    }
    return false;
  }, [channelName, selectedChannelMembers.length]);

  return {
    availableStaffOptions,
    multiSelectStyles,
    handleChannelMembersMultiselectChange,
    selectedChannelMembers,
    isPrivate,
    readyToCreateChannel,
    handleChannelNameChange,
    channelName,
    toggleChannelPrivacy,
    handleChannelDescriptionChange,
    channelDescription,
    loading,
    createNewChannel,
  };
};
