import {
  Button,
  Dialog,
  EditableText,
  Icon,
  InputGroup,
  Menu,
  MenuItem,
  Slider,
  Switch,
} from "@blueprintjs/core";
import { ContextMenu2, Popover2 } from "@blueprintjs/popover2";
import { Select } from "@blueprintjs/select";
import FontPicker from "font-picker-react";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { SketchPicker } from "react-color";
import { ReactFlowProvider } from "react-flow-renderer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "components/Box";

import BottomImage from "assets/bottom-image.svg";
import ChartIcon from "assets/chart-icon.svg";
import LeftImage from "assets/left-image.svg";
import RightImage from "assets/right-image.svg";
import ChevronDown from "assets/survery-builder/chevron-down-icon.svg";
import EmailIcon from "assets/survery-builder/email-icon.svg";
import EndStepIcon from "assets/survery-builder/end-step-icon.svg";
import LinkIcon from "assets/survery-builder/link-icon.svg";
import LongTextIcon from "assets/survery-builder/long-text-icon.svg";
import PhoneIcon from "assets/survery-builder/phone-icon.svg";
import PictureChoiceIcon from "assets/survery-builder/picture-choice-icon.svg";
import RatingsIcon from "assets/survery-builder/rating-icon.svg";
import ShortTextIcon from "assets/survery-builder/short-text-icon.svg";
import TickIcon from "assets/survery-builder/tick-icon.svg";
import YesNoIcon from "assets/survery-builder/yes-no-icon.svg";
import TopImage from "assets/top-image.svg";

import { RootState } from "../../model/store";
import { createTypeform } from "../../models/surveyBuilder";
import { saveFile } from "../../utils/storage";
import CorrectOrder from "./components/admin/CorrectOrder";
import DigitalSignature from "./components/admin/DigitalSignature";
import DropDown from "./components/admin/Dropdown";
import Email from "./components/admin/Email";
import FillIntheBlank from "./components/admin/FillIntheBlank";
import LabelPicture from "./components/admin/LabelPicture";
import Legal from "./components/admin/Legal";
import BasicFlow from "./components/admin/Logic";
import Logic from "./components/admin/Logic";
import Matching from "./components/admin/Matching";
import MultipleChoice from "./components/admin/MultipleChoice";
import PhoneInput from "./components/admin/Phone";
import PictureChoice from "./components/admin/PictureChoice";
import Ratings from "./components/admin/Ratings";
import ShortText from "./components/admin/ShortText";
import UploadFile from "./components/admin/UploadFile";
import Url from "./components/admin/Url";
import YesNo from "./components/admin/YesNo";
import { componentTypes, themesData } from "./data";
import useEndingSection from "./hooks/useEndingSection";
import useQuestions from "./hooks/useQuestions";
import useThemes from "./hooks/useThemes";
import "./style.css";
import { ComponentType, EndingSectionProps, ThemeProp } from "./types";

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const SurveyBuilder = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const bgImageInputRef = useRef<HTMLInputElement>(null);
  const endingSectionBgImageRef = useRef<HTMLInputElement>(null);
  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);

  const {
    questions,
    selectedQuestion,
    uploadingIndex,
    createNewQuestion,
    deleteOption,
    deleteMatchingOption,
    handleFileChange,
    updateDropdownOptions,
    updateMatchingChoice,
    addMatchingChoice,
    addFillInTheBlankChoice,
    updateFillInTheBlankChoice,
    updateMultipleChoice,
    addMultipleChoiceOption,
    updateQuestion,
    updateComponentType,
    setQuestions,
    setSelectedQuestion,
  } = useQuestions();
  const {
    themes,
    selectedTheme,
    selectedFile,
    showThemeSettings,
    selectedBrightness,
    uploadThemeImage,
    revertThemeSettings,
    saveNewTheme,
    saveChangesOfTheme,
    addNewThemeData,
    handleEditThemeButton,
    setShowThemeSettings,
    setSelectedTheme,
    setSelectedBrightness,
    setSelectedFile,
  } = useThemes(bgImageInputRef);

  const {
    endingSections,
    selectedEndingSection,
    showEndingSection,
    updateEndingSectionStep,
    uploadEndingSectionImage,
    addNewSection,
    setSelectedEndingSection,
    setShowEndingSection,
  } = useEndingSection(endingSectionBgImageRef);
  const [selectedTab, setSelectedTab] = useState<
    "question" | "design" | "logic"
  >("question");
  const [selectedSegment, setSelectedSegment] = useState<
    "my-themes" | "gallery"
  >("my-themes");

  // const [activeTab]
  const navigate = useNavigate();
  const [surveyInfo, setSurveyInfo] = useState<{
    typeformDisplayName: string;
    typeformDescription: string;
  }>({
    typeformDisplayName: "Survey Name",
    typeformDescription: "Give your survey a description..",
  });

  const [formData, setFormData] = useState<{ name: string; type: string }>({
    name: "",
    type: "",
  });
  const [showCreateFormModal, setShowCreateFormModa] = useState<boolean>(false);

  useEffect(() => {
    if (formData.type.length === 0 && formData.name.length === 0) {
      setShowCreateFormModa(true);
    }
  }, []);

  const getIcon = useCallback((componentType: ComponentType) => {
    switch (componentType) {
      case ComponentType.shortText:
        return <img src={ShortTextIcon} />;
      case ComponentType.longText:
        return <img src={LongTextIcon} />;
      case ComponentType.email:
        return <img src={EmailIcon} />;
      case ComponentType.phone:
        return <img src={PhoneIcon} />;
      case ComponentType.url:
        return <img src={LinkIcon} />;
      case ComponentType.multipleChoice:
        return <img src={TickIcon} />;
      case ComponentType.pictureChoice:
        return <img src={PictureChoiceIcon} />;
      case ComponentType.yesNo:
        return <img src={YesNoIcon} />;
      case ComponentType.dropdown:
        return <img src={ChevronDown} />;
      case ComponentType.ratings:
        return <img src={RatingsIcon} />;
      case ComponentType.uploadFile:
        return <img src={RatingsIcon} />;
      case ComponentType.legal:
        return <img src={RatingsIcon} />;
      case ComponentType.correctOrder:
        return <img src={LongTextIcon} />;
      case ComponentType.digitalSignature:
        return <img src={RatingsIcon} />;
      case ComponentType.labelPicture:
        return <img src={RatingsIcon} />;
      case ComponentType.fillInBlank:
        return <img src={RatingsIcon} />;
      default:
        return <img src={ShortTextIcon} />;
    }
  }, []);
  const getTypeLabelFromSlug = useMemo(() => {
    if (selectedQuestion) {
      if (selectedQuestion.type === ComponentType.shortText) {
        return "Short Text";
      }
      if (selectedQuestion.type === ComponentType.longText) {
        return "Long Text";
      }
      if (selectedQuestion.type === ComponentType.email) {
        return "Email";
      }
      if (selectedQuestion.type === ComponentType.phone) {
        return "Phone";
      }
      if (selectedQuestion.type === ComponentType.url) {
        return "URL";
      }
      if (selectedQuestion.type === ComponentType.multipleChoice) {
        return "Multiple Choice";
      }
      if (selectedQuestion.type === ComponentType.pictureChoice) {
        return "Picture Choice";
      }
      if (selectedQuestion.type === ComponentType.yesNo) {
        return "Yes/No";
      }
      if (selectedQuestion.type === ComponentType.dropdown) {
        return "Dropdown";
      }
      if (selectedQuestion.type === ComponentType.ratings) {
        return "Ratings";
      }
      if (selectedQuestion.type === ComponentType.uploadFile) {
        return "Upload File";
      }
      if (selectedQuestion.type === ComponentType.legal) {
        return "Legal Accept/Decline";
      }
      if (selectedQuestion.type === ComponentType.correctOrder) {
        return "Correct Order";
      }
      if (selectedQuestion.type === ComponentType.digitalSignature) {
        return "Digital Signature";
      }
      if (selectedQuestion.type === ComponentType.labelPicture) {
        return "Label Picture";
      }
      if (selectedQuestion.type === ComponentType.fillInBlank) {
        return "Fill In The Blank";
      }
      if (selectedQuestion.type === ComponentType.matching) {
        return "Matching";
      }
    }
    return "";
  }, [selectedQuestion]);

  const getComponent = (componentType: ComponentType) => {
    switch (componentType) {
      case ComponentType.shortText:
        return (
          <ShortText
            onChange={() => console.log("yey")}
            color={selectedTheme.answerColor}
          />
        );
      case ComponentType.longText:
        return (
          <ShortText
            onChange={() => console.log("yey")}
            color={selectedTheme.answerColor}
          />
        );
      case ComponentType.email:
        return (
          <Email
            onChange={() => console.log("yey")}
            color={selectedTheme.answerColor}
          />
        );
      case ComponentType.phone:
        return (
          <PhoneInput
            phoneNumber={""}
            onChange={() => console.log("yey")}
            color={selectedTheme.answerColor}
          />
        );
      case ComponentType.url:
        return (
          <Url
            onChange={() => console.log("yey")}
            color={selectedTheme.answerColor}
          />
        );
      case ComponentType.multipleChoice:
        return (
          <MultipleChoice
            deleteOption={deleteOption}
            color={selectedTheme.answerColor}
            updateChoice={updateMultipleChoice}
            addChoice={addMultipleChoiceOption}
            options={selectedQuestion.values}
          />
        );
      case ComponentType.pictureChoice:
        return (
          <PictureChoice
            deleteOption={deleteOption}
            color={selectedTheme.answerColor}
            updateChoice={updateMultipleChoice}
            addChoice={addMultipleChoiceOption}
            options={selectedQuestion.values}
            images={selectedQuestion.images}
            handleFileChange={handleFileChange}
            uploadingIndex={uploadingIndex}
          />
        );
      case ComponentType.yesNo:
        return (
          <YesNo
            updateChoice={() => console.log("yey")}
            value={""}
            color={selectedTheme.answerColor}
          />
        );
      case ComponentType.dropdown:
        return (
          <DropDown
            color={selectedTheme.answerColor}
            options={selectedQuestion.values}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              const dropdownOptions = e.target.value.split("\n");
              updateDropdownOptions(dropdownOptions);
            }}
          />
        );
      case ComponentType.ratings:
        return <Ratings color={selectedTheme.answerColor} />;
      case ComponentType.uploadFile:
        return <UploadFile color={selectedTheme.answerColor} />;
      case ComponentType.legal:
        return (
          <Legal
            color={selectedTheme.answerColor}
            updateChoice={() => console.log("yey")}
            value=""
          />
        );
      case ComponentType.correctOrder:
        return (
          <CorrectOrder
            color={selectedTheme.answerColor}
            deleteOption={deleteOption}
            updateChoice={updateMultipleChoice}
            addChoice={addMultipleChoiceOption}
            options={selectedQuestion.values}
          />
        );
      case ComponentType.digitalSignature:
        return <DigitalSignature color={selectedTheme.answerColor} />;
      case ComponentType.labelPicture:
        return (
          <LabelPicture
            color={selectedTheme.answerColor}
            images={selectedQuestion.images}
            handleFileChange={handleFileChange}
            uploadingIndex={uploadingIndex}
          />
        );
      case ComponentType.fillInBlank:
        return (
          <FillIntheBlank
            deleteOption={deleteOption}
            color={selectedTheme.answerColor}
            updateChoice={updateFillInTheBlankChoice}
            addChoice={addFillInTheBlankChoice}
            options={selectedQuestion.values}
          />
        );
      case ComponentType.matching:
        return (
          <Matching
            deleteOption={deleteMatchingOption}
            updateChoice={updateMatchingChoice}
            addChoice={addMatchingChoice}
            options={selectedQuestion.values}
            color={selectedTheme.answerColor}
          />
        );
      default:
        return null;
    }
  };

  const updateSurveyDetail = (
    key: "typeformDisplayName" | "typeformDescription",
    value: string
  ) => {
    const clonedSurveyInfo = { ...surveyInfo };
    clonedSurveyInfo[key] = value;
    setSurveyInfo(clonedSurveyInfo);
  };

  const renderTheme = () => {
    //TODO: show revert modal when go back to previous from theme
    return (
      <>
        <div className="segment-header">
          <Icon
            icon="chevron-left"
            onClick={() => setShowThemeSettings(false)}
          />
          <EditableText
            value={selectedTheme?.themeName}
            onChange={(themeName) => {
              setSelectedTheme({
                ...selectedTheme,
                themeName: themeName,
                status: "draft",
              });
            }}
            className="editable-theme-name"
          />
        </div>
        <div className="type-section">
          <div
            className="upload-background"
            onClick={() => bgImageInputRef.current?.click()}
          >
            {selectedFile ? (
              <>
                <img src={selectedFile} />
              </>
            ) : (
              <>
                <div className="upload-background-title">Upload</div>
                <div className="upload-background-subtitle">
                  Customize the background image
                </div>
              </>
            )}
            <input
              type="file"
              hidden
              ref={bgImageInputRef}
              onChange={uploadThemeImage}
            />
          </div>
          {selectedFile ? (
            <>
              <div className="brightness-slider-text">Brightness</div>
              <Slider
                labelRenderer={false}
                min={-100}
                max={100}
                value={selectedBrightness}
                onChange={(number) => {
                  setSelectedBrightness(number);
                  setSelectedTheme({
                    ...selectedTheme,
                    backgroundImageBrightness: number,
                    status: "draft",
                  });
                }}
              />
            </>
          ) : null}
        </div>
        <div className="settings-section">
          <div className="settings-section-title">Font</div>
          <div className="settings-section-subtitle">
            Customize font for your survey
          </div>
          <FontPicker
            apiKey="AIzaSyCAzTWQNgRzwkudY7lCPw8TjzfjPvBpSlk"
            activeFontFamily={selectedTheme.systemFont}
            onChange={(nextFont) => {
              setSelectedTheme({
                ...selectedTheme,
                systemFont: nextFont.family,
              });
            }}
          />
          <Box className="header-color-picker-container">
            <div>Questions</div>
            <Popover2
              position="bottom"
              className="color-picker-dropdown"
              content={
                <SketchPicker
                  color={selectedTheme?.questionColor}
                  onChangeComplete={(newColor) => {
                    const clonedTheme = {
                      ...selectedTheme,
                      questionColor: newColor.hex,
                      status: "draft",
                    };
                    //@ts-ignore
                    setSelectedTheme(clonedTheme);
                  }}
                />
              }
              renderTarget={({ isOpen, ref, ...targetProps }) => (
                <div {...targetProps} ref={ref}>
                  <div
                    className="color-picker-square"
                    style={{ backgroundColor: selectedTheme?.questionColor }}
                  />
                  <Icon icon="chevron-down" color="#C4C4C4" />
                </div>
              )}
            />
          </Box>
          <Box className="header-color-picker-container">
            <div>Answers</div>
            <Popover2
              position="bottom"
              className="color-picker-dropdown"
              content={
                <SketchPicker
                  color={selectedTheme?.answerColor}
                  onChangeComplete={(newColor) => {
                    const clonedTheme = {
                      ...selectedTheme,
                      answerColor: newColor.hex,
                      status: "draft",
                    };
                    //@ts-ignore
                    setSelectedTheme(clonedTheme);
                  }}
                />
              }
              renderTarget={({ isOpen, ref, ...targetProps }) => (
                <div {...targetProps} ref={ref}>
                  <div
                    className="color-picker-square"
                    style={{ backgroundColor: selectedTheme?.answerColor }}
                  />
                  <Icon icon="chevron-down" color="#C4C4C4" />
                </div>
              )}
            />
          </Box>
          <Box className="header-color-picker-container">
            <div>Buttons</div>
            <Popover2
              position="bottom"
              className="color-picker-dropdown"
              content={
                <SketchPicker
                  color={selectedTheme?.buttonColor}
                  onChangeComplete={(newColor) => {
                    const clonedTheme = {
                      ...selectedTheme,
                      buttonColor: newColor.hex,
                      status: "draft",
                    };
                    //@ts-ignore
                    setSelectedTheme(clonedTheme);
                  }}
                />
              }
              renderTarget={({ isOpen, ref, ...targetProps }) => (
                <div {...targetProps} ref={ref}>
                  <div
                    className="color-picker-square"
                    style={{ backgroundColor: selectedTheme?.buttonColor }}
                  />
                  <Icon icon="chevron-down" color="#C4C4C4" />
                </div>
              )}
            />
          </Box>
          <Box className="header-color-picker-container">
            <div>Buttons text</div>
            <Popover2
              position="bottom"
              className="color-picker-dropdown"
              content={
                <SketchPicker
                  color={selectedTheme?.buttonTextColor}
                  onChangeComplete={(newColor) => {
                    const clonedTheme = {
                      ...selectedTheme,
                      buttonTextColor: newColor.hex,
                      status: "draft",
                    };
                    //@ts-ignore
                    setSelectedTheme(clonedTheme);
                  }}
                />
              }
              renderTarget={({ isOpen, ref, ...targetProps }) => (
                <div {...targetProps} ref={ref}>
                  <div
                    className="color-picker-square"
                    style={{ backgroundColor: selectedTheme?.buttonTextColor }}
                  />
                  <Icon icon="chevron-down" color="#C4C4C4" />
                </div>
              )}
            />
          </Box>
          <Box className="header-color-picker-container">
            <div>Background Color</div>
            <Popover2
              position="bottom"
              className="color-picker-dropdown"
              content={
                <SketchPicker
                  color={selectedTheme?.backgroundColor}
                  onChangeComplete={(newColor) => {
                    const clonedTheme = {
                      ...selectedTheme,
                      backgroundColor: newColor.hex,
                      status: "draft",
                    };
                    //@ts-ignore
                    setSelectedTheme(clonedTheme);
                  }}
                />
              }
              renderTarget={({ isOpen, ref, ...targetProps }) => (
                <div {...targetProps} ref={ref}>
                  <div
                    className="color-picker-square"
                    style={{ backgroundColor: selectedTheme?.backgroundColor }}
                  />
                  <Icon icon="chevron-down" color="#C4C4C4" />
                </div>
              )}
            />
          </Box>
        </div>
        {selectedTheme.status === "draft" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              padding: "10px",
            }}
          >
            <Button
              minimal
              text="Revert"
              onClick={revertThemeSettings}
              className="revert-changes-button"
            />
            <Button
              minimal
              className="save-theme-changes-button"
              text={
                !selectedTheme.businessId ||
                selectedTheme.businessId.length === 0
                  ? "Save as New Theme"
                  : "Save Changes"
              }
              onClick={
                !selectedTheme.businessId ||
                selectedTheme.businessId.length === 0
                  ? saveNewTheme
                  : saveChangesOfTheme
              }
            />
          </div>
        ) : null}
      </>
    );
  };
  const renderMyThemes = () => {
    return (
      <>
        <div
          style={{
            marginTop: "20px",
            paddingLeft: "44px",
            paddingRight: "44px",
          }}
        >
          <Button
            onClick={addNewThemeData}
            icon="plus"
            text="Add new theme"
            className="add-new-theme"
          />
        </div>
        {themes.map((item, index) => {
          if (item.businessId === business.id) {
            return renderThemeRow(item, index);
          }
        })}
      </>
    );
  };
  const renderThemeRow = (item: ThemeProp, index: number) => {
    return (
      <div
        className={`gallery-row ${
          selectedTheme.id === item.id ? "selected" : ""
        }`}
        onClick={() => {
          // @ts-ignore
          setSelectedFile(item.backgroundImage ? item.backgroundImage : "");
          setSelectedTheme(item);
        }}
      >
        <div
          style={{ color: item.questionColor, fontFamily: "Lato-Extrabold" }}
        >
          Question
        </div>
        <div
          style={{
            color: item.answerColor,
            fontFamily: "Lato-Medium",
            marginBottom: "10px",
          }}
        >
          Answer
        </div>
        <div
          style={{
            height: "20px",
            width: "50px",
            backgroundColor: item.buttonColor,
          }}
        />
        <div className="theme-name">{item.themeName}</div>
        <Popover2
          usePortal={false}
          className="gallery-more-option-menu"
          content={
            <Menu style={{ minWidth: "auto" }}>
              <MenuItem
                text="Edit"
                onClick={() => handleEditThemeButton(item)}
              />
            </Menu>
          }
          renderTarget={({ isOpen, ref, ...targetProps }) => (
            <div {...targetProps} ref={ref}>
              <Icon icon="more" />
            </div>
          )}
        />
      </div>
    );
  };
  const renderThemeGallery = () => {
    return themesData.map((item, index) => {
      if (item.type === "system") {
        return renderThemeRow(item, index);
      }
    });
  };
  const renderQuestionTab = () => {
    return (
      <>
        <div className="type-section">
          <div className="type-section-title">Type</div>
          {/* @ts-ignore */}
          <Select
            popoverProps={{
              modifiers: {
                arrow: {
                  enabled: false,
                },
              },
            }}
            matchTargetWidth
            className="dropdown-popover"
            activeItem={selectedQuestion}
            fill={true}
            items={componentTypes}
            filterable={false}
            itemRenderer={(item, { handleClick, modifiers }) => (
              <MenuItem
                onClick={handleClick}
                active={modifiers.active}
                text={item.label}
              />
            )}
            onItemSelect={(item) => {
              updateComponentType(item);
            }}
          >
            <div className="type-dropdown-input">
              {/* ts-ignore */}
              {selectedQuestion ? getTypeLabelFromSlug : ""}
              <Icon icon="chevron-down" color="#C4C4C4" />
            </div>
          </Select>
        </div>
        <div className="settings-section">
          <div className="settings-section-title">Settings</div>
          <div className="settings-section-subtitle">
            Customize settings on this question
          </div>
          <div className="switch-group">
            <div className="switch-container">
              <div>Required</div>
              <Switch
                checked={selectedQuestion.required}
                large
                onChange={(e) => updateQuestion("required", "")}
              />
            </div>
            <div className="switch-container">
              <div>Max Characters</div>
              <Switch large />
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderDesignTab = () => {
    return (
      <>
        {!showThemeSettings ? (
          <div className="design-tabs">
            <div
              onClick={() => setSelectedSegment("my-themes")}
              className={`${selectedSegment === "my-themes" ? "active" : ""}`}
            >
              My Themes
            </div>
            <div
              onClick={() => setSelectedSegment("gallery")}
              className={`${selectedSegment === "gallery" ? "active" : ""}`}
            >
              Gallery
            </div>
          </div>
        ) : null}
        <div className="tab-body">
          {selectedSegment === "gallery"
            ? !showThemeSettings
              ? renderThemeGallery()
              : null
            : !showThemeSettings
            ? renderMyThemes()
            : ""}
          {showThemeSettings ? renderTheme() : null}
        </div>
      </>
    );
  };
  const renderEndingSectionSettings = () => {
    return (
      <>
        <div className="settings-section ending-section-settings">
          <div className="settings-section-title">Settings</div>
          <div className="settings-section-subtitle">
            Customize settings on this question
          </div>
          <div className="switch-group">
            <div className="switch-container">
              <div>Button</div>
              <Switch
                checked={selectedEndingSection?.showButton}
                large
                onChange={(e) => updateEndingSectionStep("showButton", "")}
              />
            </div>
            {selectedEndingSection?.showButton ? (
              <input
                value={selectedEndingSection.buttonLabel}
                onChange={(e) =>
                  updateEndingSectionStep("buttonLabel", e.target.value)
                }
              />
            ) : (
              ""
            )}
          </div>
          <div className="switch-group">
            <div className="switch-container">
              <div>Button Link</div>
              <Switch
                checked={selectedEndingSection?.showButtonLink}
                large
                onChange={(e) => updateEndingSectionStep("showButtonLink", "")}
              />
            </div>
            {selectedEndingSection?.showButtonLink ? (
              <input
                value={selectedEndingSection.buttonLink}
                onChange={(e) =>
                  updateEndingSectionStep("buttonLink", e.target.value)
                }
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="type-section ending-section-settings">
          <div
            className="upload-background"
            onClick={() => endingSectionBgImageRef.current?.click()}
          >
            {selectedEndingSection?.image ? (
              <>
                <img src={selectedEndingSection.image} />
              </>
            ) : (
              <>
                <div className="upload-background-title">Upload</div>
                <div className="upload-background-subtitle">
                  Customize the background image
                </div>
              </>
            )}
            <input
              type="file"
              hidden
              ref={endingSectionBgImageRef}
              onChange={uploadEndingSectionImage}
            />
          </div>
          {selectedEndingSection?.image &&
          selectedEndingSection.imageLayout === "background" ? (
            <>
              <div className="brightness-slider-text">Brightness</div>
              <Slider
                labelRenderer={false}
                min={-100}
                max={100}
                value={selectedBrightness}
                onChange={(number) => {
                  updateEndingSectionStep("imageBrightness", number);
                }}
              />
            </>
          ) : null}
        </div>
        {selectedEndingSection?.image ? (
          <div className="type-section ending-section-settings image-layout-option">
            <img
              src={LeftImage}
              onClick={() => updateEndingSectionStep("imageLayout", "left")}
              className={`${
                selectedEndingSection.imageLayout === "left" ? "active" : ""
              }`}
            />
            <img
              src={RightImage}
              onClick={() => updateEndingSectionStep("imageLayout", "right")}
              className={`${
                selectedEndingSection.imageLayout === "right" ? "active" : ""
              }`}
            />
            <img
              src={TopImage}
              onClick={() => updateEndingSectionStep("imageLayout", "top")}
              className={`${
                selectedEndingSection.imageLayout === "top" ? "active" : ""
              }`}
            />
            <img
              src={BottomImage}
              onClick={() =>
                updateEndingSectionStep("imageLayout", "background")
              }
              className={`${
                selectedEndingSection.imageLayout === "background"
                  ? "active"
                  : ""
              }`}
            />
          </div>
        ) : null}
      </>
    );
  };
  const renderRightPart = () => {
    return (
      <div className="right-section">
        <div className="tabs">
          <div
            className={`tab-item ${selectedTab === "question" ? "active" : ""}`}
            onClick={() => setSelectedTab("question")}
          >
            Question
          </div>
          <div
            className={`tab-item ${selectedTab === "design" ? "active" : ""}`}
            onClick={() => setSelectedTab("design")}
          >
            Design
          </div>
          <div
            className={`tab-item ${selectedTab === "logic" ? "active" : ""}`}
            onClick={() => setSelectedTab("logic")}
          >
            Logic
          </div>
        </div>
        {showEndingSection
          ? renderEndingSectionSettings()
          : selectedTab === "question"
          ? renderQuestionTab()
          : renderDesignTab()}
        <div
          style={{
            paddingTop: "15px",
            paddingLeft: "44px",
            paddingRight: "44px",
          }}
        >
          {/*questions[]*/}
          {/*responses[]*/}
          {/*formDetails{}*/}
          {/*theme*/}
          {selectedTab === "question" && !showEndingSection ? (
            <Button
              text="Publish"
              className="save-questions"
              onClick={async () => {
                const response = await createTypeform(
                  questions,
                  selectedTheme,
                  surveyInfo,
                  formData,
                  business.id,
                  endingSections,
                  selectedEndingSection
                );
                if (response.data) {
                  // @ts-ignore
                  navigate(`/form/${response.data.id}/${business.id}`, {
                    state: { questions, theme: selectedTheme, surveyInfo },
                  });
                } else {
                  console.log(response);
                }
              }}
            />
          ) : null}
        </div>
      </div>
    );
  };

  const renderLeftPart = () => {
    return (
      <div className="left-section">
        <div className="left-section-header">
          <div className="left-section-title">Content</div>
          <div className="left-section-subtitle">Add parts to this survey</div>
          <Popover2
            content={
              <div className="component-types-popover">
                <div className="popover-header">
                  <InputGroup
                    placeholder="Find a question type"
                    className="component-search-input"
                    leftIcon={
                      <Icon icon="search" color="#888888" iconSize={12} />
                    }
                  />
                </div>
                <div className="popover-body">
                  <div className="popover-title">All Question Types</div>
                  <Box display="flex" justifyContent="space-between">
                    <div className="component-column">
                      <div className="column-title">Contact Info</div>
                      <div>
                        {componentTypes.map((item, index) => {
                          if (item.type === "contact-info") {
                            return (
                              <div
                                className="component-container"
                                onClick={() => createNewQuestion(item.slug)}
                              >
                                <div className="component-icon">
                                  <img src={ChartIcon} />
                                </div>
                                <div className="component-name">
                                  {item.label}
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div className="component-column">
                      <div className="column-title">Text</div>
                      <div>
                        {componentTypes.map((item, index) => {
                          if (item.type === "text") {
                            return (
                              <div
                                className="component-container"
                                onClick={() => createNewQuestion(item.slug)}
                              >
                                <div className="component-icon">
                                  <img src={ChartIcon} />
                                </div>
                                <div className="component-name">
                                  {item.label}
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div className="component-column">
                      <div className="column-title">Choices</div>
                      <div>
                        {componentTypes.map((item, index) => {
                          if (item.type === "choices") {
                            return (
                              <div
                                className="component-container"
                                onClick={() => createNewQuestion(item.slug)}
                              >
                                <div className="component-icon">
                                  <img src={ChartIcon} />
                                </div>
                                <div className="component-name">
                                  {item.label}
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
            }
            renderTarget={({ isOpen, ref, ...targetProps }) => (
              <div {...targetProps} ref={ref} className="add-part-button">
                <Icon icon="plus" color="#878B91" />
              </div>
            )}
          />
        </div>
        <div className="steps">
          <DragDropContext
            onDragEnd={(result) => {
              if (!result.destination) {
                return;
              }
              const items = reorder(
                questions,
                result.source.index,
                result.destination.index
              );
              setQuestions(items);
            }}
          >
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {selectedQuestion &&
                    questions.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`single-step ${
                              selectedQuestion.id === item.id ? "active" : ""
                            }`}
                            onClick={() => {
                              setSelectedQuestion(item);
                              setShowEndingSection(false);
                            }}
                            key={item.id}
                          >
                            <div className="step-indicator">
                              {getIcon(item.type)}
                              <div>{index + 1}</div>
                            </div>
                            <div className="step-question">{item.title}</div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    );
  };
  const renderEndingSection = () => (
    <div className="ending-section">
      <div className="left-section-header">
        <div className="left-section-title">Ending</div>
        <div className="left-section-subtitle">Add parts to this survey</div>
        <div className="add-part-button" onClick={addNewSection}>
          <Icon icon="plus" color="#878B91" />
        </div>
      </div>
      <div>
        {endingSections.map((item, index) => (
          <div
            className="end-step"
            key={index}
            onClick={() => {
              setSelectedEndingSection(item);
              setShowEndingSection(true);
            }}
          >
            <div className="end-step-indicator">
              <img src={EndStepIcon} />
              <div>Aa</div>
            </div>
            <div className="step-question">{item.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
  const renderCenterSection = () => (
    <div className="center-section">
      <div
        className="center-form"
        style={{ backgroundColor: selectedTheme.backgroundColor }}
      >
        {selectedTheme.backgroundImage ? (
          <>
            <div
              className="form-bg-image"
              style={{
                backgroundImage: `url(${selectedTheme.backgroundImage})`,
              }}
            />
            <div
              className="form-bg-image bg-color"
              style={{
                backgroundColor:
                  selectedTheme.backgroundImageBrightness > 0 ? "#fff" : "#000",
                opacity:
                  selectedTheme.backgroundImageBrightness > 0
                    ? selectedTheme.backgroundImageBrightness / 100
                    : -selectedTheme.backgroundImageBrightness / 100,
              }}
            />
          </>
        ) : null}
        {!showEndingSection ? (
          <>
            <div className="center-component">
              <div className="question-container">
                <div
                  className="question-number apply-font"
                  style={{ color: selectedTheme?.questionColor }}
                >
                  {questions.findIndex(
                    (item) => item.id === selectedQuestion.id
                  ) + 1}
                </div>
                <Box display="flex" flexDirection="column" flex={1}>
                  <input
                    className="question-title apply-font"
                    value={selectedQuestion.title}
                    style={{ color: selectedTheme?.questionColor }}
                    onChange={(e) => updateQuestion("title", e.target.value)}
                  />
                  <input
                    style={{ color: selectedTheme?.questionColor }}
                    placeholder="Description on this is optional"
                    className="question-subtitle apply-font"
                    value={selectedQuestion.subtitle}
                    onChange={(e) => updateQuestion("subtitle", e.target.value)}
                  />
                </Box>
              </div>
              {selectedQuestion ? getComponent(selectedQuestion.type) : null}
              <Button
                text="ok"
                className="okay-button apply-font"
                style={{
                  backgroundColor: selectedTheme?.buttonColor,
                  color: selectedTheme?.buttonTextColor,
                }}
              />
            </div>
          </>
        ) : (
          <>
            {selectedEndingSection &&
            selectedEndingSection.image &&
            selectedEndingSection.imageLayout === "background" ? (
              <>
                <div
                  className="form-bg-image"
                  style={{
                    backgroundImage: `url(${selectedEndingSection.image})`,
                  }}
                />
                <div
                  className="form-bg-image bg-color"
                  style={{
                    backgroundColor:
                      selectedEndingSection.imageBrightness > 0
                        ? "#fff"
                        : "#000",
                    opacity:
                      selectedEndingSection.imageBrightness > 0
                        ? selectedEndingSection.imageBrightness / 100
                        : -selectedEndingSection.imageBrightness / 100,
                  }}
                />
              </>
            ) : null}
            <div className="center-component ending-section-component">
              <div
                className={`question-container ${selectedEndingSection?.imageLayout}`}
              >
                {selectedEndingSection &&
                selectedEndingSection.image &&
                selectedEndingSection.imageLayout !== "background" ? (
                  <div className="thank-you-image">
                    <img src={selectedEndingSection.image} />
                  </div>
                ) : null}
                <Box display="flex" flexDirection="column" flex={1}>
                  <input
                    multiple
                    className="question-title apply-font"
                    value={selectedEndingSection?.title}
                    placeholder="Say bye! Recall information wiht@"
                    style={{ color: selectedTheme?.questionColor }}
                    onChange={(e) =>
                      updateEndingSectionStep("title", e.target.value)
                    }
                  />
                  <input
                    style={{ color: selectedTheme?.questionColor }}
                    placeholder="Description on this is optional"
                    className="question-subtitle apply-font"
                    value={selectedEndingSection?.subtitle}
                    onChange={(e) =>
                      updateEndingSectionStep("subtitle", e.target.value)
                    }
                  />
                </Box>
              </div>
              {selectedEndingSection && selectedEndingSection.showButton ? (
                <Button
                  onClick={() => {
                    if (selectedEndingSection?.buttonLink.length > 0) {
                      window.location.href = selectedEndingSection?.buttonLink;
                    }
                  }}
                  text={selectedEndingSection.buttonLabel}
                  className="okay-button apply-font"
                  style={{
                    backgroundColor: selectedTheme?.buttonColor,
                    color: selectedTheme?.buttonTextColor,
                  }}
                />
              ) : null}
            </div>
          </>
        )}
      </div>
      <div className="survey-info">
        <input
          className="question-title survey-name"
          onChange={(e) =>
            updateSurveyDetail("typeformDisplayName", e.target.value)
          }
          value={surveyInfo.typeformDisplayName}
        />
        <input
          className="question-subtitle survey-description"
          onChange={(e) =>
            updateSurveyDetail("typeformDescription", e.target.value)
          }
          value={surveyInfo.typeformDescription}
        />
      </div>
    </div>
  );
  return (
    <div
      id={`survey-builder-page`}
      className={`${emulatorMode ? "emulatorMode" : ""}`}
    >
      {selectedTab === "logic" ? (
        <ReactFlowProvider>
          <Logic />
        </ReactFlowProvider>
      ) : (
        <>
          {/* left section */}
          {renderLeftPart()}
          {/* left section */}

          {/* ending section */}
          {renderEndingSection()}
          {/* ending section */}

          {questions.length > 0 && selectedQuestion
            ? renderCenterSection()
            : null}

          {/* right section */}
          {questions.length > 0 && selectedQuestion ? renderRightPart() : null}
          {/* right section */}
        </>
      )}

      <Dialog isOpen={showCreateFormModal} className="add-option-dropdown">
        <div className="modal-header">
          <div className="modal-header-title">Create Form</div>
          <div className="modal-header-subtitle">
            Bring your new form to life with a name
          </div>
          <Icon
            icon="cross"
            className="close-button"
            color="#231F20"
            iconSize={20}
          />
        </div>
        <div className="modal-body">
          <input
            type="text"
            placeholder="Give your form a name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <Select
            popoverProps={{
              className: "form-type-dropdown",
            }}
            fill
            matchTargetWidth
            filterable={false}
            items={["Survey Form", "Feedback Form", "Quiz"]}
            itemRenderer={(item, { handleClick }) => (
              <MenuItem text={item} onClick={handleClick} />
            )}
            onItemSelect={(item) => setFormData({ ...formData, type: item })}
          >
            <div className="form-type-placeholder">
              {formData.type.length > 0
                ? formData.type
                : "What type of form is this"}
            </div>
          </Select>
        </div>
        <Button
          text="Continue"
          className="save-button"
          disabled={formData.name.length === 0 || formData.type.length === 0}
          onClick={() => {
            setShowCreateFormModa(false);
          }}
        />
      </Dialog>
      <div style={{ position: "absolute", opacity: 0 }}>
        <FontPicker
          apiKey="AIzaSyCAzTWQNgRzwkudY7lCPw8TjzfjPvBpSlk"
          activeFontFamily={selectedTheme.systemFont}
        />
      </div>
    </div>
  );
};

export default SurveyBuilder;
