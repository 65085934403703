import firebase from "config/firebase";
import _ from "lodash";

import {
  composeUsableStaffMember,
  composeUsableTangoBusiness,
} from "./composableTypes";

const accountsCollection = firebase.firestore().collection("Accounts");
const businessesCollection = firebase.firestore().collection("Businesses");
const staffCollection = firebase.firestore().collection("Staff");

export const fetchAccountIdsWithParentId = async (accountId: string) => {
  const querySnapshot = await accountsCollection
    .where("id", "==", accountId)
    .get();

  const results: { id: string; parentId: string }[] = [];
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    results.push(data as { id: string; parentId: string });
  });

  const accountIds = _.uniq(results.map((i) => i.parentId)).filter((i) => !!i);
  console.log("Parent idsss: ", accountIds);

  if (accountIds.length > 0) {
    const parentQuerySnapshot = await accountsCollection
      .where("parentId", "==", accountIds[0])
      .get();

    const parentResults: { id: string; parentId: string }[] = [];
    parentQuerySnapshot.forEach((doc) => {
      const data = doc.data();
      parentResults.push(data as { id: string; parentId: string });
    });

    console.log("Parent id: ", accountIds);

    if (parentResults.length > 0) {
      return parentResults;
    }
  }

  return results;
};

export const fetchBusinessesByAccountsIds = async (accountIds: string[]) => {
  const businesses: TangoBusiness[] = [];
  for await (const id of accountIds) {
    const querySnapshot = await businessesCollection
      .where("accountId", "==", id)
      .get();

    querySnapshot.forEach((doc) => {
      const data = composeUsableTangoBusiness(doc.data());
      businesses.push(data);
    });
  }
  return businesses;
};

export const fetchStaffMembersByBusinessIds = async (businessIds: string[]) => {
  const staffMembers: StaffMember[] = [];
  for await (const id of businessIds) {
    const querySnapshot = await staffCollection
      .where("businessId", "==", id)
      .get();

    querySnapshot.forEach((doc) => {
      const data = composeUsableStaffMember(doc.data());
      staffMembers.push(data);
    });
  }
  return staffMembers;
};
