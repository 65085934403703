import { Icon } from "@blueprintjs/core";
import React, { KeyboardEvent, useEffect } from "react";

interface Props {
  updateChoice: (value: string) => void;
  value: string | null;
  color: string;
}
const Legal = ({ updateChoice, value, color }: Props) => {
  const handleUserKeyPress = (event: KeyboardEvent) => {
    const alphabets = "abcdefghijklmnopqrstuvwxyz";
    const characterIndex = alphabets.indexOf(event.key);

    if (characterIndex === 0) {
      updateChoice("yes");
    } else if (characterIndex === 1) {
      updateChoice("no");
    }
  };
  useEffect(() => {
    // @ts-ignore
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      // @ts-ignore
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, []);

  return (
    <div className="multiple-choice-container">
      <div
        className={`single-choice-container active`}
        style={{ borderColor: color, color, backgroundColor: color + "0C" }}
        onClick={() => {
          updateChoice("yes");
        }}
      >
        <div
          className="key-bind apply-font"
          style={{ color, borderColor: color }}
        >
          A
        </div>
        <input
          className="choice-name apply-font"
          value="I accept"
          disabled
          style={{ color }}
        />
        {value === "yes" ? <Icon icon="tick" color={color} /> : null}
      </div>
      <div
        style={{ borderColor: color, color, backgroundColor: color + "0C" }}
        className={`single-choice-container active`}
        onClick={() => {
          updateChoice("no");
        }}
      >
        <div
          className="key-bind apply-font"
          style={{ color, borderColor: color }}
        >
          B
        </div>
        <input
          className="choice-name apply-font"
          value="I don't accept"
          disabled
          style={{ color }}
        />
        {value === "no" ? <Icon icon="tick" color={color} /> : null}
      </div>
    </div>
  );
};
export default Legal;
